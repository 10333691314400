import React,{Fragment} from 'react'
import MenuItem from '@material-ui/core/MenuItem'
import Select from '@material-ui/core/Select'
import InputLabel from '@material-ui/core/InputLabel';
import {SENTIMENT,INTENT,REGEX,TEXT,UNHANDLED_INTENT} from './../../../../../../constants/ALERT_TYPES'
const NotificationActionSelect = ({action, setAction, active=true, isReport}) =>{
  const handleChange = (event) =>{
    setAction({...action,[event.target.name]:event.target.value})
  }
  if(isReport()){
    return (<Fragment>
      <InputLabel id="demo-simple-select-helper-label">Select Alert Trigger Type</InputLabel>
      {action['action']}
    </Fragment>)
  }

  return(
    <Fragment>
      <InputLabel id="demo-simple-select-helper-label">Select Alert Trigger Type</InputLabel>
      <Select
        disabled={!active}
        value={action['action']}
        name={"action"}
        onChange={handleChange}
      >
        <MenuItem value={UNHANDLED_INTENT}>Unhandled</MenuItem>
        <MenuItem value={REGEX}>Regex</MenuItem>
        <MenuItem value={INTENT}>Intent</MenuItem>
        <MenuItem value={TEXT}>Text</MenuItem>
        <MenuItem value={SENTIMENT}>Sentiment</MenuItem>
      </Select>
    </Fragment>
  )
}

export default NotificationActionSelect;
