import {
  gql
} from '@apollo/client';


export const GET_COMPANY = gql`
  query GET_COMPANY($id:ID!) {
    company(id: $id) {
      apiKey
      chatbotLimit
    }
  }
`;


export const GET_CURRENT_USER = gql`
  {
    currentUserName @client
    currentUserVersion @client
    currentUserEmail @client
    currentUserNickname @client
    currentCompanyName @client
    currentCompanyId @client
    currentCompanyApiKey @client
  }
`;


export const GET_CHATBOT = gql`
  query GET_CHATBOT($id:ID!) {
    chatbot(id: $id) {
      name
      disabled
      agentKey
      embedCode
      platform
    }
  }
`;
