import React from 'react'
import SidebarNav from './SidebarNav'
const Sidebar = (props) => {
  const { isDesktop, isSidebarCollapsedDesktop, isMobile, handleDrawerOpen } = props

  return (
    <SidebarNav handleDrawerOpen={handleDrawerOpen} isMobile={isMobile} isCollapsed={isDesktop && isSidebarCollapsedDesktop} />
  )
}



export default Sidebar
