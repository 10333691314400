import React,{Fragment} from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import AddIcon from '@material-ui/icons/Add'
import Grid from '@material-ui/core/Grid'
const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },

}));

const NotificationsActionPanel = ({setOpenActionAdd,setAddMode,showAdd=true}) =>{
  const classes = useStyles();
  return (
    <Fragment>
      <Grid
        container
        direction="row"
        justify="flex-end"
        alignItems="center"
      >
        {
        showAdd && <Button
          variant="outlined"
          color="secondary"
          onClick={event=>{
            setAddMode(true)
            setOpenActionAdd(true)
          }}
          startIcon={<AddIcon />}
        >
          Add Alert
        </Button>
      }
      </Grid>
    </Fragment>
  )
}
export default NotificationsActionPanel;
