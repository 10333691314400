import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
const useStyles = makeStyles(theme => ({
  noResults: {
    color: theme.palette.warning.dark
  }
}));
const NoResultsText = ({message}) =>{
  const classes = useStyles();
  return (
    <Typography variant="h5" component="p" className={classes.noResults} gutterBottom>
      {message}
    </Typography>
  )
}

export default NoResultsText
