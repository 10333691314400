import PropTypes from 'prop-types'
import React from "react";
import clsx from "clsx";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Popper from "@material-ui/core/Popper";
import Fade from "@material-ui/core/Fade";
import Typography from '@material-ui/core/Typography';
import ChevronDownIcon from "@material-ui/icons/KeyboardArrowDown";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import CardHeader from '@material-ui/core/CardHeader';
import Button from "@material-ui/core/Button";
import Profile from "../User/Profile";
import CloseIcon from "@material-ui/icons/Close";
import IconButton from "@material-ui/core/IconButton";
import Hidden from "@material-ui/core/Hidden";
import SettingsIcon from "@material-ui/icons/Settings";
import CodeIcon from "@material-ui/icons/Code";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import ListItemText from "@material-ui/core/ListItemText";

import { Link as RouterLink } from "react-router-dom";
import Zoom from '@material-ui/core/Zoom';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import ChatbotDetails from './ChatbotDetails'
import BootstrapTooltip from '../BootstrapTooltip'

import {
  useApolloClient, useQuery, useMutation, useLazyQuery, gql
} from '@apollo/client'
import { GET_CHATBOT, GET_CHATBOTS, GET_CURRENT_CHATBOT } from './queries'
import df_logo from './../../assets/img/platforms/dialogflow.png'
import { GET_CURRENT_USER } from '../Chatbots/queries'
import Loading from '../Loading'
import { CurrentChatbot as locale } from '../../assets/locale/en'

import CurrentChatbotSelectorList from './CurrentChatbotSelectorList'
import { UPDATE_USER } from './mutations'

const useStyles = makeStyles(theme => ({
  toolbar: {
    paddingRight: 24 // keep right padding when drawer close
  },
  toolbarIcon: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    ...theme.mixins.toolbar
  },
  toolbarButton: {
    backgroundColor: "#dee1e5",
    borderRadius: "40px",
    padding: "4px",
    display: "flex",
    alignItems: "center",
    "&:hover": {
      background: "#ccc",
      cursor: "pointer"
    }
  },
  menuRoot: {
    zIndex: theme.zIndex.drawer + 2,
    display: "flex",
    justifyContent: "space-between"
  },

  popperMenuContent: {
    border: "1px solid",
    borderColor: theme.palette.secondary.main,
    margin: theme.spacing(1),
    padding: theme.spacing(1),
    backgroundColor: theme.palette.text.primary,
    width: 300,
    borderRadius: "20px",
    color: "white"
  },
  profile: {
    position: "relative",
    marginTop: "-70px"
  },
  topActionMenu: {
    display: "flex",
    justifyContent: "flex-end",
    paddingRight: "5px",
    zIndex: theme.zIndex.drawer + 4,
    position: "relative"
  },
  cardRoot: {
    background: theme.palette.text.primary
  },
  cardTitle: {
    color: theme.palette.common.white
  },
  cardSubheader:{
    color: theme.palette.common.white
  },
  listItemLink: {
    color: theme.palette.secondary.main
  },
  actionMenu: {
    display: "flex",
    justifyContent: "space-between"
  },
  chatbotImage: {
    width: "50px"
  }
}));
const CurrentChatbot = ({ children, handleDrawerOpen, logout }) => {
  const classes = useStyles();
  const client = useApolloClient();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const handleClick = event => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };
  const {loading:currentUserLoading, data: currentUserData, error:currentUserError } = useQuery(
    GET_CURRENT_USER
  );

  const {loading:getCurrentChatbotLoading, data: getCurrentChatbotData, error:getCurrentChatbotError } = useQuery(
    GET_CURRENT_CHATBOT
  );

  const { data, error, loading } = useQuery(
    GET_CHATBOTS
  );


  function ListItemLink(props) {
    const { icon, primary, to } = props;

    const renderLink = React.useMemo(
      () =>
        React.forwardRef((itemProps, ref) => (
          <RouterLink to={to} ref={ref} {...itemProps} />
        )),
      [to]
    );

    return (
      <li>
        <ListItem button component={renderLink}>
          {icon ? <ListItemIcon>{icon}</ListItemIcon> : null}
          <ListItemText className={classes.listItemLink} primary={primary} />
        </ListItem>
      </li>
    );
  }
  const open = Boolean(anchorEl);
  const id = open ? "transitions-popper" : undefined;
  const handleClose = (event) => {
    setAnchorEl(null);
  };
  const [loadChatbot, { loading: chatbotLoading, error: chatbotError, data:chatbotData }] = useLazyQuery(
    GET_CHATBOT,{
      onCompleted(data){
        client.writeQuery({
          query : gql`
        query {
           currentChatbot{
            name
            platform
            agentKey
           }
         }`,
          data: {
            currentChatbot: {
              name: data.chatbot.name,
              platform: data.chatbot.platform,
              agentKey: data.chatbot.agentKey,
              __typename: 'Chatbot'
            }
          }
        });
      }
    });
  const loadNewCurrentChatbot = (key)=>{
    loadChatbot({
      variables: {
        id: key
      }
    })
  }

  const [
    updateUser,
    { loading: updateLoading, error: updateError, called: updateCalled }
  ] = useMutation(UPDATE_USER,{
    update(cache, { data: { updateUser } }) {
      cache.modify({
        fields: {
          severity () {
            return 'success'
          },
          showSnackBar() {
            return true;
          },
          snackBarMessage() {
            return locale.SET_CHATBOT_SUCCESS
          },
          currentAgentKey(data) {
            loadNewCurrentChatbot(updateUser.agentKey)
            return updateUser.agentKey;
          }
        }
      })},

          onError: (e) => {
            client.writeQuery({
              query: gql`
            query {
               showSnackBar
               snackBarMessage
               severity
             }`,
              data: {
                showSnackBar: true,
                snackBarMessage: locale.ERROR_UPDATING_USER,
                severity: 'error'
              },
            });
          },

          // onCompleted: (d)=>{
          //   client.writeQuery({
          //     query : gql`
          //     query {
          //        showSnackBar
          //        snackBarMessage
          //        severity
          //        currentAgentKey
          //      }`,
          //     data: {
          //       showSnackBar: true,
          //       snackBarMessage: locale.SET_CHATBOT_SUCCESS,
          //       severity: 'success',
          //       currentAgentKey: d.updateUser.agentKey
          //     },
          //   });
          //   if(d.updateUser.agentKey) {
          //     loadNewCurrentChatbot(d.updateUser.agentKey)
          //   }
          //},
          });

  const handleListItemClick = (value) => {
    //onClose(value);
    updateUser({
      variables: {
        agentKey: value,
        id: currentUserData.currentUserId
      }})
  };

if(getCurrentChatbotLoading && !getCurrentChatbotData || getCurrentChatbotError){
  return <Loading/>
}

  return (
    <React.Fragment>
      {
        (!getCurrentChatbotLoading && getCurrentChatbotData && !getCurrentChatbotError && getCurrentChatbotData.currentChatbot)  && <div className={clsx(classes.toolbarIcon)}>
          <Zoom in={true} style={{ transitionDelay: '500ms' }}>
            <BootstrapTooltip title={locale.BOOTSTRAP_CURRENT_CHATBOT_DETAILS}>

            <div className={classes.toolbarButton} onClick={handleClick}>
              <img src={df_logo} className={classes.chatbotImage}/>
              <Typography variant="subtitle2">
              {getCurrentChatbotData.currentChatbot.name}
              </Typography>
              <ChevronDownIcon />
            </div>

          </BootstrapTooltip></Zoom>

        </div>
      }
      <Popper
        id={id}
        className={classes.menuRoot}
        classes={{ root: classes.popperRoot, paper: classes.popperPaper }}
        open={open}
        anchorEl={anchorEl}
        transition
        disablePortal={false}
      >
        {({ TransitionProps }) => (
          <Fade {...TransitionProps} timeout={350}>
            <ClickAwayListener onClickAway={handleClose}>
              <div className={classes.popperMenuContent}>
                <Card
                  classes={{
                    root: classes.root
                  }}

                  classes={{ root: classes.cardRoot }}>
                  <CardHeader
                    classes={{
                      title: classes.cardTitle,
                      subheader: classes.cardSubheader
                    }}
                    action={
                      <IconButton
                        size={"medium"}
                        color={"secondary"}
                        onClick={handleClick}
                      >
                        <CloseIcon />
                      </IconButton>
                    }
                    title="Loaded Chatbot"
                    subheader={getCurrentChatbotData.currentChatbot.name}
                  />
                  <CardContent>
                    {
                      (!loading && !error && data) &&  <CurrentChatbotSelectorList currentAgentKey={getCurrentChatbotData.currentChatbot.agentKey} light={true} showAdd={false} data={data} handleListItemClick={handleListItemClick}/>
                    }
                  </CardContent>

                  <CardActions
                    classes={{
                      root: classes.actionMenu
                    }}
                  >

                      <ListItemLink
                        to="/dashboard/chatbots"
                        primary="Edit Chatbots"
                        icon={<SettingsIcon color={"secondary"} />}
                      />

                  </CardActions>
                </Card>
              </div>
            </ClickAwayListener>
          </Fade>
        )}
      </Popper>
    </React.Fragment>
  );
};

export default CurrentChatbot;
