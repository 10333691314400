import React, { Fragment } from "react";
import Grid from "@material-ui/core/Grid";
import Avatar from "@material-ui/core/Avatar";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import IconButton from "@material-ui/core/IconButton";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import ListItemText from "@material-ui/core/ListItemText";
import DeleteIcon from "@material-ui/icons/Delete";
import AccountTreeIcon from '@material-ui/icons/AccountTree';
import ActionPanel from "./ActionPanel";
import SimpleDialog from "../SimpleDialog";
import AgentAdd from "./AgentAdd";
import ApiDocs from "./ApiDocs";
import {
  useQuery,gql
} from '@apollo/client';
import Loading from '../Loading'
import Billing from './Billing'
import { useAuth0 } from '../../utils/react-auth0-spa'

const GET_COMPANY = gql`
  query GET_COMPANY(
    $id: ID!
  ) {
    company(
      id: $id
    ) {
      name
      id
      createdAt
      cost
      gsCallCount
      chatbots {
        dfProjectId
        name
        disabled
        dfProjectId
        dfServiceKey
      }
    }
  }
`;


const GatewayServicesManager = () => {
  const { user } = useAuth0();
  const accessCompany = user ? user["https://thebotforge.eu.auth0.com.app_metadata"]['company'] : false
  const handleClose = value => {
    setOpenAddAgent(false);
  };


  const { data, loading, networkStatus } = useQuery(
    GET_COMPANY,
    {
      notifyOnNetworkStatusChange: true,
      variables: {
        id: accessCompany
      }
    }
  );

  const [openAddAgent, setOpenAddAgent] = React.useState(false);
  const [agentData] = React.useState(false);
  const allowEdit = false;
  //TODO get graphql working
  if(loading || networkStatus === 4){
    return <Fragment>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <ActionPanel setOpenAddAgent={setOpenAddAgent} />
          <Loading/>
        </Grid>
      </Grid>
    </Fragment>
  }
  return (
    <Grid container spacing={2}>

      <Grid item xs={12}>
        <ActionPanel setOpenAddAgent={setOpenAddAgent} allowEdit={allowEdit} />
        <Billing company={data ? data.company ? data.company : null : null} user={user}/>
      </Grid>
      {(!data || !data.company || data.company.chatbots.length < 1) ? (
        <Grid item xs={12}>
          You have not added a Dialogflow agent service key, please add one.
        </Grid>
      ) : (
        <Fragment>
          <Grid item xs={12}>
            <List>
              {
                data.company.chatbots.map(chatbot=>{
                  return <ListItem key={chatbot.dfProjectId}>
                    <ListItemAvatar>
                      <Avatar>
                        <AccountTreeIcon />
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                      primary={<div><b>Dialogflow Project-</b> {chatbot.dfProjectId}</div>}
                      secondary={chatbot.dfServiceKey}
                    />{
                    allowEdit && <ListItemSecondaryAction>
                      <IconButton edge="end" aria-label="delete">
                        <DeleteIcon />
                      </IconButton>
                    </ListItemSecondaryAction>
                  }
                  </ListItem>
                })
              }

            </List>
          </Grid>
          <Grid item xs={12}>
            <ApiDocs dfProjectId={data.company.chatbots[0].dfProjectId} />
          </Grid>
        </Fragment>
      )}

      <SimpleDialog
        title={"Add Dialogflow Agent"}
        subtitle={``}
        onClose={() => handleClose()}
        open={openAddAgent}
      >
        <AgentAdd agentData={agentData} />
      </SimpleDialog>
    </Grid>
  );
};

export default GatewayServicesManager;
