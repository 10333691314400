import React  from 'react'
import {
  Switch,
  Route
} from "react-router-dom";

import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import Alerts from '../../components/dashboard/Notifications/Alerts'
import Reports from '../../components/dashboard/Notifications/Reports'

const useStyles = makeStyles(theme => ({}));

export default function Notifications() {
  const classes = useStyles();

  return (

    <Container maxWidth="lg" className={classes.container}>
      <Grid container spacing={3}>
        <Switch>
          <Route path="/dashboard/notifications/alerts" component={Alerts} />
          <Route path="/dashboard/notifications/reports" component={Reports} />
        </Switch>
      </Grid>
    </Container>
  )
}

