import React from 'react'
import SentimentVeryDissatisfiedIcon from '@material-ui/core/SvgIcon/SvgIcon'
import SentimentOption from '../SentimentOption'
import Input from '@material-ui/core/Input';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import {S0,S1,S2,S4,S5} from './../../../constants/SENTIMENTS'
export const SentimentList = [
  {sentiment:'SentimentVeryDissatisfiedIcon',value: S5},
  {sentiment:'SentimentDissatisfiedIcon',value: S4},
  {sentiment:'SentimentSatisfiedIcon',value: S2},
  {sentiment:'SentimentSatisfiedAltIcon',value: S1},
  {sentiment:'SentimentVerySatisfiedIcon',value: S0}
]

export const SentimentSelect = ({action,setAction}) => {

  const handleChange = (event) =>{
    setAction({...action,[event.target.name]:event.target.value})
  }

return(
  <Select
    labelId="demo-mutiple-checkbox-label"
    id="sentiment-action-select"
    name="actionValue"
    value={action['actionValue']}
    onChange={handleChange}
    input={<Input />}
    //renderValue={(selected) => selected.join(', ')}
    //MenuProps={MenuProps}
  >
    {SentimentList.map(({sentiment,value}) => (
      <MenuItem key={sentiment} value={value}>
        <SentimentOption sentiment={sentiment}/>
      </MenuItem>
    ))}
  </Select>
)
}
