import React from 'react'
import PropTypes from 'prop-types'
import Button from '@material-ui/core/Button';
import { makeStyles, useTheme } from '@material-ui/core/styles'
import GetStarted from '../../components/GetStarted'
import MobileMenu from '../../components/MobileMenu'
import AppBar from '@material-ui/core/AppBar/AppBar'
import HeaderLogo from '../../components/Header/HeaderLogo'
import IconButton from '@material-ui/core/IconButton'
import MenuIcon from '@material-ui/core/SvgIcon/SvgIcon'
import MainMenu from '../../components/MainMenu'
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import CssBaseline from '@material-ui/core/CssBaseline';
import Grid from '@material-ui/core/Grid';
import StarIcon from '@material-ui/icons/StarBorder';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';
import FooterHome from '../../components/Footer/FooterHome'
import useMediaQuery from '@material-ui/core/useMediaQuery/useMediaQuery'
import { useAuth0 } from '../../utils/react-auth0-spa'
const useStyles = makeStyles((theme) => ({
  appBar: {
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
  toolbar: {
    flexWrap: 'wrap',
  },
  toolbarTitle: {
    flexGrow: 1,
  },

  heroContent: {
    padding: theme.spacing(8, 0, 6),
  },
  cardHeader: {
    backgroundColor:
      theme.palette.type === 'light' ? theme.palette.grey[200] : theme.palette.grey[700],
  },
  cardPricing: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'baseline',
    marginBottom: theme.spacing(2),
  }
}));

const tiers = [
  {
    title: 'Free',
    price: '0',
    description: ['10 users included', '2 GB of storage', 'Help center access', 'Email support'],
    buttonText: 'Sign up for free',
    buttonVariant: 'outlined',
  },
  {
    title: 'Pro',
    subheader: 'Most popular',
    price: '15',
    description: [
      '20 users included',
      '10 GB of storage',
      'Help center access',
      'Priority email support',
    ],
    buttonText: 'Get started',
    buttonVariant: 'contained',
  },
  {
    title: 'Enterprise',
    price: '30',
    description: [
      '50 users included',
      '30 GB of storage',
      'Help center access',
      'Phone & email support',
    ],
    buttonText: 'Contact us',
    buttonVariant: 'outlined',
  },
];

const  Pricing = (props) => {
  const classes = useStyles();
  const theme = useTheme();
  const sm = useMediaQuery(theme.breakpoints.down('sm'));
  const [open, setOpen] = React.useState(false);
  const [openMenu, setOpenMenu] = React.useState(false);
  const [email, setEmail] = React.useState('');
  const [fname, setFname] = React.useState('');
  const { loginWithRedirect } = useAuth0();
  const handleCloseMenu = () => {
    setOpen(false);
  };
  return(
    <React.Fragment>
      <GetStarted sm={sm} open={open} setEmail={setEmail} setOpen={setOpen} email={email} classes={classes} fname={fname}/>
      {
        sm && <MobileMenu open={openMenu} setOpen={setOpenMenu} handleClose={handleCloseMenu}/>
      }
      <div
        className={classes.container}>
        <AppBar position="static" className={classes.header}>

          <Toolbar className={classes.toolbar}>

            <Grid container
                  direction="row"
                  justify="space-between"
                  alignItems="center">
              <Grid item>
                <HeaderLogo />
              </Grid>
              <Grid item xs={5}>
                {
                  sm ? (
                    <IconButton aria-label="menu" onClick={()=>setOpenMenu(!openMenu)}>
                      <MenuIcon/>
                    </IconButton>) : (
                    <MainMenu
                      loginWithRedirect={loginWithRedirect}
                    />
                  )
                }
              </Grid>
            </Grid>
          </Toolbar>
        </AppBar>

        <div className={classes.root}>
          <Grid items>
            <Typography component={sm ? 'p' : 'h4'} variant={sm ? 'p' : 'h4'} align={'left'} className={classes.headerText}>Pricing</Typography>
            {/* Hero unit */}
            <Container maxWidth="sm" component="main" className={classes.heroContent}>
              <Typography component="h1" variant="h2" align="center" color="textPrimary" gutterBottom>
                Pricing
              </Typography>
              <Typography variant="h5" align="center" color="textSecondary" component="p">
                Quickly build an effective pricing table for your potential customers with this layout.
                It&apos;s built with default Material-UI components with little customization.
              </Typography>
            </Container>
            {/* End hero unit */}
            <Container maxWidth="md" component="main">
              <Grid container spacing={5} alignItems="flex-end">
                {tiers.map((tier) => (
                  // Enterprise card is full width at sm breakpoint
                  <Grid item key={tier.title} xs={12} sm={tier.title === 'Enterprise' ? 12 : 6} md={4}>
                    <Card>
                      <CardHeader
                        title={tier.title}
                        subheader={tier.subheader}
                        titleTypographyProps={{ align: 'center' }}
                        subheaderTypographyProps={{ align: 'center' }}
                        action={tier.title === 'Pro' ? <StarIcon /> : null}
                        className={classes.cardHeader}
                      />
                      <CardContent>
                        <div className={classes.cardPricing}>
                          <Typography component="h2" variant="h3" color="textPrimary">
                            ${tier.price}
                          </Typography>
                          <Typography variant="h6" color="textSecondary">
                            /mo
                          </Typography>
                        </div>
                        <ul>
                          {tier.description.map((line) => (
                            <Typography component="li" variant="subtitle1" align="center" key={line}>
                              {line}
                            </Typography>
                          ))}
                        </ul>
                      </CardContent>
                      <CardActions>
                        <Button fullWidth variant={tier.buttonVariant} color="primary"
                                onClick={() =>{
                                  if(tier.title === "Free") {
                                    loginWithRedirect({
                                      mode: 'signUp'
                                    })
                                  }else{
                                    alert('boom')
                                  }
                                }}>
                          {tier.buttonText}
                        </Button>
                      </CardActions>
                    </Card>
                  </Grid>
                ))}
              </Grid>
            </Container>




          </Grid>
        </div>
        <footer>
          <FooterHome/>
        </footer>
      </div>
    </React.Fragment>
  )
}
Pricing.propTypes = {

}

export default Pricing
