import React, { Fragment, useState, useEffect } from 'react'
import Grid from "@material-ui/core/Grid";
import MaterialTable from 'material-table';
import Image from 'material-ui-image'
import { Link as RouterLink } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import {
  useApolloClient, useQuery, useMutation, gql
} from '@apollo/client';
import Typography from "@material-ui/core/Typography";
import AddButton from './AddButton'
import SimpleDialog from '../SimpleDialog'
import ChatbotAddEdit from './ChatbotAddEdit'
import create_chatbot_img from './../../assets/img/create_chatbot_settings.png';
import Paper from '@material-ui/core/Paper';
import {Chatbots as locale} from './../../assets/locale/en'
import { GET_CURRENT_CHATBOT, GET_CHATBOTS, GET_CURRENT_USER } from './queries'
import { ADD_CHATBOT, REMOVE_CHATBOT, UPDATE_CHATBOT } from './mutations'
import { useSnackbar } from 'notistack'
import CancelDialog from '../CancelDialog'
import useCancelDialog from '../CancelDialog/useCancelDialog'
import Loading from '../Loading'

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1
  },
  paper: {
    height: 140,
    width: 100
  },
  control: {
    padding: theme.spacing(2)
  },
  half: {
    height: "50vh"
  },
  createFirst: {
    margin: theme.spacing(2),
    padding: theme.spacing(2)
  }
}));

const Chatbots = () => {

  const classes = useStyles();
  const [openAddEdit, setOpenAddEdit] = useState(false);
  const [addMode, setAddMode] = useState(true);
  const [isValid,setIsValid] = useState(false);
  const [name,setName] = useState('');
  const [active,setActive] = useState(false);
  const [environment, setEnvironment] = useState(null);
  const [selectedTimezone, setSelectedTimezone] = useState({})
  const [selectedPlatform, setSelectedPlatform] = useState({})
  const [selectedProjectId, setSelectedProjectId] = useState(null)
  const [currentAgentKey, setCurrentAgentKey] = useState(null)
  const [currentChatbotId, setCurrentChatbotId] = useState(null)
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const {isShowing, toggle} = useCancelDialog();

  const client = useApolloClient();
  const [
    updateChatbot,
    { loading: updateLoading, error: updateError, called: updateCalled }
  ] = useMutation(UPDATE_CHATBOT);

  const [
    removeChatbot,
    { loading: removeLoading, error: removeError, called: removeCalled }
  ] = useMutation(REMOVE_CHATBOT);

  const [addChatbot, {
    loading: addLoading, error: addError, called: addCalled
  }] = useMutation(ADD_CHATBOT, {
    onError:(e)=>{
      client.writeQuery({
        query : gql`
        query {
           showSnackBar
           snackBarMessage
           severity
         }`,
        data: {
          showSnackBar: true,
          snackBarMessage: locale.ERROR_CREATING_CHATBOT,
          severity: 'error'
        },
      });
    },
    onCompleted: (d)=>{

      client.writeQuery({
        query : gql`
        query {
           showSnackBar
           snackBarMessage
           severity
         }`,
        data: {
          showSnackBar: true,
          snackBarMessage: locale.CHATBOT_CREATED,
          severity: 'success'
        },
      });


    },
  });

  const { data: chatbotData, loading: getChatbotsLoading, error: chatbotError } = useQuery(
    GET_CHATBOTS
  );
  const {loading, data: currentUserData, error } = useQuery(
    GET_CURRENT_USER
  );



  useEffect(() => {
    let valid = true;
    valid = name !== "" && selectedPlatform !== "" && selectedTimezone !== "";
    setIsValid(valid);
  }, [
    name,
    active,
    selectedTimezone,
    selectedPlatform,
    selectedProjectId
  ]);

  const handleCancel = ()=>{
       //clear out all the changes in state
      setName("");
      setActive(false);
      setSelectedPlatform(null);
      setSelectedTimezone("");
      setSelectedProjectId("")
  };

  const handleClose = ()=>{
    setOpenAddEdit(false)
  }

  const handleSave = (variables) =>{
    if (addMode) {
      addChatbot({
        variables: {
          name: name,
          timezone: selectedTimezone,
          active: active,
          platform: selectedPlatform,
          companyId: currentUserData.currentCompanyId,
          dfProjectId: selectedProjectId
        },
        refetchQueries: [
          {
            query: GET_CHATBOTS
          }
        ]
      });
    }else{
      updateChatbot({
        variables: {
          name: name,
          timezone: selectedTimezone,
          active: active,
          platform: selectedPlatform,
          companyId: currentUserData.currentCompanyId,
          dfProjectId: selectedProjectId,
          _id: currentChatbotId
        },
        refetchQueries: [
          {
            query: GET_CHATBOTS
          }
        ]
      });
    }
  }
  const handleRemoveChatbot = () =>{
    removeChatbot({
      variables: {
        _id: currentChatbotId
      },
      refetchQueries: [
        {
          query: GET_CHATBOTS
        }
      ]
    });
  }
  const startEdit = (chatbot) =>{
    setOpenAddEdit(true);
    setAddMode(false);
    setSelectedPlatform(chatbot.platform);
    setSelectedTimezone(chatbot.timezone);
    setName(chatbot.name);
    setActive(chatbot.active);
    setSelectedProjectId(chatbot.dfProjectId);
    setCurrentChatbotId(chatbot._id);
    setCurrentAgentKey(chatbot.agentKey)
    console.log(chatbot)
  }
  const checkChatbotLimit = (limit, chatbots)=>{
    return chatbots < limit
  }

  const addButtonLabel = (chatbotData)=>{
    return  (chatbotData && chatbotData.chatbotsAll && chatbotData.chatbotsAll.length > 0) ?
      "Add Another Chatbot" : "Add Chatbot"
  }

  if(getChatbotsLoading){
    return <Loading/>
  }

  return (
    <Fragment>

      <Grid container justify={"center"} className={classes.root} spacing={2}>
        <Grid>
          <Typography component={'p'}>
            Instructions:
            Add a chatbot you want to track using the button below.
            Follow our documentation to integrate the code into your chatbot to get analytics.
          </Typography>
        </Grid>

        {!chatbotData || chatbotError ? (
          <Grid>
            <Paper variant="outlined" className={classes.createFirst}>
              <Grid
                container
                direction="column"
                justify="center"
                alignItems="center"
              >
              <Typography variant="h3" component="h2" gutterBottom>
                Please create your first chatbot
              </Typography>
              <img src={create_chatbot_img} />
              <AddButton label="Add Your Chatbot" setAddMode={setAddMode} setOpenAction={setOpenAddEdit} showAdd={true}/>
              </Grid>
            </Paper>
          </Grid>
        ) : (
          <Grid item xs={12}>
            <Paper variant="outlined" className={classes.createFirst}>
              {
                checkChatbotLimit(currentUserData.currentChatbotLimit,(chatbotData && chatbotData.chatbotsAll && chatbotData.chatbotsAll.length) || 0)  ?
                  (<AddButton label={addButtonLabel(chatbotData)} setAddMode={setAddMode} showAdd={true} setOpenAction={setOpenAddEdit}/>) :
                  ('You have reached your chatbot limit')
              }
            </Paper>
            <MaterialTable
              title="Your chatbots"
              columns={[
                { title: 'Name', field: 'name' },
                { title: 'Platform', field: 'platform' },
                { title: 'Date Created', field: 'createdAt',render: rowData=>new Date(rowData.createdAt).toUTCString() },
                { title: 'Timezone', field: 'timezone' }
              ]}
              data={chatbotData.chatbotsAll.map(o => ({ ...o })) || []}
              actions={[
                {
                  icon: 'edit',
                  tooltip: 'Edit Chatbot',
                  onClick: (event, rowData) => startEdit(rowData)
                },
                rowData => ({
                  icon: 'delete',
                  tooltip: 'Delete Chatbot',
                  onClick: (event, rowData) => {
                    setCurrentChatbotId(rowData._id);
                    toggle()
                  }
                })
              ]}
              options={{
                actionsColumnIndex: -1,
                search: false,
                paging:false
              }}

            />

          </Grid>
        )}
      </Grid>


      <SimpleDialog
        title={addMode ? "Create Chatbot" : "Edit Chatbot"}
        subtitle={``}
        isValid={isValid}
        fullWidth={true}
        maxWidth={"md"}
        onSave={() => {
          handleSave();
          handleClose();
        }}
        onClose={() => {
          handleCancel();
          handleClose();
        }}
        confirmTxt={addMode ? "Add Chatbot" : "Save Changes"}
        open={openAddEdit || false}
      >
        <ChatbotAddEdit
          name={name}
          active={active}
          setActive={setActive}
          setName={setName}
          environment={environment}
          setEnvironment={setEnvironment}
          selectedTimezone={selectedTimezone}
          setSelectedTimezone={setSelectedTimezone}
          selectedPlatform={selectedPlatform}
          setSelectedPlatform={setSelectedPlatform}
          currentAgentKey={currentAgentKey}
          selectedProjectId={selectedProjectId}
          setSelectedProjectId={setSelectedProjectId}
          >

        </ChatbotAddEdit>
      </SimpleDialog>

      <CancelDialog
        isShowing={isShowing}
        hide={toggle}
        confirmButtonText={'Delete Chatbot'}
        confirm={()=>{
          handleRemoveChatbot();
          toggle();
          setCurrentChatbotId(null);
        }}
        confirmText={'Are you sure you want to delete this chatbot, all your data will be lost '}
      />
    </Fragment>

  );
};
export default Chatbots;
