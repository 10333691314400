import Button from "@material-ui/core/Button";
import { Link as RouterLink } from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import React from "react";
import AddIcon from "@material-ui/icons/Add";

const AddButton = ({ setAddMode, setOpenAction, showAdd, label, variant, startIcon=true }) => {
  return (
    <React.Fragment>
      {showAdd && (
        <Button
          variant={variant || 'outlined'}
          color="secondary"
          onClick={event => {
            setAddMode(true);
            setOpenAction(true);
          }}
          startIcon={startIcon && <AddIcon />}
        >
          {label}
        </Button>
      )}
    </React.Fragment>
  );
};

export default AddButton;
