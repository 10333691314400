import React from 'react'
import LoadingOverlay from 'react-loading-overlay';

const Overlay = (props)=>{
  return (
    <LoadingOverlay
      active={props.isActive}
      spinner
      text='Loading...'
    >
      {props.children}
    </LoadingOverlay>
    )
}
export default Overlay

