import React, { Fragment } from 'react'
import TextField from '@material-ui/core/TextField'
import FormControl from '@material-ui/core/FormControl'
import Grid from '@material-ui/core/Grid'
import InputLabel from '@material-ui/core/InputLabel/InputLabel'
import { makeStyles } from '@material-ui/core'
import CopyClipboard from '../../CopyCodeClipboard'
import IconButton from '@material-ui/core/IconButton';
import CopyIcon from '@material-ui/icons/FileCopy';
import { CopyToClipboard } from 'react-copy-to-clipboard'
import Tooltip from '@material-ui/core/Tooltip/Tooltip'
import Highlight from 'react-highlight.js'
const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  }
}));
const ChatbotProjectId = ({selectedProjectId,setSelectedProjectId,client})=>{
  const classes = useStyles();


  return(
    <Grid container spacing={2}>
      <Grid item xs={6}>
        <FormControl fullWidth className={classes.formControl}>
          <InputLabel id="name-select-label">Project Id</InputLabel>
          <TextField
            fullWidth
            size={"medium"}
            value={selectedProjectId}
            name={"projectId"}
            onChange={event => {
              setSelectedProjectId(event.target.value);
            }}
          />
        </FormControl>
      </Grid>

    </Grid>

  )
}
export default ChatbotProjectId;
