import React, { Fragment } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Highlight from "react-highlight.js";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableContainer from "@material-ui/core/TableContainer";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import {
  code_curlAuthRequest,
  code_curlAuthResponse,
  code_curlUseToken,
  code_curlPostMessageRequest,
  code_curlPostMessageResponse
} from "./../code-tips";
const useStyles = makeStyles(theme => ({
  table: {
    minWidth: 650
  },
  code: {
    width: 800,
    minWidth: 800,
    maxWidth: 800,
    height: 120,
    display: "flex",
    textAlign: "left",
    "& code": {
      width: "100%"
    }
  },
  container: {
    paddingBottom: theme.spacing(5)
  }
}));
const ApiResource = ({
  header,
  description,
  resourceUrl,
  resourceInformation,
  resourceParameters,
  extra1Header,
  extra1Content,
  extra1code,
  requestCode,
  responseCode
}) => {
  const classes = useStyles();
  return (
    <Fragment>
      <Paper className={classes.container}>
        <Typography variant={"h2"} align={"left"}>
          {header}
        </Typography>
        {description}
        <Typography variant={"h4"} align={"left"} gutterBottom>
          Resource URL
        </Typography>
        <Typography variant={"body2"} align={"left"} gutterBottom>
          {resourceUrl}
        </Typography>
        <Typography variant={"h4"} align={"left"}>
          Resource Information
        </Typography>
        <Table className={classes.table} aria-label="simple table">
          <TableBody>
            {resourceInformation.map(({ lable, value }) => (
              <TableRow key={lable}>
                <TableCell component="th" scope="row">
                  <b>{lable}</b>
                </TableCell>
                <TableCell align="left">
                  <Typography variant={"body2"} align={"left"} gutterBottom>
                    {value}
                  </Typography>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <Typography variant={"h4"} align={"left"}>
          Resource Parameters
        </Typography>
        <Table className={classes.table} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>Required</TableCell>
              <TableCell>Description</TableCell>
              <TableCell>Example</TableCell>
              <TableCell>Default</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {resourceParameters.map(
              ({ name, required, description, example, default_val }) => (
                <TableRow key={name}>
                  <TableCell>{name}</TableCell>
                  <TableCell>{required}</TableCell>
                  <TableCell>{description}</TableCell>
                  <TableCell>{example}</TableCell>
                  <TableCell>{default_val}</TableCell>
                </TableRow>
              )
            )}
          </TableBody>
        </Table>

        <Typography variant={"subtitle1"} align={"left"}>
          Request
        </Typography>
        <Highlight language={"JavaScript"} className={classes.code}>
          {requestCode}
        </Highlight>
        <Typography variant={"subtitle1"} align={"left"}>
          Response
        </Typography>
        <Highlight language={"JavaScript"} className={classes.code}>
          {responseCode}
        </Highlight>
        {extra1Header && (
          <Typography variant={"h4"} align={"left"}>
            {extra1Header}
          </Typography>
        )}
        {extra1Content && (
          <Typography variant={"subtitle1"} align={"left"}>
            {extra1Content}
          </Typography>
        )}
        {extra1code && (
          <Highlight language={"JavaScript"} className={classes.code}>
            {extra1code}
          </Highlight>
        )}
      </Paper>
    </Fragment>
  );
};

export default ApiResource;
