import React, { Fragment } from 'react';
import Typography from '@material-ui/core/Typography'
import Dropzone from '../../DropZone'
import Link from '@material-ui/core/Link';
import AgentFile from './AgentFile/index.'
const AgentAdd = ({agentData : {agentFile,agentKey,id}={}})=>{
  const preventDefault = (event) => event.preventDefault();
  const [addedAgentFile, setAddedAgentFile] = React.useState(agentFile);
  const [addedAgentFileBinary,setAddedAgentFileBinary] = React.useState();
  return(
    <Fragment>
      <Typography variant="body1" gutterBottom>
        Please upload service account json file associated with a Dialogflow agent.
      </Typography>
      <Typography variant="h6" gutterBottom>
        Service account
      </Typography>
      Learn how to use the Dialogflow connector <Link href="https://dialogflow.com/docs/reference/v2-auth-setup" onClick={preventDefault}>Link</Link>
      {
        (!addedAgentFile) ? (<Dropzone setAddedAgentFile={setAddedAgentFile} setAddedAgentFileBinary={setAddedAgentFileBinary}/>) : (
          <AgentFile addedAgentFile={addedAgentFile}/>
        )
      }
    </Fragment>
  )
}

export default AgentAdd;
