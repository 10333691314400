import React from 'react'
import Switch from "@material-ui/core/Switch";
import { withStyles } from '@material-ui/core'
import { red } from '@material-ui/core/colors'
import FormControlLabel from '@material-ui/core/FormControlLabel';
import classNameTDZError from '@babel/runtime/helpers/esm/classNameTDZError'
const RedSwitch = withStyles({
  switchBase: {
    color: red[300],
    '&$checked': {
      color: red[500],
    },
    '&$checked + $track': {
      backgroundColor: red[500],
    },
  },
  checked: {},
  track: {},
})(Switch);

const ErrorSwitch = ({error, name, checked, disabled, onChange,className,lable,labelPlacement})=>{
  return (
      error ?   <FormControlLabel
        labelPlacement={labelPlacement}
        control={<RedSwitch
          className={className}
          lable={lable}
          checked={checked}
          disabled={disabled}
          onChange={onChange}
          labelPlacement={labelPlacement || 'end'}
          name={name}
          inputProps={{ "aria-label": "secondary checkbox" }}
        />}
        label={lable}
      /> : <FormControlLabel
        labelPlacement={labelPlacement}
        control={<Switch
          lable={lable}
          className={className}
          checked={checked}
          disabled={disabled}
          onChange={onChange}
          labelPlacement={labelPlacement || 'end'}
          name={name}
          inputProps={{ "aria-label": "secondary checkbox" }}
        />}
        label={lable}
      />

    )

}
export default ErrorSwitch
