import React, { useState } from 'react'
import {Line} from 'react-chartjs-2';
import {timelineLabels} from '../../../../utils/time';
import * as moment from 'moment'
import {
  useApolloClient, useQuery,useMutation, gql
} from '@apollo/client';

import Loading from '../../../Loading'
import {RELOAD_PERIOD} from '../../../../utils/settings'
import { GET_INTERACTIONS_OVER_TIMESPAN } from './queries'
import { GET_CONVERSATIONS } from '../DashboardWidgetRecentTranscriptsListSmall/queries'


const DashboardWidgetLiveInteractionActivityGraph = ({currentUser})=>{
  const startTime = new Date(Date.now())

  const { data, loading, error } = useQuery(GET_INTERACTIONS_OVER_TIMESPAN,{
    pollInterval: RELOAD_PERIOD,
    variables: {
      agentKey: currentUser ? currentUser.currentAgentKey :  null
    }
  });
  /*
  needs to go back but keeps reloading
  {
    variables: {
      startTime: startTime.toISOString()
    }
  }
   */
  if (loading) return <Loading />;
  if (error) return <Loading />;
  if (!data.interactionsAllTimespan) return <p>Not found</p>;

  const dataLabels = timelineLabels(
    moment(data.interactionsAllTimespan.start).format("DD MMM YYYY hh:mm a"),
    15,
    'minutes',
    data.interactionsAllTimespan.segments
    );
  const gdata = {
    labels: dataLabels.map(dl=> dl.label),
    datasets: [
      {
        label: 'Latest chatbot interactions',
        fill: false,
        lineTension: 0.1,
        backgroundColor: '#C8569A',
        borderColor: '#704D99',
        borderCapStyle: 'butt',
        borderDash: [],
        borderDashOffset: 0.0,
        borderJoinStyle: 'miter',
        pointBorderColor: '#704D99',
        pointBackgroundColor: '#fff',
        pointBorderWidth: 1,
        pointHoverRadius: 5,
        pointHoverBackgroundColor: '#704D99',
        pointHoverBorderColor: 'rgba(220,220,220,1)',
        pointHoverBorderWidth: 2,
        pointRadius: 1,
        pointHitRadius: 10,
        data:  dataLabels.map(dl=> dl.count),
      }
    ]
  };

  return (
    <React.Fragment>
      {data.interactionsAllTimespan.segments.map(m=>{
        return m.message
      })}
      <Line data={gdata} />
    </React.Fragment>
  )
}

export default DashboardWidgetLiveInteractionActivityGraph;
