import {
  gql
} from '@apollo/client';


export const GET_CONVERSATIONS = gql`
query GET_CONVERSATIONS($startDate: String,$endDate: String, $agentKey: String) {
  conversationsAll(startDate: $startDate, endDate: $endDate, agentKey: $agentKey) {
    conversationsCount
    conversations {
      sessionId,
        startTime,
        interactions{
        intent{
          name
          displayName
        }
        sender
        isFallback
        message
        sentiment{
          score
          magnitude
        }
      }
    }
  }
}
`;


export const GET_CURRENT_USER = gql`
  {
    currentUserVersion @client
    currentUserName @client
    currentUserEmail @client
    currentUserNickname @client
    currentCompanyName @client
    currentCompanyId @client
  }
`;
