/* eslint-disable no-nested-ternary */
import React from "react";
import PropTypes from "prop-types";
import { makeStyles, emphasize, withStyles } from "@material-ui/core/styles";
import Link from "@material-ui/core/Link";
import ListItem from "@material-ui/core/ListItem";
import HomeIcon from "@material-ui/icons/Home";
import ListItemText from "@material-ui/core/ListItemText";
import Typography from "@material-ui/core/Typography";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import Chip from "@material-ui/core/Chip";
import { BrowserRouter as Router, Route, useLocation } from "react-router-dom";
import { Link as RouterLink } from "react-router-dom";
import { ROUTE_NAME_MAP } from '../../constants/ROUTE_NAME_MAP'

function ListItemLink(props) {
  const { to, open, ...other } = props;
  const primary = ROUTE_NAME_MAP[to];
  return (
    <li>
      <ListItem button component={RouterLink} to={to} {...other}>
        <ListItemText primary={primary} />
        {open != null ? open ? <ExpandLess /> : <ExpandMore /> : null}
      </ListItem>
    </li>
  );
}

ListItemLink.propTypes = {
  open: PropTypes.bool,
  to: PropTypes.string.isRequired
};

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
    flexDirection: "column",
    width: 560
  },
  lists: {
    backgroundColor: theme.palette.background.paper,
    marginTop: theme.spacing(1)
  },
  nested: {
    paddingLeft: theme.spacing(4)
  }
}));

const LinkRouter = props => <Link {...props} component={RouterLink} />;
const StyledBreadcrumb = withStyles(theme => ({
  root: {
    backgroundColor: theme.palette.grey[100],
    height: theme.spacing(3),
    fontSize: "0.875 rem",
    color: theme.palette.grey[800],
    fontWeight: theme.typography.fontWeightRegular,
    "&:hover, &:focus": {
      backgroundColor: theme.palette.grey[300]
    },
    "&:active": {
      boxShadow: theme.shadows[1],
      backgroundColor: emphasize(theme.palette.grey[300], 0.12)
    }
  }
}))(Chip);
export default function RouterBreadcrumbs() {
  const classes = useStyles();
  let location = useLocation();
  const [pathnames, setPathnames] = React.useState(
    location.pathname.split("/").filter(x => x)
  );
  React.useEffect(() => {
    setPathnames(location.pathname.split("/").filter(x => x));
  }, [location]);

  return (
    <Router initialIndex={0} forceRefresh={true}>
      <div className={classes.root}>
        <Route>
          {({ location }) => {
            return (
              <Breadcrumbs aria-label="breadcrumb">
                <StyledBreadcrumb
                  component="a"
                  href="/"
                  label="Home"
                  icon={<HomeIcon fontSize="small" />}
                />
                {pathnames.map((value, index) => {
                  const last = index === pathnames.length - 1;
                  const to = `/${pathnames.slice(0, index + 1).join("/")}`;

                  return last ? (
                    <Typography
                      color="textPrimary"
                      component={"span"}
                      variant={"subtitle2"}
                      key={to}
                    >
                      {ROUTE_NAME_MAP[to]}
                    </Typography>
                  ) : (
                    <LinkRouter color="inherit" to={to} key={to}>
                      <StyledBreadcrumb
                        component="span"
                        href="#"
                        label={ROUTE_NAME_MAP[to]}
                      />
                    </LinkRouter>
                  );
                })}
              </Breadcrumbs>
            );
          }}
        </Route>
      </div>
    </Router>
  );
}
