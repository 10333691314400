import React, { useState } from 'react'
import Grid from '@material-ui/core/Grid'
import { makeStyles } from '@material-ui/core'
import Paper from '@material-ui/core/Paper/Paper'
import Typography from '@material-ui/core/Typography'
import create_chatbot_img from '../../../assets/img/create_chatbot_settings.png'
import AddButton from '../../Chatbots/AddButton'

import {
  useApolloClient, useQuery, useMutation, gql
} from '@apollo/client'

import { GET_CHATBOTS, GET_CURRENT_USER } from '../../Chatbots/queries'
import { ADD_CHATBOT } from '../../Chatbots/mutations'
import { GENERATE_API_KEY, GET_API_KEY } from './mutations'
import SimpleDialog from '../../SimpleDialog'
import Highlight from 'react-highlight.js'
import CopyClipboard from '../../CopyCodeClipboard'
import { AccountDetails as locale } from '../../../assets/locale/en'
const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1
  },
  control: {
    padding: theme.spacing(2)
  },
  createFirst: {
    margin: theme.spacing(2),
    padding: theme.spacing(2)
  },
  row: {
    height: '40px'
  }
}));
const AccountDetails = () => {
  const classes = useStyles();
  const client = useApolloClient();
  const copyCodeRef = React.createRef();
  const [addMode, setAddMode] = useState(true);
  const [toggleRegenerateConfirm, setToggleRegenerateConfirm] = useState(false);
  const [toggleGenerateConfirm, setToggleGenerateConfirm] = useState(false);

  const {loading:userLoading, data: currentUserData, error:userError } = useQuery(
    GET_CURRENT_USER
  );

  const [generateApiKey, {
    loading: getApiKeyLoading, error: getApiKeyError, called: getApiKeyCalled,networkStatus:networkStatus
  }] = useMutation(GENERATE_API_KEY,{

    onCompleted: (d)=>{
      client.writeQuery({
        query : gql`
        query {
           showSnackBar
           snackBarMessage
           severity
         }`,
        data: {
          showSnackBar: true,
          snackBarMessage: locale.API_KEY_CREATED,
          severity: 'success'
        },
      });

    },
    onError: (e)=>{
      client.writeQuery({
        query : gql`
        query {
           showSnackBar
           snackBarMessage
           severity
         }`,
        data: {
          showSnackBar: true,
          snackBarMessage: locale.PROBLEM_WITH_CREATING_KEY,
          severity: 'error'
        },
      });

    }});


  const generateAPIRequest = ()=>{
    generateApiKey({
      update: (cache, { data: { generateApiKey } }) => {

        client.writeQuery({
          query : gql`
        query {
           currentCompanyApiKey
         }`,
          data: {
            currentCompanyApiKey: generateApiKey.apiKey
          },
        });
      },
      variables: {
        id: currentUserData.currentCompanyId
      }
    }).catch(err => console.error(err))
  }


  const handleCancel = ()=>{

  };
  const handleGenerate = ()=>{
    generateAPIRequest()
  };

  const handleRegenerateClose = (value) => {
    setToggleRegenerateConfirm(false);
  };

  const handleClose = ()=>{
    setToggleGenerateConfirm(false)
  }

  const handleCopyCode = () => {
    client.writeQuery({
      query : gql`
        query {
           showSnackBar
           snackBarMessage
           severity
         }`,
      data: {
        showSnackBar: true,
        snackBarMessage: locale.CODE_COPIED_TO_CLIPBOARD,
        severity: 'success'
      },
    });

  };


  return (
    <React.Fragment>
    <Grid container justify={"flex-start"} className={classes.root}>
      <Grid
        container
        direction="row"
        justify="flex-start"
        alignItems="center"
      >
        <Grid item>
          <Typography variant="h5" gutterBottom>
            Account details
          </Typography>
          </Grid>
      </Grid>

      <Grid item xs={10} md={2} className={classes.row}>
        <Typography variant="h6" gutterBottom align={'left'}>
          API Key
        </Typography>
      </Grid>
      {
        (currentUserData && currentUserData.currentCompanyApiKey) ?
          ( <React.Fragment>
              <Grid>
                <CopyClipboard
                  classes={classes}
                  value={currentUserData.currentCompanyApiKey}
                  embedCodeRef={copyCodeRef}
                  onCopyClick={handleCopyCode}
                />
               </Grid>
              <Grid item xs={10} md={2}>
                <AddButton startIcon={false} variant="contained" label="Regenerate" setAddMode={setAddMode} setOpenAction={setToggleRegenerateConfirm} showAdd={true}/></Grid>
            </React.Fragment>

           ) :
          (<Grid item xs={10}>
            <Paper variant="outlined" className={classes.createFirst}>
              <Grid
                container
                direction="column"
                justify="flex-start"
                alignItems="center"
              >
                <Typography variant="subtitle2" gutterBottom>
                  There are no API keys created yet
                </Typography>


                <AddButton variant="contained" label="Add new api key" setAddMode={setAddMode} setOpenAction={setToggleGenerateConfirm} showAdd={true}/>
              </Grid>
            </Paper>
          </Grid>)
      }


    </Grid>
      {
        toggleGenerateConfirm && <SimpleDialog
          title={ "Generate API key"}
          confirmTxt={"Generate"}
          subtitle={``}
          isValid={true}
          fullWidth={true}
          maxWidth={"md"}
          onSave={() => {
            handleGenerate();
            handleClose();
          }}
          onClose={() => {
            handleCancel();
            handleClose();
          }}
          open={toggleGenerateConfirm || false}
        > <Typography
          gutterBottom
          variant="subtitle1"
          component={'b'}
        >
          Generate your API key, you can then use this to send your chat logs to the chatseer service
        </Typography>
        </SimpleDialog>
      }

      {
        toggleRegenerateConfirm && <SimpleDialog
          title={'Regenerate API Key'}
          confirmTxt={"Yes Regenerate"}
          cancelTxt={'Close'}
          onClose={handleRegenerateClose}
          isValid={true}
          open={toggleRegenerateConfirm}
          onSave={() => {
            handleGenerate();
            handleRegenerateClose();
          }}
          onClose={() => {
            handleCancel();
            handleRegenerateClose()
          }}
        >
          {

            <div container spacing={3}>
              <Typography
                gutterBottom
                variant="subtitle1"
                component={'span'}
              >
                Regenerating your API key will cause any clients using this token to lose access.
              </Typography>

              <Typography
                component="span"
                variant="subtitle1"
                color="textPrimary"
              >
                Are you sure you want to regenerate?
              </Typography>
            </div>
          }

        </SimpleDialog>
      }

    </React.Fragment>
  )
}

export default AccountDetails
