import React from 'react'
import TopIntentsGraph from './TopIntentsGraph'
import IntentsDetails from './IntentsDetails'
import Grid from '@material-ui/core/Grid'
import PageHeader from '../../../Header/PageHeader'
import TranscriptGrid from '../../Transcripts/TranscriptGrid'
import { makeStyles } from '@material-ui/core/styles'
const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    height: 140,
    width: 100,
  },
  control: {
    padding: theme.spacing(2),
  },
  half: {
    height: '50vh'
  }
}));
const TopIntents = ()=>{
  const classes = useStyles();
  return (
    <React.Fragment>
      <Grid container justify={'center'} className={classes.root} spacing={2}>
        <Grid item xs={12}>
          {
            <TopIntentsGraph/>
          }
        </Grid>
        <Grid item xs={12}>
          {
            <IntentsDetails/>
          }
        </Grid>
      </Grid>
    </React.Fragment>

  )
}

export default TopIntents
