import {
  gql
} from '@apollo/client';


export const GET_NOTIFICATIONS = gql`
  query GET_NOTIFICATIONS(
    $notificationType: String
    $startDate: String
    $endDate: String
    $agentKey: String
    $user_id: String
  ) {
    notificationsAll(
      notificationType: $notificationType
      startDate: $startDate
      endDate: $endDate
      agentKey: $agentKey
      user_id: $user_id
    ) {
      id
      active
      notificationType
      name
      action
      scheduleDateTime
      notificationTarget
      notificationTargetValue
      frequency
      actionValue
    }
  }
`;


export const GET_CURRENT_USER = gql`
  {
    currentAgentKey @client
    currentUserName @client
    currentUserVersion @client
    currentUserEmail @client
    currentUserNickname @client
    currentCompanyName @client
    currentCompanyId @client
    currentCompanyApiKey @client
    currentChatbotLimit @client
    currentUserId @client
  }
`;
