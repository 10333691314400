import React from "react";

import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";

import IconButton from "@material-ui/core/IconButton";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";

import NotificationsTargetDisplay from "../NotificationsTargetDisplay";
import ErrorSwitch from "../../../../ErrorSwitch";
import NotificationsActionDisplay from "../NotificationsActionDisplay";

const NotificationsGridRow = ({
  id,
  name,
  action,
  scheduleDateTime,
  active,
  frequency,
  notificationTarget,
  notificationTargetValue,
  handleChange,
  notificationType,
  actionValue,
  classes,
  setOpenActionAdd,
  setNotificationTarget,
  setAction,
  setName,
  setActive,
  setAddMode,
  setId,
  setFrequency,
  toggleCancel,
  handleSave
}) => {
  return (
    <TableRow key={id}>
      <TableCell component="td" scope="row">
        {name}
      </TableCell>
      <TableCell component="td" scope="row">
        <NotificationsActionDisplay actionValue={actionValue} action={action}/>
      </TableCell>
      <TableCell align="left">
        <ErrorSwitch
          error={
            notificationTarget === "" &&
            notificationTargetValue === "" &&
            active
          }
          checked={active}
          onChange={event => {
            handleSave({
              id: id,
              active: event.target.checked
            })
          }}
          name={"active"}
          inputProps={{"aria-label": "secondary checkbox"}}
        />
      </TableCell>
      <TableCell align="left">
        <div className={classes.tableCell}>
          {notificationTarget && active && (
            <NotificationsTargetDisplay
              notificationTargetValue={notificationTargetValue}
            />
          )}
        </div>
      </TableCell>
      <TableCell align="left">
        {notificationType === "REPORT" && frequency}
      </TableCell>
      <TableCell>
        <IconButton
          onClick={() => {
            setAddMode(false);
            setId(id);
            setOpenActionAdd(true);
            setNotificationTarget({
              notificationTargetValue: notificationTargetValue,
              notificationTarget: notificationTarget
            });
            setAction({
              action: action,
              actionValue: actionValue
            });
            setActive(active);
            setFrequency(frequency);
            setName(name);
          }}
          aria-label="edit-alert"
        >
          <EditIcon/>
        </IconButton>{
        (notificationType === "ALERT") &&
        <IconButton
          onClick={() => {
            setId(id);
            toggleCancel();
          }}
          aria-label="delete-alert"
        >
          <DeleteIcon/>
        </IconButton>
      }

      </TableCell>
    </TableRow>
  );
}
export default NotificationsGridRow;
