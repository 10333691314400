import React from 'react';
import Avatar from '@material-ui/core/Avatar';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemText from '@material-ui/core/ListItemText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import AssistantIcon from '@material-ui/icons/Assistant';

import {CurrentChatbotSelector as locale} from './../../assets/locale/en'

import {
  useApolloClient, useQuery, useMutation, useLazyQuery, gql
} from '@apollo/client'
import { GET_CHATBOTS, GET_CURRENT_CHATBOT } from '../CurrentChatbot/queries'
import { UPDATE_USER } from './mutations'
import { GET_CURRENT_USER } from '../CurrentChatbot/queries'
import { GET_CHATBOT } from '../../layout/queries'
import CurrentChatbotSelectorList from './CurrentChatbotSelectorList'



const CurrentChatbotSelector = (props) => {
  const client = useApolloClient();
  const { onClose, selectedValue, open } = props;
  const { data, error, loading } = useQuery(
    GET_CHATBOTS
  );
  const { data:userData, error:userError, loading: userLoading } = useQuery(
    GET_CURRENT_USER
  );




  const [loadChatbot, { loading: chatbotLoading, error: chatbotError, data:chatbotData }] = useLazyQuery(
    GET_CHATBOT,{
      onCompleted(data){
        client.writeQuery({
          query : gql`
        query {
           currentChatbot{
            name
            platform
            agentKey
           }
         }`,
          data: {
            currentChatbot: {
              name: data.chatbot.name,
              platform: data.chatbot.platform,
              agentKey: data.chatbot.agentKey,
              __typename: 'Chatbot'
            }
          }
        });
      }
    });

  const [
    updateUser,
    { loading: updateLoading, error: updateError, called: updateCalled }
  ] = useMutation(UPDATE_USER,{
    onError:(e)=>{
      client.writeQuery({
        query : gql`
        query {
           showSnackBar
           snackBarMessage
           severity
         }`,
        data: {
          showSnackBar: true,
          snackBarMessage: locale.ERROR_UPDATING_USER,
          severity: 'error'
        },
      });
    },
    onCompleted: (d)=>{
      client.writeQuery({
        query : gql`
        query {
           showSnackBar
           snackBarMessage
           severity
           currentAgentKey
         }`,
        data: {
          showSnackBar: true,
          snackBarMessage: locale.SET_CHATBOT_SUCCESS,
          severity: 'success',
          currentAgentKey: d.updateUser.agentKey
        },
      });
      if(d.updateUser.agentKey) {
        loadNewCurrentChatbot(d.updateUser.agentKey)
      }
    },
  });

  const handleClose = () => {
    onClose(selectedValue);
  };
  const loadNewCurrentChatbot = (key)=>{
    loadChatbot({
        fetchPolicy: "cache-and-network",
        variables: {
          id: key
        }
      })
  }

  const handleListItemClick = (value) => {
    onClose(value);
    updateUser({
      variables: {
        agentKey: value,
        id: userData.currentUserId
      }})
  };

  return (
    <Dialog onClose={handleClose} aria-labelledby="no-chatbot-dialog-title" open={open}>
      <DialogTitle id="no-chatbot-dialog-title">Set current chatbot</DialogTitle>
      {
        (!loading && !error && data) && <CurrentChatbotSelectorList data={data} handleListItemClick={handleListItemClick}/>
      }
    </Dialog>
  );
}

export default CurrentChatbotSelector;
