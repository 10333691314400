import ErrorSwitch from "../../ErrorSwitch";

import React from "react";

const Index = ({active,setActive}) => (
  <ErrorSwitch
    checked={active}
    lable={"Active"}
    onChange={()=> setActive(!active)}
    name={"active"}
    labelPlacement={'start'}
    inputProps={{ "aria-label": "secondary checkbox" }}
  />
);

export default Index;
