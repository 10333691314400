import {
  gql
} from '@apollo/client';



export const GET_CURRENT_CHATBOT = gql`
  {
    currentChatbot @client {
      name
      platform
      agentKey
      __typename
    }
  }
`;

export const GET_CHATBOTS = gql`
  query GET_CHATBOTS {
    chatbotsAll {
      name
      active
      createdAt
      platform
      timezone
      agentKey
      _id
    }
  }
`;

export const GET_CHATBOT = gql`
  query GET_CHATBOT($id:ID!) {
    chatbot(id: $id) {
      name
      disabled
      agentKey
      embedCode
      platform
    }
  }
`;


export const GET_CURRENT_USER = gql`
  {
    currentUserId @client
    currentAgentKey @client
  }
`;
