import React from 'react'
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography'
const useStyles = makeStyles((theme) => ({
  red: {
    color: theme.palette.error.main,
    padding: theme.spacing(1),
  },
}));

export const AlertText = ({text})=>{
  const classes = useStyles();
  return <Typography variant="body1" className={classes.red} gutterBottom>
    {text}
  </Typography>
}
