import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid';
import { Paper } from '@material-ui/core'
import DashboardWidgetCounterNumber from '../DashboardWidgetCounterNumber'
import DashboardWidgetCounterGraph from '../DashboardWidgetCounterGraph'

const useStyles = makeStyles({
  holder: {
    minHeight: 160
  }
})

const DashboardWidgetCounterHolder = (props)=>{
  const classes = useStyles();
  return (
    <Grid
      className={classes.holder}
      container
      direction="column"
      justify="flex-start"
      alignItems="center"
    ><Grid item>
      {props.title}
    </Grid>
      <Grid item>
        {props.counter}
      </Grid>
      <Grid item>
        {props.graph}
      </Grid>

    </Grid>
  )
}

export default DashboardWidgetCounterHolder;
