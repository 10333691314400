import React from "react";
import clsx from "clsx";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Popper from "@material-ui/core/Popper";
import Fade from "@material-ui/core/Fade";
import HeaderLogo from "../Header/HeaderLogo";
import ChevronDownIcon from "@material-ui/icons/KeyboardArrowDown";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import Button from "@material-ui/core/Button";
import Profile from "../User/Profile";
import CloseIcon from "@material-ui/icons/Close";
import IconButton from "@material-ui/core/IconButton";
import Hidden from "@material-ui/core/Hidden";
import SettingsIcon from "@material-ui/icons/Settings";
import CodeIcon from "@material-ui/icons/Code";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import ListItemText from "@material-ui/core/ListItemText";
import { MemoryRouter as Router } from "react-router";
import { Link as RouterLink } from "react-router-dom";
import Status from "../Status";
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import { CopyToClipboard } from 'react-copy-to-clipboard'
import BootstrapTooltip from '../BootstrapTooltip'

const useStyles = makeStyles(theme => ({
  toolbar: {
    paddingRight: 24 // keep right padding when drawer close
  },
  toolbarIcon: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    ...theme.mixins.toolbar,
    margin: 10
  },
  toolbarButton: {
    backgroundColor: "#dee1e5",
    borderRadius: "40px",
    padding: "4px",
    display: "flex",
    alignItems: "center",
    "&:hover": {
      background: "#ccc",
      cursor: "pointer"
    }
  },
  menuRoot: {
    zIndex: theme.zIndex.drawer + 2,
    display: "flex",
    justifyContent: "space-between"
  },

  popperMenuContent: {
    border: "1px solid",
    borderColor: theme.palette.secondary.main,
    margin: theme.spacing(1),
    padding: theme.spacing(1),
    backgroundColor: theme.palette.text.primary,
    width: 300,
    borderRadius: "20px",
    color: "white"
  },
  profile: {
    position: "relative",
    marginTop: "-70px"
  },
  topActionMenu: {
    display: "flex",
    justifyContent: "flex-end",
    paddingRight: "5px",
    zIndex: theme.zIndex.drawer + 3,
    position: "relative"
  },
  cardRoot: {
    background: theme.palette.text.primary
  },
  listItemLink: {
    color: theme.palette.secondary.main
  },
  actionMenu: {
    display: "flex",
    justifyContent: "space-between"
  }
}));


const MainMenu = ({ children, isAuthenticated, handleDrawerOpen, logout }) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const handleClick = event => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };
  function ListItemLink(props) {
    const { icon, primary, to } = props;

    const renderLink = React.useMemo(
      () =>
        React.forwardRef((itemProps, ref) => (
          <RouterLink to={to} ref={ref} {...itemProps} />
        )),
      [to]
    );

    return (
      <li>
        <ListItem button component={renderLink}>
          {icon ? <ListItemIcon>{icon}</ListItemIcon> : null}
          <ListItemText className={classes.listItemLink} primary={primary} />
        </ListItem>
      </li>
    );
  }
  const open = Boolean(anchorEl);
  const id = open ? "transitions-popper" : undefined;
  const handleClose = (event) => {
    setAnchorEl(null);
  };
  return (
    <React.Fragment>
      <div className={clsx(classes.toolbarIcon)}>
        <BootstrapTooltip title="Main Menu">
        <div className={classes.toolbarButton} onClick={handleClick}>
          <HeaderLogo />
          <ChevronDownIcon />
        </div>
        </BootstrapTooltip>
        <Hidden smUp>

          <IconButton
            size={"small"}
            color={"secondary"}
            onClick={handleDrawerOpen}
          >
            <CloseIcon />
          </IconButton>

        </Hidden>
      </div>
      <Popper
        id={id}
        className={classes.menuRoot}
        classes={{ root: classes.popperRoot, paper: classes.popperPaper }}
        open={open}
        anchorEl={anchorEl}
        transition
        disablePortal={false}
      >
        {({ TransitionProps }) => (
          <Fade {...TransitionProps} timeout={350}>
            <ClickAwayListener onClickAway={handleClose}>
            <div className={classes.popperMenuContent}>

              <div className={classes.topActionMenu}>
                <IconButton
                  size={"medium"}
                  color={"secondary"}
                  onClick={handleClick}
                >
                  <CloseIcon />
                </IconButton>
              </div>
              {isAuthenticated && (
                <div className={classes.profile}>
                  <Profile />
                </div>
              )}
              <Card classes={{ root: classes.cardRoot }}>
                <CardContent>
                  <List aria-label="main settings">
                    <ListItemLink
                      to="/dashboard/chatbots"
                      primary="Chatbots"
                      icon={<SettingsIcon color={"secondary"} />}
                    />
                    <ListItemLink
                      to="/dashboard/account"
                      primary="Account Settings"
                      icon={<AccountCircleIcon color={"secondary"}/>}
                    />
                    <ListItemLink
                      to="/dashboard/embed"
                      primary="Embed Code"
                      icon={<CodeIcon color={"secondary"} />}
                    />
                  </List>
                </CardContent>
                <CardActions
                  classes={{
                    root: classes.actionMenu
                  }}
                >
                  <Button
                    size="small"
                    color={"secondary"}
                    onClick={() =>
                      logout({
                        returnTo: window.location.origin
                      })
                    }
                  >
                    Log Out
                  </Button>

                  <Status />
                </CardActions>
              </Card>
            </div>
            </ClickAwayListener>
          </Fade>
        )}
      </Popper>
    </React.Fragment>
  );
};

export default MainMenu;
