import moment from 'moment'
/*
timelineLabels('18:00', 2, 'hours')
timelineLabels('06:00', 30, 'm')
timelineLabels('00:00', 5, 'minutes')
 */
export const timelineLabels = (desiredStartTime, interval, period, segments) => {
  const periodsInADay = moment.duration(1, 'day').as(period);
  const timeLabels = [];

  const startTimeMoment = moment(desiredStartTime);
  for (let i = 0; i <= periodsInADay; i += interval) {
    startTimeMoment.add(i === 0 ? 0 : interval, period);

    let count = segments.find(({date}) => {
      let checkDate = moment(new Date(date))
      let first = startTimeMoment.clone().subtract(15,'minutes')
      let second = startTimeMoment.clone()
      return checkDate.isSameOrBefore(second) && checkDate.isSameOrAfter(first)
    })

    if(moment(startTimeMoment).isSameOrBefore(moment())){
      timeLabels.push({
        label: startTimeMoment.format('hh:mm A'),
        time: startTimeMoment.toDate(),
        count: count ? count.count : 0
      });
    }
  }
  return timeLabels;
};

export const duration = ({createdAt, updatedAt})=>{
  const start = moment(createdAt);
  const finish = moment(updatedAt);
  const duration  = finish.diff(start, 'seconds');
  if(duration ===0) return 0;
  const hrs = ~~(duration / 3600);
  const mins = ~~((duration % 3600) / 60);
  const secs = ~~duration % 60;

  let ret = '';
  if (hrs > 0) {
    ret = `${hrs}hrs : ${mins < 10 ? "0" : ""}`
  }
  ret = `${ret} ${mins}min : ${secs < 10 ? "0" : ""}`

  return `${ret} ${secs}sec`;
}


