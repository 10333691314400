import {
  gql
} from '@apollo/client';


export const EMAIL_EMBED_CODE = gql`
  mutation emailEmbedCode ($email: String, $agentKey: String, $senderName: String) {
      emailEmbedCode(
        email: $email
        agentKey: $agentKey
        senderName: $senderName
      )
  }
`;
