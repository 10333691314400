import React from 'react';
import PropTypes from 'prop-types';
import Select from '@material-ui/core/Select/Select'
import Input from '@material-ui/core/Input/Input'
import MenuItem from '@material-ui/core/MenuItem'
import InputLabel from '@material-ui/core/InputLabel';
import { makeStyles } from '@material-ui/core/styles';
import { minimalTimezoneSet } from 'compact-timezone-list';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField/TextField'
ChatbotTimezone.propTypes = {

}
const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));
function ChatbotTimezone ({selectedTimezone, setSelectedTimezone}) {
  const classes = useStyles();
  return (
    <FormControl fullWidth className={classes.formControl}>
    <InputLabel id="timezone-select-label">Time zone</InputLabel>
    <Select
      size={"medium"}
      id="timezone-select"
      name="chatbotTimezone"
      onChange={(e)=>{
        setSelectedTimezone(e.target.value)
      }}
      value={selectedTimezone || ''}
      input={<Input />}
    >
      {minimalTimezoneSet.map(({offset,label,tzCode}) => (
        <MenuItem key={label} value={label}>
          {label}
        </MenuItem>
      ))}
    </Select>
    </FormControl>
  )
}

export default ChatbotTimezone
