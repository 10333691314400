import React, { Fragment } from "react";
import Grid from "@material-ui/core/Grid";
import NotificationName from "./NotificationName";
import NotificationTarget from "./NotificationTarget";
import NotificationAction from "./NotificationAction";
import NotificationFrequency from './NotificationFrequency'
import NotificationActive from './NotificationActive'
const NotificationAddEdit = (props) => {
  return (
    <Fragment>
      <Grid container spacing={3}
            direction="row"
            justify="center"
            alignItems="center"
      >
        <Grid item xs={12}>
          <Grid container spacing={3} justify="flex-start" alignItems="flex-end">
            <Grid item xs={3}>
              <NotificationActive {...props}/>
            </Grid>
            <Grid item xs={9}>
              <NotificationName {...props}/>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <NotificationTarget {...props}/>
        </Grid>
        <Grid item xs={12}>
          <NotificationAction {...props}/>
        </Grid>
        {props.isReport() && <Grid item xs={12}>
          <NotificationFrequency {...props}/>
        </Grid>}
      </Grid>
    </Fragment>
  );
};

export default NotificationAddEdit;
