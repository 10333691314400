import React from "react";
import Chip from "@material-ui/core/Chip";
import IconButton from "@material-ui/core/IconButton";
import EditIcon from '@material-ui/icons/Edit';
const NotificationsTargetDisplay = ({
  notificationTargetValue = null
}) => {
  const targets = (notificationTargetValue === "" || notificationTargetValue === null) ? [] : notificationTargetValue.split(",");
  return (
    <React.Fragment>
      <span>
      {targets.length > 0 ? (
        targets.map(t => (
          <Chip
            key={t}
            size="small"
            variant="outlined"
            label={t}
            color="primary"
          />
        ))
      ) : (
        <IconButton
          size="small">
          <EditIcon fontSize={"small"} />
        </IconButton>

      )}
      </span>
    </React.Fragment>
  );
};

export default NotificationsTargetDisplay;
