import React from 'react'
import Tooltip from '@material-ui/core/Tooltip/Tooltip'
import { makeStyles } from '@material-ui/core'
const useStyles = makeStyles(theme => ({
  tooltip: {
    backgroundColor: theme.palette.primary.dark,
  }
}));
function BootstrapTooltip (props) {
  const classes = useStyles();
  return (
    <Tooltip classes={classes} {...props} />
  )
}

export default BootstrapTooltip
