import React, { Fragment } from "react";
import TextField from "@material-ui/core/TextField";
import NotificationsActionEditSelect from '../NotificationActionEditSelect/index'
import InputLabel from '@material-ui/core/InputLabel'
import {SentimentSelect} from '../../../../../Sentiment/SentimentSelect'
import { SENTIMENT, REGEX, TEXT,UNHANDLED_INTENT, INTENT} from '../../../../../../constants/ALERT_TYPES'
import {
  useQuery
} from '@apollo/client';

import { DF_INTENTS_ALL } from './queries'
import Loading from '../../../../../Loading'

const NotificationActionEdit = (props) => {


  const { data, loading, error } = useQuery(DF_INTENTS_ALL);
  const handleChange = (event) =>{
    props.setAction({...props.action,[event.target.name]:event.target.value})
  }
  const triggerHeader = () =>{
    return  <InputLabel id="alert-trigger-value-lbl">Alert Trigger Value</InputLabel>
  }
  if(props.isReport()){
    return null
  }



  if(!props.action || !props.action.action) return null;
  if (props.action.action === TEXT || props.action.action === REGEX)
    return (
      <Fragment>
        {triggerHeader}
        <TextField
          fullWidth
          size={"medium"}
          value={props.action['actionValue']}
          name={"actionValue"}
          label="Action Value"
          onChange={handleChange}
          disabled={props.isReport()}
        />
      </Fragment>
    );
  if (props.action.action === INTENT) {
    if(loading) return <Loading/>;
    return <Fragment>
      {triggerHeader}
      <NotificationsActionEditSelect
        {...props}
        values={data.intentsDFAll.map(intent=> intent.displayName) || []}
      />
    </Fragment>;
  }


  if (props.action.action === SENTIMENT) return <Fragment>
    {triggerHeader()}
    <SentimentSelect
      {...props}
    />
  </Fragment>
  if (props.action.action === UNHANDLED_INTENT) return null;

  return <Fragment>{props.action['actionValue'] - props.action['actionValue']}</Fragment>
};

export default NotificationActionEdit;
