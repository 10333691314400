import React, { useState } from "react";
import { makeStyles } from '@material-ui/core/styles';
import { DateTimePicker, KeyboardDateTimePicker } from "@material-ui/pickers";
import Grid from "@material-ui/core/Grid";
const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
  }
}));

const FromToDateTimePicker = ({startDate,endDate,handleFrom})=>{

  const classes = useStyles();
  return (
    <Grid container direction="row" justify="center" alignItems="flex-end" spacing={2}>
      <Grid item>
        <DateTimePicker
          autoOk
          disableFuture
          value={startDate}
          onChange={handleFrom}
          label="From"
          variant="inline"
        />
      </Grid>
      <Grid item>
        <DateTimePicker
          autoOk
          disableFuture
          value={endDate}
          onChange={()=>{}}
          label="To"
          variant="inline"
        />
      </Grid>
      </Grid>

  );
}

export default FromToDateTimePicker;
