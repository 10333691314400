import React from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import bg from './../../assets/img/chatseer-logo@1680x945.png';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import HeaderLogo from '../../components/Header/HeaderLogo'
import { useAuth0 } from '../../utils/react-auth0-spa'
import useMediaQuery from '@material-ui/core/useMediaQuery';
import InputBase from '@material-ui/core/InputBase';
import AppBar from '@material-ui/core/AppBar/AppBar'
import Toolbar from '@material-ui/core/Toolbar';
import { BrowserRouter as Router } from 'react-router-dom'
import FooterHome from '../../components/Footer/FooterHome'
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import MenuIcon from '@material-ui/icons/Menu';
import IconButton from '@material-ui/core/IconButton';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import GetStarted from '../../components/GetStarted'
import MainMenu from '../../components/MainMenu';
import MobileMenu from '../../components/MobileMenu'
import DashboardIcon from '@material-ui/icons/Dashboard'
import HeaderLogoBig from '../../components/Header/HeaderLogo/HeaderLogoBig'
import CardActions from '@material-ui/core/CardActions/CardActions'

const useStyles = makeStyles(theme => ({
  container:  {
    minHeight: '100%',
      [theme.breakpoints.up('md')]: {
        background: `${theme.palette.primary.main} no-repeat center/100% url("${bg}")`,
      },
      [theme.breakpoints.down('sm')]: {
        background: `${theme.palette.primary.main} no-repeat center/100% url("${bg}")`,
        backgroundSize: 'cover'
    }
  },
  header: {
    fontWeight: theme.typography.fontWeightBold,
    color: theme.palette.common.black,
    padding: theme.spacing(1),
    display: 'flex',
    backgroundColor: '#fff',
    flexGrow: 1,
    alignItems:'center'
  },
  toolbar: {
    [theme.breakpoints.up('lg')]: {
      width: '100%',
      maxWidth: '1270px'
    },
    [theme.breakpoints.down('lg')]: {
      width: '100%'
    },
  },
  logo: {
    display: 'flex',
    justifyContent: 'flex-start',
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(1),
      width: 100
    },
    [theme.breakpoints.up('md')]: {
      padding: theme.spacing(2)
    },
    position: 'absolute'
  },
  root: {
    margin: 'auto',
    width: '100%',
    height: '100%',
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(2),
      paddingTop: theme.spacing(4)
    },
    [theme.breakpoints.up('md')]: {
      padding: theme.spacing(4),
      paddingTop: theme.spacing(6),
      width: '100%',
      maxWidth: '1270px'
    }
  },
  content: {
    alignContent: 'space-between'
  },

  highlight: {
    fontSize: '110%',
    color: theme.palette.common.white
  },

  buttonWhite: {
    color: theme.palette.common.white,
    margin: theme.spacing(1)
  },
  headerText: {
    fontWeight: 700,
    color: theme.palette.common.white
  },
  headerTextThin: {
    fontWeight: 500,
    color: theme.palette.common.white
  },
  features: {
    display: 'flex'
  },
  featureHeader: {
    color: theme.palette.common.white,
    fontWeight: 700,
    paddingTop: theme.spacing(1)
  },
  purple: {
    color: theme.palette.common.white,
    backgroundColor: theme.palette.secondary.dark
  },
  listItemText: {
    color: theme.palette.common.white
  },
  benefitsList: {
    paddingTop: theme.spacing(1),
    '& li': {
      paddingLeft: 0
    }
  },
  getStartedBar: {
    position: 'relative',
  },
  getStartedDialog: {
    backgroundColor: theme.palette.secondary.dark
  },
  getStartedDialogForm: {
    //backgroundColor: theme.palette.primary.main,
    minHeight: '60%'
  }
}));


const Home = ()=>{
  const classes = useStyles();
  const theme = useTheme();
  const sm = useMediaQuery(theme.breakpoints.down('sm'));
  const [open, setOpen] = React.useState(false);
  const [openMenu, setOpenMenu] = React.useState(false);
  const [email, setEmail] = React.useState('');
  const [fname, setFname] = React.useState('');
  const { logout } = useAuth0();
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const handleCloseMenu = () => {
    setOpen(false);
  };

  return(
    <React.Fragment>
      <GetStarted sm={sm} open={open} setEmail={setEmail} setOpen={setOpen} email={email} classes={classes} fname={fname}/>
      {
        sm && <MobileMenu open={openMenu} setOpen={setOpenMenu} handleClose={handleCloseMenu}/>
      }
        <div
       className={classes.container}>
      <AppBar position="static" className={classes.header}>

        <Toolbar className={classes.toolbar}>

          <Grid container
                direction="row"
                justify="space-between"
                alignItems="center"
                >
            <Grid item>
              <HeaderLogoBig />
            </Grid>
            {
              <Grid item>
                <IconButton href={'/dashboard'} aria-label="menu">
                  <DashboardIcon/>
                </IconButton>
                <Button
                  size="small"
                  color={"secondary"}
                  onClick={() =>
                    logout({
                      returnTo: window.location.origin
                    })
                  }
                >
                  Log Out
                </Button>

              </Grid>
            }
          </Grid>
        </Toolbar>
      </AppBar>


      <div className={classes.root}>
            <Grid items>
              <Typography component={sm ? 'p' : 'h4'} variant={sm ? 'p' : 'h4'} align={'left'} className={classes.headerText}> Advanced chatbot analytics, reporting and monitoring toolkit.</Typography>
              <Typography component={sm ? 'p' : 'h5'} variant={sm ? 'p' : 'h5'} align={'left'} className={classes.headerTextThin}>Superpowers for conversational experience owners and creators.</Typography>

                <Grid item className={classes.callout}>
                  <Grid className={classes.features}>

                      <List className={classes.benefitsList}>

                        <ListItem>
                          <ListItemAvatar>
                            <Avatar className={classes.purple}>
                              <CheckCircleOutlineIcon/>
                            </Avatar>
                          </ListItemAvatar>
                          <ListItemText classes={
                            {
                              primary: classes.listItemText
                            }
                          } primary="Optimize chatbot response effectiveness" />
                        </ListItem>
                        <ListItem>
                          <ListItemAvatar>
                            <Avatar className={classes.purple}>
                              <CheckCircleOutlineIcon/>
                            </Avatar>
                          </ListItemAvatar>
                          <ListItemText classes={
                            {
                              primary: classes.listItemText
                            }
                          } primary="Review your chat transcripts" />
                        </ListItem>
                        <ListItem>
                          <ListItemAvatar>
                            <Avatar className={classes.purple}>
                              <CheckCircleOutlineIcon />
                            </Avatar>
                          </ListItemAvatar>
                          <ListItemText classes={{
                            primary: classes.listItemText
                            }} primary="Chatbot analytics and reporting" />
                        </ListItem>
                        <ListItem>
                          <ListItemAvatar>
                            <Avatar className={classes.purple}>
                              <CheckCircleOutlineIcon />
                            </Avatar>
                          </ListItemAvatar>
                          <ListItemText classes={
                            {
                              primary: classes.listItemText
                            }
                          } primary="Export the chatbot usage data you need"/>
                        </ListItem>
                        <ListItem>
                          <ListItemAvatar>
                            <Avatar className={classes.purple}>
                              <CheckCircleOutlineIcon />
                            </Avatar>
                          </ListItemAvatar>
                          <ListItemText classes={
                            {
                              primary: classes.listItemText
                            }
                          } primary=" Monitor KPIs"/>
                        </ListItem>
                        <ListItem>
                          <ListItemAvatar>
                            <Avatar className={classes.purple}>
                              <CheckCircleOutlineIcon />
                            </Avatar>
                          </ListItemAvatar>
                          <ListItemText classes={
                            {
                              primary: classes.listItemText
                            }
                          } primary="Enterprise plugins"/>
                        </ListItem>

                      </List>
                    {/*<ul className={classes.benefitsList}>*/}
                    {/*  <li>Monitor KPIs</li>*/}
                    {/*  <li>Reduce NLP failure rate</li>*/}
                    {/*  <li>Review live chat transcripts</li>*/}
                    {/*  <li>Identify mishandled and unhandled Intents</li>*/}
                    {/*  <li>NLP / Intents & Entities</li>*/}
                    {/*  <li>Users & Sessions</li>*/}
                    {/*  <li>Increase user satisfaction</li>*/}
                    {/*  <li>Reduce escalations</li>*/}
                    {/*  <li>Custom fallback handler</li>*/}
                    {/*</ul>*/}
                  </Grid>

                </Grid>

            </Grid>
      </div>

      </div>
    </React.Fragment>
  )
}

export default Home;
