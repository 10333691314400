import React  from "react";
import Grid from '@material-ui/core/Grid';
import NotificationActionSelect from './NotificationActionSelect'
import NotificationActionEdit from './NotificationActionEdit'

const NotificationAction = (props) => {
    return (
        <Grid container spacing={3} justify="flex-start" alignItems="flex-end">
          <Grid item xs={3}>
            <NotificationActionSelect {...props}/>
          </Grid>
          <Grid item xs={9}>
            <NotificationActionEdit {...props} />
          </Grid>
        </Grid>
    );

};

export default NotificationAction;
