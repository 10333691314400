import {
  gql
} from '@apollo/client';


export const ADD_NOTIFICATION = gql`
  mutation addNotification(
    $name: String!
    $notificationType: String
    $action: String
    $actionValue: String
    $active: Boolean
    $notificationTarget: String
    $notificationTargetValue: String
    $agentKey: String
    $user_id: String
  ) {
    addNotification(
      name: $name
      notificationType: $notificationType
      action: $action
      actionValue: $actionValue
      active: $active
      notificationTarget: $notificationTarget
      notificationTargetValue: $notificationTargetValue
      agentKey: $agentKey
      user_id: $user_id
    ) {
      id
      name
      active
      notificationType
      action
      actionValue
      notificationTarget
      notificationTargetValue
    }
  }
`;

export const UPDATE_NOTIFICATION = gql`
  mutation updateNotification(
    $id: ID!
    $scheduleDateTime: String
    $notificationTarget: String
    $notificationTargetValue: String
    $frequency: String
    $name: String
    $active: Boolean
    $actionValue: String
  ) {
    updateNotification(
      id: $id
      scheduleDateTime: $scheduleDateTime
      notificationTarget: $notificationTarget
      notificationTargetValue: $notificationTargetValue
      frequency: $frequency
      name: $name
      active: $active
      actionValue: $actionValue
    ) {
      id
      name
      active
      scheduleDateTime
      notificationTargetValue
      notificationTarget
      actionValue
    }
  }
`;

export const REMOVE_NOTIFICATION = gql`
  mutation deleteNotification ($id: ID!) {
      deleteNotification(
        id: $id
      )
  }
`;
