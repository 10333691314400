import React from 'react';
import { CardHeader } from '@material-ui/core'
import Title from '../../../Title'
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';

import {
  Link
} from "react-router-dom";
const useStyles = makeStyles(theme => ({
  root: {
    width: "100%",
    minHeight: 340,
    height: "100%"
  },
  rootSmall: {
    width: "100%",
    minHeight: 300
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
  seeMore: {
    textDecoration: 'none'
  },
  cardActions: {
    display: 'flex',
    justifyContent: 'flex-end',
    padding: 8,
  },
  cardHeader: {
    display: 'flex',
    justifyContent: 'flex-start',
    paddingTop: 10,
    paddingBottom: 0
  },
  cardContent: {
    paddingTop: 0
  }
}));


const DashboardWidget = ({children,...props}) =>{
  const classes = useStyles();
  return (
    <Card className={props.small? classes.rootSmall : classes.root}>
      <CardHeader
        className={classes.cardHeader}
        title={<Title>{props.title} {props.badge}</Title>}
        subheader={props.subtitle}
      />
      <CardContent className={classes.cardContent}>
        {children}
      </CardContent>
      <CardActions className={classes.cardActions}>
        <div className={classes.seeMore}>
          {props.label && <Link color="primary" to={props.to}>
            {props.label}
          </Link>}
        </div>
      </CardActions>
    </Card>
  )
}

export default DashboardWidget;
