import React from 'react'
import Profile from "../User/Profile";
import Pricing from "./../../views/Pricing"
import history from "./../../utils/history";
import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";


import PrivateRoute from './PrivateRoute'

import Home from '../../views/Home'
import Footer from '../Footer'
import DashboardLayout from '../../layout/DashboardLayout'
import PageLayout from '../../layout/PageLayout'



const Routes = ()=>{
  return(
    <Router history={history}>

      <Switch>
        <PrivateRoute path="/" exact component={Home} />
        <PrivateRoute path="/dashboard/" component={DashboardLayout} />
        <PrivateRoute path="/profile" component={Profile} />
        <PrivateRoute path="/gatewayservices" component={PageLayout} />
      </Switch>

    </Router>

  )
}
export default Routes;
