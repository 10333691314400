import DashboardWidget from '../DashboardWidget'
import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import DashboardWidgetTranscriptsListSmall from '../DashboardWidgetRecentTranscriptsListSmall'
import Badge from '@material-ui/core/Badge/Badge'
import ChatIcon from '@material-ui/icons/Chat';

const useStyles = makeStyles({
});
const defaultProps = {
  color: 'secondary',
  children: <ChatIcon />,
};
const DashboardWidgetRecentTranscripts = ({startDate,endDate,currentUser}) =>{
  return (
    <DashboardWidget
      title={'Recent Transcripts'}
      subtitle={"Click to view conversation"}
      to="/dashboard/transcripts/"
      label="View More Recent Transcripts"
      badge={<Badge badgeContent={'LIVE'} {...defaultProps} />}>
      <DashboardWidgetTranscriptsListSmall startDate={startDate} endDate={endDate} currentUser={currentUser}/>
    </DashboardWidget>
  )
}

export default DashboardWidgetRecentTranscripts;
