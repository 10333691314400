import React, {Fragment} from 'react';
import Copyright from './Copyright'
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
const useStyles = makeStyles(theme => ({
  appBarBottom: {
    top: 'auto',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    bottom: 0,
    zIndex: 99,
    backgroundColor: 'rgb(112,77,153)',
    maxHeight: '36px',
    minHeight: '36px'
  },
  copyright: {
    color: theme.palette.common.white
  }
}));

const Footer = ()=>{
  const classes = useStyles();
  return (
    <Fragment>

      <AppBar position="fixed" color="primary" className={classes.appBarBottom}>
        <Toolbar className={classes.toolbar}>
          <Copyright classes={classes}/>
        </Toolbar>
      </AppBar>
    {/*<div className={classes.appBarBottom}>*/}
    {/*  <Copyright classes={classes}/>*/}
    {/*</div>*/}
    </Fragment>
  )
}
export default Footer;
