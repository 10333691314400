import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Dropzone from '../../components/DropZone'
import AppBar from '@material-ui/core/AppBar';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import Toolbar from '@material-ui/core/Toolbar';
import Header from '../../components/Header'
import PageHeader from '../../components/Header/PageHeader'
import Footer from '../../components/Footer'
import GatewayServicesManager from '../../components/GatewayServicesManager'
const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
  },
  container: {
    paddingBottom: theme.spacing(4),
    height: '100vh'
  },
  toolbar: {
    paddingRight: 24, // keep right padding when drawer closed
  },
}));
const GatewayServices = ()=>{
  const classes = useStyles();

  return (
    <div className={classes.root}>

    <Container maxWidth="xl" className={classes.container}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <PageHeader title={'Dialogflow Gateway Services'}/>
        </Grid>
        <GatewayServicesManager/>
      </Grid>
      <footer>
        <Footer/>
      </footer>
    </Container>

    </div>
  )
}

export default GatewayServices;
