import React from 'react'
import ListItem from '@material-ui/core/ListItem'
import ListItemAvatar from '@material-ui/core/ListItemAvatar/ListItemAvatar'
import Avatar from '@material-ui/core/Avatar'
import AssistantIcon from '@material-ui/icons/Assistant'
import ListItemText from '@material-ui/core/ListItemText/ListItemText'
import List from '@material-ui/core/List'
import AddIcon from '@material-ui/icons/Add'
import CardActions from '@material-ui/core/CardActions/CardActions'
import clsx from 'clsx'
import { makeStyles } from '@material-ui/core'
const useStyles = makeStyles(theme => ({
  primary: {
    color: theme.palette.common.white
  },
}));
function CurrentChatbotSelectorList ({data,handleListItemClick,showAdd=true,light=false,currentAgentKey}) {
  const classes = useStyles();
  return (
    <List>
      {data.chatbotsAll.map(({name,agentKey}) => (
        <ListItem disabled={currentAgentKey === agentKey} button onClick={() => {
          console.log(agentKey)
          handleListItemClick(agentKey)
        }} key={agentKey}>
          <ListItemAvatar>
            <Avatar>
              <AssistantIcon color={'secondary'} />
            </Avatar>
          </ListItemAvatar>
          <ListItemText className={clsx(light && classes.primary)} primary={name} />
        </ListItem>
      ))}
      {
        showAdd && <ListItem autoFocus button onClick={() => handleListItemClick('addAccount')}>
          <ListItemAvatar>
            <Avatar>
              <AddIcon />
            </Avatar>
          </ListItemAvatar>
          <ListItemText className={clsx(light && classes.primary)} primary="Add chatbot" />
        </ListItem>
      }

    </List>
  )
}

export default CurrentChatbotSelectorList
