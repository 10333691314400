import {
  gql
} from '@apollo/client';


export const GET_CHATBOT = gql`
  query GET_CHATBOT {
    chatbot {
      name
      disabled
      agentKey
      embedCode
    }
  }
`;
