import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import Typography from "@material-ui/core/Typography";
import PersonIcon from "@material-ui/icons/Person";
import data from "./../../../../data/transcript";
import { FixedSizeList as List } from "react-window";
import Sentiment from '../../../Sentiment'
import {RELOAD_PERIOD} from '../../../../utils/settings'
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  Redirect,
  useHistory,
  useLocation
} from "react-router-dom";

import {
  gql, useQuery
} from '@apollo/client';
import Loading from '../../../Loading'
import { GET_CONVERSATIONS,GET_CURRENT_USER } from './queries'
const useStyles = makeStyles({
  overFlowString: {
    textOverflow: 'ellipsis',
    width: 300,
    whiteSpace: 'nowrap',
    overflow: 'hidden'
  }
});

function RowComp(props) {
  const { data, index, style } = props;
  const classes = useStyles();

  const record = data[index];
  let history = useHistory();
  const handleToggle = value => () => {
    history.push("/dashboard/transcripts/");
  };
  return (
    <ListItem button alignItems="flex-start"
              style={props.style}
              className={classes.root}
              component="div"
              ContainerComponent="div"
              onClick={handleToggle(record)}>

      <ListItemAvatar>
        <PersonIcon alt="Remy Sharp" src="/static/images/avatar/1.jpg" />
      </ListItemAvatar>
      <ListItemText
        primary={new Date(record.startTime).toUTCString()}
        secondaryTypographyProps={{
          "component" : "span"
          }
        }
        secondary={
          <React.Fragment>
            <Typography
              component="span"
              variant="body2"
              className={classes.inline}
              color="textPrimary"
            >
              In: {record.interactions.filter(inter=>inter.sender==='IN').length} Out: {record.interactions.filter(inter=>inter.sender==='OUT').length}
            </Typography>
            <div className={classes.overFlowString}>{data[index].interactions[0].message}</div>

            <ListItemSecondaryAction>
              <Sentiment sentiment={record.sentiment}/>
            </ListItemSecondaryAction>

          </React.Fragment>
        }
      />
    </ListItem>
  );
}


const Row = React.memo(RowComp);

export default function DashboardWidgetTranscriptsListSmall({startDate,endDate, currentUser}) {

  const { data, loading, error } = useQuery(GET_CONVERSATIONS,{
    pollInterval: RELOAD_PERIOD,
    variables: {
      startDate: startDate,
      endDate: endDate,
      agentKey: currentUser ? currentUser.currentAgentKey :  null
    }
  })
  if (loading) return <Loading/>;
  if (error) return <Loading/>;
  if (!data.conversationsAll) return <p>No Data found</p>;
  if (data.conversationsAll.length <=0) return <p>No Data found</p>;


  return (
    <List
      height={220}
      itemSize={80}
      itemHeight={80}
      itemCount={data.conversationsAll.conversations.length}
      itemData={data.conversationsAll.conversations}
    >{Row}</List>


  );
}
