// Load Roboto typeface
import 'typeface-work-sans';
import React from 'react';
import ReactDOM from 'react-dom';

import Bugsnag from '@bugsnag/js'
import BugsnagPluginReact from '@bugsnag/plugin-react'

import MomentUtils from '@date-io/moment';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { Auth0Provider } from './utils/react-auth0-spa'
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import history from "./utils/history";
import { createMuiTheme,ThemeProvider,responsiveFontSizes } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import AuthorizedApolloProvider from './components/AuthorizedApolloProvider'
import { SnackbarProvider } from "notistack";
//after login redirect
const onRedirectCallback = appState => {
  history.push(
    appState && appState.targetUrl
    ? appState.targetUrl
    : window.location.pathname
  );
};

const config = {
  "domain": "thebotforge.eu.auth0.com",
  "clientId": "CCaL1uS8lg3xPmHoDuMqj2a5ZvyTHUSJ",
  "audience": "https://api.chatbotforge.com"
}

Bugsnag.start({
  apiKey: '3f5643beee4c04cf8dee96849f719432',
  plugins: [new BugsnagPluginReact()]
})
const ErrorBoundary = Bugsnag.getPlugin('react')
  .createErrorBoundary(React)
const theme = createMuiTheme({
  typography: {
    fontFamily: 'Work Sans,sans-serif',
  },
  palette: {
    text: {
      primary: "#1E183C"
    },
    primary: {
      main: '#704D99'
    },
    secondary: {
      main: '#C8569A',
    },
    dark: {
      main: "#1E183C"
    },
    userColor: {
      main: '#ffa600'
    },
    chatbotColor: {
      main: '#f95d6a'
    }
  }
});
responsiveFontSizes(theme);
ReactDOM.render(
  <ErrorBoundary>
    <Auth0Provider
      domain={config.domain}
      client_id={config.clientId}
      redirect_uri={window.location.origin}
      onRedirectCallback={onRedirectCallback}
      audience={config.audience}
    >
      <AuthorizedApolloProvider>

        <ThemeProvider theme={theme}>
          <CssBaseline />
          <MuiPickersUtilsProvider utils={MomentUtils}>
            <SnackbarProvider>
              <App />
            </SnackbarProvider>
          </MuiPickersUtilsProvider>
        </ThemeProvider>
      </AuthorizedApolloProvider>
    </Auth0Provider>
  </ErrorBoundary>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
