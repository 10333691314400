import React from "react";
import DashboardWidget from "./../DashboardWidget";

import DashboardWidgetInteractionActivityCounters from '../DashboardWidgetInteractionActivityCounters'
import Badge from '@material-ui/core/Badge/Badge'
import ChatIcon from '@material-ui/icons/Chat'
import PeopleIcon from '@material-ui/icons/People';
const defaultProps = {
  color: 'secondary',
  children: <PeopleIcon />,
};
const DashboardWidgetInteractionActivity = ({startDate,endDate,customTitle,currentUser}) => {
  return (
    <DashboardWidget
      title={"Interaction Activity"}
      subtitle={customTitle}
      small={true}
      to="/dashboard/reports/"
      //label="View Interaction Activity Report"
      badge={<Badge badgeContent={'LIVE'} {...defaultProps} />}>
      <DashboardWidgetInteractionActivityCounters currentUser={currentUser} startDate={startDate} endDate={endDate}/>
    </DashboardWidget>
  );
};

export default DashboardWidgetInteractionActivity;
