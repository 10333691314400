import React, { Fragment, useState } from 'react'
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import PageHeader from "../../../Header/PageHeader";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import NotificationsGrid from "../NotificationsGrid";
import NotificationsActionPanel from '../NotificationsActionPanel'
const useStyles = makeStyles(theme => ({
  content: {
    padding: theme.spacing(2)
  }
}));
const Reports = () => {
  const classes = useStyles();
  const [openActionAdd, setOpenActionAdd] = React.useState(false);
  const [addMode, setAddMode] = useState(null);
  const handleClose = (value) => {
    setOpenActionAdd(false);
  };
  return (
    <Fragment>
      <Grid container justify={"center"} spacing={2}>
        <Grid item xs={12}>
          <Paper className={classes.content}>
            <Typography variant="body1" component="p">
              Stay up to date with the latest chatbot activities via SMS or
              Email including unhandled intents, handled intents, chat
              transcripts and more.
            </Typography>
            <NotificationsActionPanel showAdd={false} addMode={addMode} setAddMode={setAddMode} setOpenActionAdd={setOpenActionAdd}/>
            <NotificationsGrid addMode={addMode} setAddMode={setAddMode} setOpenActionAdd={setOpenActionAdd} handleClose={handleClose} openActionAdd={openActionAdd} notificationType={"REPORT"} />
          </Paper>
        </Grid>
      </Grid>
    </Fragment>
  );
};

export default Reports;
