import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import clsx from 'clsx';

const useStyles = makeStyles(theme => ({
  number: {
    fontSize: theme.typography.h2.fontSize,
    fontWeight: theme.typography.fontWeightBold
  },
  numberSmall: {
    fontSize: theme.typography.h5.fontSize,
    fontWeight: theme.typography.fontWeightBold
  }
}));
const DashboardWidgetCounterNumber = (props)=>{
  const {value,showSmall} = props;
  const classes = useStyles();
  return(
    <span className={clsx(classes.number, showSmall && classes.numberSmall) }>{value}</span>
  )
}
export default DashboardWidgetCounterNumber
