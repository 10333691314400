import { gql } from '@apollo/client'

export const GET_INTENTS_ALL = gql`
    query GET_INTENTS_ALL($startTime: String,$agentKey: String){ 
        intentsAll(startTime: $startTime, agentKey: $agentKey) {
          name,
          displayName
          count
      }
    }
`;

export const GET_CURRENT_USER = gql`
  {
    currentAgentKey @client
    currentUserName @client
    currentUserVersion @client
    currentUserEmail @client
    currentUserNickname @client
    currentCompanyName @client
    currentCompanyId @client
    currentCompanyApiKey @client
    currentChatbotLimit @client
  }
`;
