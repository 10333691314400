import {
  gql
} from '@apollo/client';




export const UPDATE_USER = gql`
  mutation updateUser(
    $id: ID!
    $agentKey: String
  ) {
    updateUser(
      id: $id
      agentKey: $agentKey
    ) {
      id
      agentKey
    }
  }
`;
