import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { LAST_3_MONTH, LAST_MONTH, LAST_WEEK, TODAY, YESTERDAY } from '../../../constants/CUSTOM_DATE_RANGE'
const useStyles = makeStyles((theme) => ({
  formControl: {
    //margin: theme.spacing(1),
    minWidth: 180
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

const CustomDateRange = ({customRange,setCustomRange}) => {
  const classes = useStyles();

  const handleChange = (event) => {
    setCustomRange(event.target.value);
  };
  return (
    <FormControl variant="standard" className={classes.formControl}>
      <InputLabel id="demo-simple-select-outlined-label">Custom Range</InputLabel>
      <Select
        labelId="demo-simple-select-outlined-label"
        id="demo-simple-select-outlined"
        value={customRange}
        onChange={handleChange}
        label="Range"
      >
        <MenuItem value="">
          <em>None</em>
        </MenuItem>
        <MenuItem value={TODAY}>Today</MenuItem>
        <MenuItem value={YESTERDAY}>Yesterday</MenuItem>
        <MenuItem value={LAST_WEEK}>Last week</MenuItem>
        <MenuItem value={LAST_MONTH}>Last month</MenuItem>
        <MenuItem value={LAST_3_MONTH}>Last 3 Months</MenuItem>
      </Select>
    </FormControl>
  )
}

export default CustomDateRange
