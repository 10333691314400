import { gql } from '@apollo/client'

export const GET_UNHANDLED_INTENTS_ALL = gql`
  query GET_UNHANDLED_INTENTS_ALL(
    $pagination: PaginationInput
    $filter: FilterIntentInput
    $startDate: String
    $endDate: String
    $agentKey: String
  ) {
    intentsUnhandledAll(
      pagination: $pagination
      filter: $filter
      startDate: $startDate
      endDate: $endDate
      agentKey: $agentKey
    ) {
        intentsTotalsUnhandled
        intentsUnhandled {
          message
          sender
          id
          responseTimestamp
          sentiment {
            score
            magnitude
          }
        }
      }
  }
`;

export const INTENTS_UNHANDLED_COMPARISON_GRAPH = gql`
  query INTENTS_UNHANDLED_COMPARISON_GRAPH(
    $startDate: String
    $endDate: String
    $agentKey: String
  ) {
    intentsUnhandledAllComparison(
      startDate: $startDate
      endDate: $endDate
      agentKey: $agentKey
    ) {
        month
        count
        year
        fallback
      }
  }
`;
