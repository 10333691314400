import React from 'react'

import SidebarNavItem  from './SidebarNavItem'



const SidebarNavItems = (props) => {
  const { items, isCollapsed, isNested, isMobile, handleDrawerOpen } = props

  return (
    <>
      {items.map((item, index) => (
        <SidebarNavItem
          {...item}
          isCollapsed={isCollapsed}
          isNested={isNested}
          key={index}
          isMobile={isMobile}
          handleDrawerOpen={handleDrawerOpen}
        />
      ))}
    </>
  )
}

export default SidebarNavItems
