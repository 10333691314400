import {
  gql
} from '@apollo/client';


export const ADD_CHATBOT = gql`
  mutation addChatbot(
    $name: String!
    $timezone: String
    $active: Boolean
    $platform: String 
    $companyId: String 
    $dfProjectId: String
  ) {
    addChatbot(
      name: $name
      timezone: $timezone
      active: $active
      platform: $platform
      companyId: $companyId
      dfProjectId: $dfProjectId
    ) {
      name
      timezone
      active
      platform
      companyId
      dfProjectId
    }
  }
`;

export const UPDATE_CHATBOT = gql`
  mutation updateChatbot(
    $_id: ID!
    $platform: String
    $timezone: String
    $name: String
    $active: Boolean
    $dfProjectId: String
  ) {
    updateChatbot(
      _id: $_id
      platform: $platform
      timezone: $timezone
      name: $name
      active: $active
      dfProjectId: $dfProjectId
    ) {
      _id
      name
      platform
      timezone
      active
      dfProjectId
    }
  }
`;

export const REMOVE_CHATBOT = gql`
  mutation deleteChatbot ($_id: ID!) {
      deleteChatbot(
        _id: $_id
      )
  }
`;
