import React from 'react'
import SentimentDissatisfiedIcon from '@material-ui/icons/SentimentDissatisfied'
import SentimentSatisfiedIcon from '@material-ui/icons/SentimentSatisfied'
import SentimentSatisfiedAltIcon from '@material-ui/icons/SentimentSatisfiedAlt';
import SentimentVeryDissatisfiedIcon from '@material-ui/icons/SentimentVeryDissatisfied';
import SentimentVerySatisfiedIcon from '@material-ui/icons/SentimentVerySatisfied';
import { makeStyles } from '@material-ui/core/styles'
import SentimentOption from './SentimentOption'

const useStyles = makeStyles(theme => ({
  vsat: {
    color: theme.palette.success.light
  },
  satalt: {
    color: theme.palette.success.main
  },
  sat: {
    color: theme.palette.success.dark
  },
  ds: {
    color: theme.palette.error.main
  },
  vds: {
    color: theme.palette.error.dark
  }
}));
const renderSentiment = ({sentiment,classes})=>{

  if(Number(sentiment) >= 0.5){
    return <SentimentOption sentiment={'SentimentVeryDissatisfiedIcon'}/>
  }else if(Number(sentiment) >= 0.4){
    return <SentimentOption sentiment={'SentimentDissatisfiedIcon'}/>
  }else if(Number(sentiment) >= 0.2){
    return <SentimentOption sentiment={'SentimentSatisfiedIcon'}/>
  }else if(Number(sentiment) >= 0.1){
    return <SentimentOption sentiment={'SentimentSatisfiedAltIcon'}/>
  }else{
    return <SentimentOption sentiment={'SentimentVerySatisfiedIcon'}/>
  }
}
const Sentiment = (props)=>{
  const { sentiment } = props;
  const classes = useStyles();
  return(
    renderSentiment({sentiment:sentiment, classes:classes})
  )
}

export default Sentiment;
