import React from 'react'
import Dashboard from '../views/Dashboard'
import {
  Switch,
  Route, BrowserRouter as Router
} from 'react-router-dom'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import Transcripts from '../views/Transcripts/index'
import Analytics from '../views/Analytics/index'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography';
import InputBase from '@material-ui/core/InputBase';
import MenuIcon from '@material-ui/icons/Menu';
import SearchIcon from '@material-ui/icons/Search';

import clsx from 'clsx';
import { makeStyles, useTheme, withStyles } from '@material-ui/core/styles'
import Drawer from '@material-ui/core/Drawer';
import Grid from '@material-ui/core/Grid';

import Container from '@material-ui/core/Container';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import Box from '@material-ui/core/Box';
import Divider from '@material-ui/core/Divider';
import {useAuth0 } from '../utils/react-auth0-spa';
import Profile from '../components/User/Profile'
import RouterBreadcrumbs from '../components/RouterBreadcrumbs'
import Header from '../components/Header'
import HeaderLogo from '../components/Header/HeaderLogo'
import GatewayServices from '../views/GatewayServices'
import PageHeader from '../components/Header/PageHeader'
const drawerWidth = 242;


const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
  },
  toolbar: {
    height: 48,
    paddingRight: 24, // keep right padding when drawer closed
  },
  toolbarIcon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 8px',
    ...theme.mixins.toolbar,
  },

  menuButton: {
    marginRight: 36,
  },
  menuButtonHidden: {
    display: 'none',
  },
  title: {
    flexGrow: 1,
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: '100vh',
    overflow: 'auto',
  },
  container: {
    padding: theme.spacing(2)
  },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
  fixedWidth: {
    width: 240,
    display: 'flex'
  },
  breadCrumb: {
    marginTop: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing(1),
    },
  }
}));




export default function PageLayout() {
  const classes = useStyles();
  const theme = useTheme()
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'))
  const isMobile = !isDesktop
  const [open, setOpen] = React.useState(true);
  const { isAuthenticated } = useAuth0();
  const handleDrawerOpen = () => {
    setOpen(true);
  };

  return (
    <div className={classes.root}>
      <AppBar position="absolute">
        <Toolbar className={classes.toolbar}>
          <div className={classes.fixedWidth}>
            <HeaderLogo white={true}/>
          </div>
          <Header/>
          <div className={classes.search}>
            <div className={classes.searchIcon}>
              <SearchIcon />
            </div>
            <InputBase
              placeholder="Search…"
              classes={{
                root: classes.inputRoot,
                input: classes.inputInput,
              }}
              inputProps={{ 'aria-label': 'search' }}
            />
          </div>
        </Toolbar>
      </AppBar>

      <main className={classes.content}>
        <PageHeader/>
        <div className={classes.breadCrumb}><RouterBreadcrumbs/></div>
        <Container maxWidth="lg" className={classes.container}>
          <Grid container spacing={3}>
            <Switch>
              <Route path="/gatewayservices" exact component={GatewayServices} />
            </Switch>
          </Grid>
        </Container>
      </main>
    </div>
  );
}
