import React from 'react';
import Button from '@material-ui/core/Button';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import { makeStyles } from '@material-ui/core/styles';


import {
  useApolloClient, useQuery, gql
} from '@apollo/client';
import { Chatbots as locale } from '../../assets/locale/en'


function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    '& > * + *': {
      marginTop: theme.spacing(2),
    },
  },
}));
const GET_CURRENT_SNACKBAR = gql`
    {
      showSnackBar @client
      snackBarMessage @client
      severity @client
    }
  `;
const Customsnackbar = () => {
  //TODO implement a mechanism for other severity types
  const client = useApolloClient();
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);

  const { data, loading, error } = useQuery(GET_CURRENT_SNACKBAR);
  const handleClick = () => {
    setOpen(true);
  };

  React.useEffect(() => {
    if(data){
      setOpen(data.showSnackBar)
    }
  }, [data]);
  if(!data) return null;


  const handleClose = (event, reason) => {

    client.writeQuery({
      query : gql`
        query {
           showSnackBar
           snackBarMessage
           severity
         }`,
      data: {
        showSnackBar: false,
        snackBarMessage: '',
        severity: data.severity
      },
    });

    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };

  return (
    <div className={classes.root}>
      <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleClose} severity={data.severity || 'success'}>
          {data.snackBarMessage}
        </Alert>
      </Snackbar>
      {/*<Alert severity="error">This is an error message!</Alert>*/}
      {/*<Alert severity="warning">This is a warning message!</Alert>*/}
      {/*<Alert severity="info">This is an information message!</Alert>*/}
      {/*<Alert severity="success">This is a success message!</Alert>*/}
    </div>
  );
}

export default Customsnackbar
