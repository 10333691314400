import React from 'react';
import PropTypes from 'prop-types';

import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions';
const SimpleDialog = ({ cancelTxt="Cancel", confirmTxt="Save Changes", onClose,onSave, open, children, title, subtitle,fullWidth=false,maxWidth='md',isValid,showSave=true}) => {

  const handleClose = () => {
    onClose();
  };

  return (
    <Dialog onClose={handleClose} aria-labelledby="simple-dialog-title" open={open} fullWidth={true}
            maxWidth={maxWidth}>
      <DialogTitle id="simple-dialog-title">{title}</DialogTitle>
      <DialogContent>
        {subtitle && <Typography variant="caption" component="p">
          {subtitle}
        </Typography>}

          {children}
      </DialogContent>
      <DialogActions>
        {
         showSave && <Button autoFocus onClick={onSave} color="primary" disabled={!isValid}>
            {confirmTxt}
          </Button>
        }
          <Button
         autoFocus onClick={onClose} color="primary">
          {cancelTxt}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

SimpleDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired
};

export default SimpleDialog;
