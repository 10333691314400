import React from 'react'
import Dashboard from '../views/Dashboard'
import {
  Switch,
  Redirect,
  useHistory,
  Route, BrowserRouter as Router, Link as RouterLink
} from 'react-router-dom'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import Transcripts from '../views/Transcripts/index'
import Analytics from '../views/Analytics/index'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import IconButton from '@material-ui/core/IconButton'
import MenuIcon from '@material-ui/icons/Menu';
import Hidden from '@material-ui/core/Hidden';
import clsx from 'clsx';
import { makeStyles, useTheme } from '@material-ui/core/styles'
import Drawer from '@material-ui/core/Drawer';
import Grid from '@material-ui/core/Grid';

import Container from '@material-ui/core/Container';
import Divider from '@material-ui/core/Divider';
import {useAuth0 } from '../utils/react-auth0-spa';
import Profile from '../components/User/Profile'
import RouterBreadcrumbs from '../components/RouterBreadcrumbs'
import Sidebar from '../components/Sidebar'
import Notifications from '../views/Notifications'
import Footer from '../components/Footer/index'
import Embed from '../views/Embed'
import { GET_COMPANY } from './queries'

import {
  useApolloClient, useQuery
} from '@apollo/client';
import Loading from '../components/Loading'
import Chatbots from '../components/Chatbots'
import MainMenu from '../components/MainMenu'
import Header from '../components/Header'
import Account from '../views/Account'
import { GET_CURRENT_USER } from '../components/Chatbots/queries'
import CurrentChatbotSelector from '../components/CurrentChatbot/CurrentChatbotSelector'
import { GET_CHATBOT } from '../components/CurrentChatbot/queries'
const drawerWidth = 242;


const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flex: 1,
    height: 'calc(100vh - 36px)'
  },
  toolbar: {
    paddingRight: 24, // keep right padding when drawer close
  },
  toolbarIcon: {
    display: 'flex',
    alignItems: 'center',
    ...theme.mixins.toolbar,
    margin: 10
  },
  toolbarButton: {
    backgroundColor: '#dee1e5',
    borderRadius: "40px",
    padding: "4px",
    display: 'flex',
    alignItems: 'center',
    '&:hover': {
      background: "#ccc",
      cursor: "pointer"
    },
  },
  toolbarMenu: {
    backgroundColor: theme.palette.primary.main,
    borderRadius: "40px",
    //zIndex: theme.zIndex.drawer + 2,
  },
  appBar: {
    backgroundColor: 'rgb(112,77,153)',
    //background: 'linear-gradient(90deg, rgba(112,77,153,1) 55%, rgba(237,138,76,1) 100%)',
    //zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    })
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  menuButtonHidden: {
    display: 'none',
  },
  title: {
    flexGrow: 1,
  },
  drawerPaper: {
    position: 'relative',
    whiteSpace: 'nowrap',
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    zIndex: 19
  },
  drawerPaperClose: {
    overflowX: 'hidden',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    [theme.breakpoints.down('sm')]: {
      width: 0,
    },
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(11),
    },
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    overflow: 'auto',
    display: 'flex'

  },
  container: {
      padding: theme.spacing(2),
      [theme.breakpoints.down('sm')]: {
        padding: theme.spacing(0),
    },
  },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
  fixedHeight: {
    height: 240,
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end',
  },
}));

export default function DashboardLayout() {
  const classes = useStyles();
  const history = useHistory();
  const theme = useTheme()
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'))
  const isMobile = !isDesktop
  const [open, setOpen] = React.useState(true);
  const { isAuthenticated, logout } = useAuth0();
  const client = useApolloClient();
  const [selectedValue, setSelectedValue] = React.useState();
  const [noCurrentChatbotSelectedOpen, setNoCurrentChatbotSelectedOpen] = React.useState(false);
  const {loading:currentUserLoading, data: currentUserData, error:currentUserError } = useQuery(
    GET_CURRENT_USER,{onCompleted(data){
      if(!data.currentAgentKey) setNoCurrentChatbotSelectedOpen(true);
    }}
  );

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const openMainMenu = Boolean(anchorEl);
  const id = openMainMenu ? 'transitions-popper' : undefined;
  const handleDrawerOpen = () => {
    setOpen(!open);
  };
  const handleDrawerClose = () => {
    setOpen(false);
  };

  const handleClose = () => {
    setNoCurrentChatbotSelectedOpen(false);
    history.push("/dashboard/chatbots");
  };


  return (
    <div className={classes.root}>
      <Hidden smUp>
        <AppBar position="absolute" className={clsx(classes.appBar, open && classes.appBarShift)}>
          <Toolbar className={classes.toolbar}>
            <IconButton
              edge="start"
              color="inherit"
              aria-label="open drawer"
              onClick={handleDrawerOpen}
              className={clsx(classes.menuButton, open && classes.menuButtonHidden)}
            >
              <MenuIcon />
            </IconButton>
            {/*<Header/>*/}
          </Toolbar>
        </AppBar>
      </Hidden>

      <Drawer
        variant="permanent"
        classes={{
          paper: clsx(classes.drawerPaper, !open && classes.drawerPaperClose),
        }}
        open={open}
      >
        <MainMenu isAuthenticated={isAuthenticated} handleDrawerOpen={handleDrawerOpen} logout={logout}/>
        {/*<Divider />*/}
        <Sidebar
          isDesktop={isDesktop}
          isMobile={isMobile}
          isSidebarCollapsedDesktop={open}
          isSidebarOpenMobile={open}
          handleDrawerOpen={handleDrawerOpen}
        />

        {/*<List>{dashboardMenuItems}</List>*/}
        <Divider />
      </Drawer>

      <main className={classes.content}>
        <Container maxWidth={false} className={classes.container}>
            <Grid container spacing={1}>
            {isMobile && <div className={classes.drawerHeader} />}
            <Header/>
              {
                !currentUserLoading && !currentUserData && currentUserError ? (
                  <Loading/>
                ) : (
                  <React.Fragment>

                    {<CurrentChatbotSelector selectedValue={selectedValue} open={noCurrentChatbotSelectedOpen} onClose={handleClose} />}
                    {/*{<CurrentChatbotSelector selectedValue={selectedValue} open={noCurrentChatbotSelectedOpen} onClose={handleClose} />}*/}
                        <React.Fragment>
                          <Switch>
                            <Route path="/dashboard" exact component={Dashboard}/>
                            <Route path="/dashboard/chatbots" component={Chatbots}/>
                            <Route path="/dashboard/notifications" component={Notifications}/>
                            <Route path="/dashboard/transcripts" component={Transcripts}/>
                            <Route path="/dashboard/reports" component={Analytics}/>
                            <Route path="/dashboard/embed" exact component={Embed}/>
                            <Route path="/dashboard/account" exact component={Account}/>
                          </Switch>
                        </React.Fragment>


                  </React.Fragment>
                )

              }
          </Grid>
        </Container>
      </main>


    </div>
  );
}
