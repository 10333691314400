export const CurrentChatbotSelector = {
  ERROR_UPDATING_USER : 'There was a problem updating your current chatbot',
  SET_CHATBOT_SUCCESS : 'Current chatbot set'
}

export const Chatbots  = {
  ERROR_CREATING_CHATBOT : 'There was a problem creating your Chatbot',
  CHATBOT_CREATED: 'Chatbot created'
}

export const ChatbotName = {
  CODE_COPIED_TO_CLIPBOARD : 'Code copied to clipboard'
}

export const CurrentChatbot = {
  BOOTSTRAP_CURRENT_CHATBOT_DETAILS : 'View current agent details',
  SET_CHATBOT_SUCCESS : 'Current chatbot set'
}

export const AccountDetails = {
  PROBLEM_WITH_CREATING_KEY : 'There was a problem creating your API key',
  API_KEY_CREATED : 'API key generated',
  CODE_COPIED_TO_CLIPBOARD : 'Code copied to clipboard'
}


