import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import ListItemText from '@material-ui/core/ListItemText';
import ListItem from '@material-ui/core/ListItem';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';
import Grow from '@material-ui/core/Grow/Grow'
import bg from '../../assets/img/chatseer-logo@1680x945.png'
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Grow ref={ref} {...props} />;
});
const useStyles = makeStyles(theme => ({
  getStartedBar: {
    position: "relative"
  },
  getStartedDialog: {
    display: "flex",
    flexWrap: "wrap",
    flexDirection: "column",
    alignContent: "center",
    alignItems: "center",
    minHeight: '100%',
    [theme.breakpoints.up('md')]: {
      background: `${theme.palette.primary.main} no-repeat center/100% url("${bg}")`,
    },
    [theme.breakpoints.down('sm')]: {
      background: `${theme.palette.primary.main} no-repeat center/100% url("${bg}")`,
      backgroundSize: 'cover',
      backgroundPosition: 'bottom right'
    }
  },
  header: {
    fontWeight: theme.typography.fontWeightBold,
    color: theme.palette.common.black,
    padding: theme.spacing(1),
    display: "flex",
    backgroundColor: theme.palette.common.white
  },
  menuRoot: {
    width: '100%',
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  },
}));
function ListItemLink(props) {
  return <ListItem button component="a" {...props} />;
}
const MobileMenu = ({open,setOpen, handleClose})=>{
  const classes = useStyles();
  return (
    <Dialog
      fullScreen
      open={open}
      onClose={handleClose}
      TransitionComponent={Transition}
      classes={{
        paper: classes.getStartedDialog
      }}>
      <AppBar className={classes.appBar}>
        <Toolbar>
          <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
            <CloseIcon />
          </IconButton>
          <Typography variant="h6" className={classes.title}>
            Sound
          </Typography>
          <Button autoFocus color="inherit" onClick={handleClose}>
            save
          </Button>
        </Toolbar>
      </AppBar>
      <div className={classes.menuRoot}>
        <List component="nav" aria-label="secondary mailbox folders">
          <ListItem button>
            <ListItemText primary="Trash" />
          </ListItem>
          <ListItemLink href="#simple-list">
            <ListItemText primary="Spam" />
          </ListItemLink>
        </List>
      </div>
    </Dialog>
  )
}

export default MobileMenu;
