import IconButton from '@material-ui/core/IconButton';
import RefreshIcon from "@material-ui/icons/Refresh";
import FromToDateTimePicker from '../Picker/FromToDateTimePicker';
import Search from '../Search';
import {CSVLink} from 'react-csv';
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import ImportExportIcon from '@material-ui/icons/ImportExport';
import SimpleDialog from '../SimpleDialog';

const useStyles = makeStyles(theme => ({
  toolbar: {
    fontSize: theme.typography.h6.fontSize,
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    padding: 20
  }
}));
const MaterialTableToolbar = ({setQ, currentFilter, refetch, startDate, setStartDate, endDate, data, csvData, csvExportFile,csvParseData}) =>{
  const classes = useStyles();
  const [download, setDownload] = React.useState(false);

  const handleClose = (value) => {
    setDownload(false);
  };

  return (<React.Fragment><div className={classes.toolbar}>
    Refresh{" "}
    <IconButton
      color="primary"
      aria-label="refresh"
      onClick={() => refetch()}
      component="span"
    ><RefreshIcon />
    </IconButton>
    <FromToDateTimePicker
      startDate={startDate}
      handleFrom={setStartDate}
      endDate={endDate}
      elevation={0}
    />
      <Search setQ={setQ} q={ currentFilter ? currentFilter.query : '' }/>
      <IconButton  onClick={() => {
        setDownload(!download)
      }} aria-label="export-data">
        <ImportExportIcon fontSize="large" />
      </IconButton>
    {
      download && <SimpleDialog title={'Download CSV'} onClose={handleClose} open={download}>
        <CSVLink
          data={csvParseData(data)}
          filename={csvExportFile}
          className="btn btn-primary"
          target="_blank"
          onClick={() => {
            setDownload(false)
          }}
        >
          {csvExportFile}
        </CSVLink>
      </SimpleDialog>
    }
  </div>
  </React.Fragment>
  )
}

export default MaterialTableToolbar;
