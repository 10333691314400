import React, { useState } from 'react'
import MaterialTable from 'material-table'
import FirstPageIcon from '@material-ui/icons/FirstPage'

import List from '@material-ui/core/List'
import Chip from '@material-ui/core/Chip';
import Avatar from '@material-ui/core/Avatar';

import _keys from 'lodash/keys'
import moment from 'moment'
import { useAuth0 } from '../../../../../utils/react-auth0-spa'
import {
  useQuery
} from '@apollo/client';

import ArrowUpIcon from "@material-ui/icons/ArrowUpward";
import LastPageIcon from "@material-ui/icons/LastPage";
import NextPageIcon from "@material-ui/icons/NavigateNext";
import PreviousPageIcon from "@material-ui/icons/NavigateBefore";

import SearchIcon from "@material-ui/icons/Search";
import ExportIcon from "@material-ui/icons/SaveAlt";
import ClearIcon from "@material-ui/icons/Clear";
import ChatIcon from "@material-ui/icons/ChatBubble";

import { makeStyles } from '@material-ui/core/styles'
import {
  gql
} from '@apollo/client';


import MaterialTableToolbar from '../../../../MaterialTableToolbar'
import SimpleDialog from '../../../../SimpleDialog'
import Typography from '@material-ui/core/Typography'
import { GET_INTENTS_ALL,GET_CURRENT_USER } from './queries'


const useStyles = makeStyles(theme => ({
  interactionAgent: {
    backgroundColor: theme.palette.grey[100],
    padding: theme.spacing(2),
    paddingLeft: theme.spacing(4)
  },
  interactionUser: {
    backgroundColor: theme.palette.grey[300],
    padding: theme.spacing(2),
    paddingLeft: theme.spacing(4)
  },
  transcriptDetailPanel: {
    paddingLeft: theme.spacing(2),
    backgroundColor: theme.palette.primary.light
  },
  root: {
    padding: 0,
    margin: 0
  },
  user: {
    color: theme.palette.success.main
  },
  anchorOriginBottomLeftCircle: {
    bottom: 30
  },
  toolbar: {
    fontSize: theme.typography.h6.fontSize,
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    padding: 20
  },
  messageIcon: {
    marginLeft: 10,
    marginRight: 10
  },
  chip: {
    margin: theme.spacing(1),
  },
}));


function generate(data, classes, handleUtteranceClick) {
  const messages = data.map(m=> m.message);

  const score = message =>data.find(element => element.message === message);
  let counts = {};
  messages.forEach(function(x) { counts[x] = (counts[x] || 0)+1; });
  return Object.keys(counts).map((key,index)=>{
    return <Chip onClick={()=> handleUtteranceClick({label:key,intentDetectionConfidence: score(key)['intentDetectionConfidence']})} key={key} size={'small'} label={key} className={classes.chip} avatar={<Avatar>{counts[key]}</Avatar>} />
  });


}


const IntentsDetails = ()=>{
  const defaultPageSize = 20;
  const classes = useStyles();
  const {loading:loadingUser, data:dataUser, error:errorUser } = useQuery(GET_CURRENT_USER)
  const [q, setQ] = React.useState(undefined);
  const [startDate, setStartDate] = useState(moment().subtract("7", "days"));
  const [endDate, setEndDate] = useState(new Date(Date.now()));
  const [currentPage, setCurrentPage] = React.useState(0);
  const [currentPageSize, setCurrentPageSize] = React.useState(defaultPageSize);
  const [currentPagination, setCurrentPagination] = React.useState({
    page: currentPage,
    pageSize: currentPageSize
  });
  const [currentFilter, setCurrentFilter] = React.useState({
    query: q
  });
  const [openUtteranceDetail, setOpenUtteranceDetail] = React.useState(false)
  const [utteranceDetail, setUtteranceDetail] = React.useState({})
  const handleUtteranceClick = ({label,intentDetectionConfidence}) => {
    setOpenUtteranceDetail(true)
    setUtteranceDetail({label: label,intentDetectionConfidence: intentDetectionConfidence})
  }
  const handleClose = (value) => {
    setOpenUtteranceDetail(false);
  };
  React.useEffect(() => {
    if (currentPage !== undefined && currentPageSize !== undefined) {
      setCurrentPagination({
        page: currentPage,
        pageSize: currentPageSize
      });
    } else {
      setCurrentPagination(undefined);
    }
  }, [currentPage, currentPageSize, setCurrentPagination]);
  React.useEffect(() => {
    if (
      q !== undefined &&
      q !== ""
    ) {
      setCurrentFilter({
        query: q
      });
    } else {
      setCurrentFilter(undefined);
    }
  }, [q, setCurrentFilter]);

  const { data, loading, error, refetch:refetchALL, networkStatus } = useQuery(
    GET_INTENTS_ALL,
    {
      notifyOnNetworkStatusChange: true,
      variables: {
        startDate: startDate,
        endDate: endDate,
        pagination: {
          limit: currentPageSize,
          offset: currentPageSize * currentPage
        },
        filter: currentFilter,
        agentKey: dataUser.currentAgentKey
      }
    }
  );
  const parseDataForCSVExport = (data) => {
    return data.map(intent=>{
      return {
        intent:intent.intent,
        messages: intent.messages.map(msg=> msg.message).toString()
      }
    })
  }

  return (
    <React.Fragment>
      <SimpleDialog title={'Utterance details'} cancelTxt={'Close'} showSave={false} onClose={handleClose} open={openUtteranceDetail}>
        {
          utteranceDetail && <div>
            <Typography
              gutterBottom
              variant="subtitle1"
              className={classes.inline}
            >
              {utteranceDetail.label}
            </Typography>

            <Typography
              component="span"
              variant="subtitle1"
              className={classes.inline}
              color="textPrimary"
            >
              Confidence Score:
            </Typography>
            <Typography
              component="span"
              variant="body1"
              className={classes.inline}
              color="textPrimary"
            > {utteranceDetail.intentDetectionConfidence}
            </Typography>

          </div>
        }

      </SimpleDialog>
      <Typography variant="body1" component="p">
        Matched Intent Details
      </Typography>
    <MaterialTable
      //columns={columns}
      data={data ? data.intentsAllStats ? data.intentsAllStats.intents.map(o => ({ ...o })) : [] : []}
      totalCount={data ? data.intentsAllStats.intentsTotal : 0}
      isLoading={loading || networkStatus === 4}
      icons={{
        FirstPage: FirstPageIcon,
        LastPage: LastPageIcon,
        NextPage: NextPageIcon,
        PreviousPage: PreviousPageIcon,
        SortArrow: ArrowUpIcon,
        Search: SearchIcon,
        ResetSearch: ClearIcon,
        Export: ExportIcon
      }}
      components={{
        Toolbar: props => ( <MaterialTableToolbar
          refetch={refetchALL}
          startDate={startDate}
          setStartDate={setStartDate}
          endDate={endDate}
          csvExportFile={'matched-intents.csv'}
          setQ={setQ}
          currentFilter={ currentFilter }
          data={data ? data.intentsAllStats ? data.intentsAllStats.intents : [] : []}
          csvParseData={parseDataForCSVExport}/>
          )
      }}
      options={{
        search: false,
        pageSize: defaultPageSize,
        emptyRowsWhenPaging: false,
        //exportButton: true,
        pageSizeOptions: [20, 50, 100]
      }}
      onSearchChanged={(event)=>{
        event.preventDefault();
        setQ(event.target.value)
      }}
      onChangePage={nextPage => {
        setCurrentPage(nextPage);
      }}
      page={currentPage }
      onChangeRowsPerPage={nextPerPage => {
        setCurrentPageSize(nextPerPage);
        setCurrentPage(0);
      }}
      detailPanel={[
        {
          tooltip: "Show Name",
          icon: () => <ChatIcon />,
          render: rowData => {
            return (
              <div className={classes.transcriptDetailPanel}>
                <List
                  classes={{
                    root: classes.root
                  }}
                  disablePadding={true}
                  dense={true}
                >
                  {generate(rowData.messages, classes, handleUtteranceClick)}
                </List>
              </div>
            );
          }
        }
      ]}
      columns={[
        {
          title: "Intent",
          field: "intent",
          numeric: false,
          sorting: true
        },
        {
          title: "Sessions",
          field: "messages",
          sorting: false,
          render: rowData => {
            let counts = {};
            const messages = rowData.messages.map(m=> m.sessionId)
              .forEach(function(x) { counts[x] = (counts[x] || 0)+1; });
            return _keys(counts).length;
          }
        },
        // {
        //   title: "Messages Out",
        //   field: "messagesOut",
        //   sorting: false,
        //   customSort: rowData => messagesCount(rowData, "OUT"),
        //   render: rowData => messagesCount(rowData, "OUT")
        // }
        // {
        //   field: 'sentiment',
        //   title: 'Sentiment',
        //   render: rowData => <Sentiment sentiment={(rowData.sentiment)? rowData.sentiment.score : ''}/>
        // },
      ]}
    />
    </React.Fragment>
  );
}

export default IntentsDetails
