import Typography from "@material-ui/core/Typography";
import React, { Fragment } from "react";
import useMediaQuery from '@material-ui/core/useMediaQuery/useMediaQuery'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import { ROUTE_NAME_MAP } from '../../../constants/ROUTE_NAME_MAP'
import { useLocation } from "react-router-dom";
import Grid from '@material-ui/core/Grid'
import CurrentChatbot from '../../CurrentChatbot'
const useStyles = makeStyles(theme => ({
  headerText: {
    display: 'flex',
    flexGrow: 1,
    fontWeight: theme.typography.fontWeightBold,
  },
}));
const PageHeader = ({ title, action }) => {
  const classes = useStyles();
  let location = useLocation();
  const theme = useTheme()
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'))
  const isMobile = !isDesktop
  return (
    <Fragment>
      {!isMobile && <Grid
        container
        direction="row"
        justify="space-between"
        alignItems="center"
      ><Typography component="span" variant="h5" color="inherit" noWrap className={classes.headerText}>
        {ROUTE_NAME_MAP[location.pathname]}
      </Typography>
        {<CurrentChatbot/>}
      </Grid>}
    </Fragment>
  );
};

export default PageHeader;
