
import React, { Fragment } from 'react'
import ReactDOM from 'react-dom';
import SimpleDialog from '../SimpleDialog'
import { AlertText } from '../AlertText'

const CancelDialog = ({ isShowing, hide, confirm, confirmText, confirmButtonText = "Go Ahead"}) => isShowing ? ReactDOM.createPortal(
  <SimpleDialog
    title={"Are you sure?"}
    subtitle={``}
    isValid={true}
    fullWidth={true}
    maxWidth={"sm"}
    confirmTxt={confirmButtonText}
    onSave={() => {
      confirm();
    }}
    onClose={hide}
    open={true}
  >
    <AlertText text={confirmText}/>

  </SimpleDialog>, document.body
) : null;

export default CancelDialog;
