import React  from 'react'
import {Bar} from 'react-chartjs-2';
import moment from 'moment'

import {
  useQuery, gql
} from '@apollo/client';
import Loading from '../../../../Loading'
import Grid from '@material-ui/core/Grid'
import PageHeader from '../../../../Header/PageHeader'
import { BLUE, DARK_BLUE, VIOLET, PINK } from '../../../../../constants/GRAPH_COLOURS'
import { INTENTS_UNHANDLED_COMPARISON_GRAPH } from '../queries'
import { GET_CURRENT_USER } from '../../../Transcripts/queries'



const UnhandledIntentsComparisonGraph = (props) =>{
  const {loading:loadingUser, data:dataUser, error:errorUser } = useQuery(GET_CURRENT_USER);
  const { data, loading, error } = useQuery(INTENTS_UNHANDLED_COMPARISON_GRAPH,{
    variables: {
      startDate: props.startDate,
      endDate: props.endDate,
      agentKey: dataUser.currentAgentKey
    }
  })

  const createMonthsWithData = (data) => {
    const months = []
    const dateStart = moment()
    const dateEnd = moment().add(12, 'month')
    const intentCount = (dateStart, data, isFallback=false) =>{
      return data.find(dt => {
        let checkYear = dateStart.year();
        checkYear -=1;
        return dt.month - 1 === dateStart.month() && dt.year === checkYear && dt.fallback === isFallback;
      })
    }

    while (dateEnd.diff(dateStart, 'months') >= 0) {
      const isfallback = intentCount(dateStart, data.intentsUnhandledAllComparison, true);
      const notFallback = intentCount(dateStart, data.intentsUnhandledAllComparison);
      months.push({month : dateStart.format('MMMM'), data: {intentFallback: isfallback ? isfallback.count : 0, intent: notFallback ? notFallback.count : 0}})
      dateStart.add(1, 'month')
    }

    return months
  }

  if (loading) return <Loading/>;
  if (error) return <Loading/>;
  if (!data.intentsUnhandledAllComparison) return <p>No Data found</p>;

  const months = createMonthsWithData(data)
  const options = {
    responsive: true,
    tooltips: {
      mode: 'label'
    },
    elements: {
      line: {
        fill: false
      }
    },
    scales: {
      xAxes: [
        {
          display: true,
          gridLines: {
            display: false
          },
          labels: months.map(m=> m.month),
        }
      ],
      yAxes: [
        {
          type: 'linear',
          display: true,
          position: 'left',
          id: 'y-axis-1',
          gridLines: {
            display: false
          },
          labels: {
            show: true
          }
        },
        {
          type: 'linear',
          display: true,
          position: 'right',
          id: 'y-axis-2',
          gridLines: {
            display: true
          },
          ticks : {
            max : 100,
            min : 0
          },
          labels: {
            show: true
          }
        }
      ]
    }
  };



  const graphData = {
    datasets: [{
      label: '% of all incoming messages',
      type:'line',
      data: months.map(m=> {
        if(!m.data.intentFallback || m.data.intentFallback === 0 || !m.data.intentFallback === 0 || m.data.intent === 0) return 0;
       return Math.round(m.data.intentFallback / (m.data.intentFallback + m.data.intent) * 100);
      }),
      fill: false,
      borderColor: DARK_BLUE,
      backgroundColor: DARK_BLUE,
      pointBorderColor: DARK_BLUE,
      pointBackgroundColor: DARK_BLUE,
      pointHoverBackgroundColor: BLUE,
      pointHoverBorderColor: BLUE,
      yAxisID: 'y-axis-2',
    },{
      type: 'bar',
      label: 'Number of messages matching the unhandled intent',
      data: months.map(m=> m.data.intentFallback),
      fill: false,
      backgroundColor: VIOLET,
      borderColor: PINK,
      hoverBackgroundColor: PINK,
      hoverBorderColor: VIOLET,
      yAxisID: 'y-axis-1'
    }]
  };


  return (
    <Grid container justify={'center'} spacing={2}>
      <Grid item xs={12}>
        {
          <Bar
            data={graphData}
            width={100}
            height={50}
            options={options}
            //plugins={plugins}
          />
        }
      </Grid>
    </Grid>

  );
}
export default UnhandledIntentsComparisonGraph
