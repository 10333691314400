import React, { useEffect } from 'react'
import { useAuth0 } from "./utils/react-auth0-spa";
import "./App.css";
import Routes from "./components/routes/Routes";
import LoadingOverlay from "./components/Loading/LoadingOverlay";
import {
  useApolloClient,
  useLazyQuery,gql
} from '@apollo/client';


import Snackbar from "./components/Snackbar";
import { GET_CHATBOT, GET_COMPANY, GET_CURRENT_USER } from './layout/queries'
import Footer from './components/Footer'

function App() {
  const client = useApolloClient();
  const { loading, user } = useAuth0();
  const [loadCompany, { loading: companyLoading, error: companyError, data:companyData }] = useLazyQuery(
    GET_COMPANY,{
    onCompleted(data){
      client.writeQuery({
        query : gql`
        query {
           currentCompanyApiKey
           currentChatbotLimit
         }`,
        data: {
          currentCompanyApiKey: data.company.apiKey,
          currentChatbotLimit: data.company.chatbotLimit
        },
      });
    }}
  );

  const [loadChatbot, { loading: chatbotLoading, error: chatbotError, data:chatbotData }] = useLazyQuery(
    GET_CHATBOT,{
      onCompleted(data){
        client.writeQuery({
          query : gql`
        query {
           currentChatbot{
            name
            platform
            agentKey
           }
         }`,
          data: {
            currentChatbot: {
              name: data.chatbot.name,
              platform: data.chatbot.platform,
              agentKey: data.chatbot.agentKey,
              __typename: 'Chatbot'
            }
          }
        });
      }
    });
  if (!loading && user) {
    const meta_app = user['https://thebotforge.eu.auth0.com.app_metadata'];
    const meta_user = user['https://thebotforge.eu.auth0.com.user_metadata'];
    const agentKey = (meta_user && meta_user['agentKey']) ? meta_user['agentKey'] : null;
    //load all the user params into the Apollo storage
    client.writeQuery({
      query : gql`
         query {
          currentChatbot
          currentAgentKey
          currentCompanyApiKey
          currentUserId
          currentCompanyId
          currentUserName
          currentUserNickname
          currentUserEmail
          currentUserPicture
          currentUserGateway
          currentUserDashboard
          currentUserVersion
          currentCompanyName
          currentChatbotLimit
          showSnackBar
          snackBarMessage
          severity
        }
      `,
      data: {
        currentAgentKey: agentKey,
        currentCompanyApiKey: user.apiKey ? user.apiKey : null,
        currentUserId: user.sub,
        currentCompanyId: meta_app ? meta_app['company'] : null,
        currentUserName: user.name,
        currentUserNickname: user.nickname,
        currentUserEmail: user.email,
        currentUserPicture: user.picture,
        currentUserGateway: meta_app ? meta_app['gateway'] : false,
        currentUserDashboard: meta_app ? meta_app['dashboard'] : false,
        currentUserVersion: meta_app ? meta_app['version'] : false,
        currentCompanyName: meta_app ? meta_app['companyName'] : false,
        currentChatbotLimit: null,
        showSnackBar: false,
        snackBarMessage: '',
        severity: 'success'
      }
    });
      //load loadChatbot data
      if (agentKey && !chatbotLoading && !chatbotData && !chatbotError) {
        loadChatbot({
          variables: {
            id: agentKey
          }
        })
      }

    //load company data
    if(!companyData && !companyLoading && !companyError){
      loadCompany(    {variables:{
          id:  meta_app['company']
      }})
    }

  }
  return (
    <div className="App">
      <Snackbar/>
      {loading ? (
        <LoadingOverlay active={loading}></LoadingOverlay>
      ) : (
        <React.Fragment>
          <Routes />
          <footer>
            <Footer/>
          </footer>
        </React.Fragment>

      )}


    </div>
  );
}

export default App;
