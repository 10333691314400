import React from 'react';
import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles'
const useStyles = makeStyles(theme => ({
  copyright: {
    color: theme.palette.common.white,
    bottom: 0,
    left: theme.spacing(1),
  }
}));
const Copyright = ()=>{
  const classes = useStyles();
    return (
      <Typography className={classes.copyright} variant="body2" color="textSecondary" align="center">
        {'Copyright © '}
        <Link className={classes.copyright} href="https://www.thebotforge.io">
          The Bot Forge Ltd
        </Link>{' '}
        {new Date().getFullYear()}
        {'.'}
      </Typography>
    );
}

export default Copyright;
