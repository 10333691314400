import React from 'react'
import {
  Redirect,
} from "react-router-dom";
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import {
  useQuery
} from '@apollo/client';
import Container from '@material-ui/core/Container';
import DashboardWidgetActivity from '../../components/dashboard/Widgets/DashboardWidgetInteractionActivity'
import DashboardWidgetUsrActivity from '../../components/dashboard/Widgets/DashboardWidgerUsrActivity'
import DashboardWidgetLiveMessageRate from '../../components/dashboard/Widgets/DashboardWidgetLiveInteractionRate'
import DashboardWidgetRecentTranscripts from '../../components/dashboard/Widgets/DashboardWidgetRecentTranscripts'
import PageHeader from '../../components/Header/PageHeader'
import { useAuth0 } from '../../utils/react-auth0-spa'
import Loading from '../../components/Loading'

import FromToDateTimePicker from '../../components/Picker/FromToDateTimePicker'
import DateSpanPicker from '../../components/dashboard/DateSpanPicker'
import moment from 'moment';
import { TODAY, YESTERDAY, LAST_WEEK, LAST_3_MONTH, LAST_MONTH } from '../../constants/CUSTOM_DATE_RANGE'
import CurrentChatbot from '../../components/CurrentChatbot'
import { GET_CURRENT_USER } from '../../components/dashboard/queries'
const drawerWidth = 240;
const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
  },
  toolbar: {
    paddingRight: 24, // keep right padding when drawer closed
  },
  toolbarIcon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 8px',
    ...theme.mixins.toolbar,
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  menuButtonHidden: {
    display: 'none',
  },
  title: {
    flexGrow: 1,
  },
  drawerPaper: {
    position: 'relative',
    whiteSpace: 'nowrap',
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerPaperClose: {
    overflowX: 'hidden',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing(7),
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9),
    },
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: '100vh',
    overflow: 'auto',
  },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
  fixedHeight: {
    height: 240,
  },
  dashboard: {
    width: '100%'
  }
}));

export default function Dashboard (date, formatString) {
  const classes = useStyles();

  const { data:currentUser, loading:currentUserLoading, error:currentUserError } = useQuery(GET_CURRENT_USER);
  const { isAuthenticated, loginWithRedirect, logout, user } = useAuth0();
  const accessDashboard = user ? user["https://thebotforge.eu.auth0.com.app_metadata"]['dashboard'] : false
  const now = moment();
  const [startDate, setStartDate] = React.useState(moment().subtract(7, 'days'));
  const [endDate, setEndDate] = React.useState(moment());
  const [customRange, setCustomRange] = React.useState(LAST_WEEK);
  const [customTitle, setCustomTitle] = React.useState(LAST_WEEK);
  const [manualDate, setManualDate] = React.useState(false);
  const rangeSetter = (customRange)=> {
    switch (customRange) {
      case TODAY:
        setStartDate(moment().startOf('day'))
        setEndDate(moment())
        setCustomTitle('Today')
        break;
      case YESTERDAY :
        setStartDate(moment().subtract(1, 'days'))
        setEndDate(moment().subtract(1, 'days'))
        setCustomTitle('Yesterday')
        break;
      case LAST_WEEK :
        setStartDate(moment().subtract(1, 'week'))
        setEndDate(moment())
        setCustomTitle('Last Week')
        break;
      case LAST_MONTH :
        setStartDate(moment().subtract(1, 'month'))
        setEndDate(moment())
        setCustomTitle('Last Month')
        break;
      case LAST_3_MONTH :
        setStartDate(moment().subtract(3, 'month'))
        setEndDate(moment())
        setCustomTitle('Last 3 Months')
        break;
      default:
        console.log(`Sorry, we are out of ${customRange}.`);
    }
  }
  const dashBoardBuilder = (dash) =>{
    return( <Grid item md={6} className={classes.dashboard} >
      {dash}
    </Grid>)
  }
  React.useEffect(() => {
    rangeSetter(customRange)
  }, [customRange]);

  React.useEffect(() => {
    setCustomTitle(`${startDate.format("MMM DD, YYYY")} to ${endDate.format("MMM DD, YYYY")}`)
  }, [manualDate]);

  if(!user || !currentUser){
    return <Loading/>
  }

  if(!accessDashboard){
    return  <Redirect
      to={{
        pathname: "/gatewayservices",
      }}
    />
  }


  return (
        <Container maxWidth="xl">

          <Grid container spacing={1}  alignItems="center" justify="flex-end">
          <Grid item>
          <DateSpanPicker
            endDate={endDate}
            setEndDate={setEndDate}
            startDate={startDate}
            setStartDate={setStartDate}
            customRange={customRange}
            setCustomRange={setCustomRange}
            setManualDate={setManualDate}
            manualDate={manualDate}/>
            </Grid>
            <Grid item md={3}>
              {<CurrentChatbot/>}
            </Grid>

            {
              dashBoardBuilder(<DashboardWidgetActivity customTitle={customTitle} startDate={startDate} endDate={endDate} currentUser={currentUser}/>)
            }
            {
              dashBoardBuilder(<DashboardWidgetUsrActivity customTitle={customTitle} startDate={startDate} endDate={endDate} currentUser={currentUser}/>)
            }
            {
              dashBoardBuilder(<DashboardWidgetLiveMessageRate customTitle={customTitle} startDate={startDate} endDate={endDate} currentUser={currentUser}/>)
            }
            {
              dashBoardBuilder(<DashboardWidgetRecentTranscripts customTitle={customTitle} startDate={startDate} endDate={endDate} currentUser={currentUser}/>)
            }
          </Grid>

        </Container>
  );
}

