import DashboardWidget from '../DashboardWidget'
import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Badge from '@material-ui/core/Badge/Badge'
import ChatIcon from '@material-ui/icons/Chat';
import DashboardWidgetLiveInteractionActivityGraph from '../DashboardWidgetLiveInteractionActivityGraph'
import TimelineIcon from '@material-ui/icons/Timeline';
const useStyles = makeStyles({
});
const defaultProps = {
  color: 'secondary',
  children: <TimelineIcon />,
};
const DashboardWidgetLiveMessageRate = ({currentUser}) =>{

  return (
    <DashboardWidget
      title={'Live Interaction'}
      to="/dashboard/reports/"
      badge={<Badge badgeContent={'LIVE'} {...defaultProps} />}>
      <DashboardWidgetLiveInteractionActivityGraph currentUser={currentUser}/>
    </DashboardWidget>
  )
}

export default DashboardWidgetLiveMessageRate;
