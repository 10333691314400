import {
  ApolloProvider,
  ApolloClient,
  HttpLink,
  ApolloLink,
  createHttpLink,
  InMemoryCache,
  useQuery,
  gql
} from '@apollo/client'
import { onError } from "@apollo/client/link/error";
import { setContext } from 'apollo-link-context';
import React from 'react';

import { useAuth0 } from './../../utils/react-auth0-spa';
import { getLocal, getStaging } from '../../utils/urls'
//TODO get this working
//https://stackoverflow.com/questions/48863441/apollo-client-how-to-simply-debug-a-400-code-error
// const errorLink = onError(({ graphQLErrors }) => {
//   if (graphQLErrors) graphQLErrors.map(({ message }) => console.log(message))
// })

const graphql = () =>{
  return getLocal(window.location.hostname) ? "http://localhost:4000/graphql" :
    getStaging(window.location.hostname) ? "https://chatseer-staging.herokuapp.com" : "https://app.chatseer.ai/graphql"
}

const AuthorizedApolloProvider = ({ children }) => {
  const { getTokenSilently } = useAuth0();
  const httpLink = createHttpLink({
    uri:  graphql()
  });


  const link = onError(({ graphQLErrors, networkError }) => {
    if (graphQLErrors)
      graphQLErrors.forEach(({ message, locations, path }) =>
        console.log(
          `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`,
        ),
      );

    if (networkError) console.log(`[Network error]: ${networkError}`);
  });
  // const authLink = setContext(async () => {
  //   const token = await getTokenSilently();
  //   return {
  //     headers: {
  //       Authorization: `Bearer ${token}`
  //     }
  //   };
  // });
  const authLink = setContext(async(_, { headers }) => {
    const token = await getTokenSilently();
    // return the headers to the context so httpLink can read them
    return {
      headers: {
        ...headers,
        Authorization: token ? `Bearer ${token}` : "",
      }
    }
  });



  const apolloClient = new ApolloClient({
    link: authLink.concat(httpLink),
    cache: new InMemoryCache(),
    connectToDevTools: true
  });
  return (
    <ApolloProvider client={apolloClient}>
      {children}
    </ApolloProvider>
  );
};

export default AuthorizedApolloProvider;
