import { getLocal } from './../../../utils/urls'
const api = getLocal(window.location.hostname) ? 'http://localhost:5000/api' : 'https://cgs.thebotforge.io/api';
export const code_curlAuthRequest = `curl --request POST \\
  --url ${api}/auth/token \\
  --header 'content-type: application/json' \\
  --data '{"client_id":"81MB0KikFNIF6A2sn25uhGznwCkKefD2","client_secret":"Jgbo_zKVxlNevt4Lnw9eduz8VclG9mKJJAfca-HwWOcOjB8j4N1navDga8AKJstG"}'`

export const code_curlAuthResponse = `{"access_token\": \"eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCIsImtpZCI6IlJUVkVNMEkyUXpNNFFqQkVRVVpDTWtZNVFqTTJRekpFTWpRME5VRkVSa015TVVNNE0wRTRPUSJ9.eyJpc3MiOiJodHRwczovL3RoZWJvdGZvcmdlLmV1LmF1dGgwLmNvbS8iLCJzdWIiOiI4MU1CMEtpa0ZOSUY2QTJzbjI1dWhHem53Q2tLZWZEMkBjbGllbnRzIiwiYXVkIjoiaHR0cHM6Ly9jaGF0Ym90LWdhdGV3YXktc2VydmljZS50aGVib3Rmb3JnZS5pbyIsImlhdCI6MTU4ODI4NTQxNSwiZXhwIjoxNTg4MzcxODE1LCJhenAiOiI4MU1CMEtpa0ZOSUY2QTJzbjI1dWhHem53Q2tLZWZEMiIsInNjb3BlIjoicG9zdDptZXNzYWdlcyIsImd0eSI6ImNsaWVudC1jcmVkZW50aWFscyJ9.L5028BRIdBvqKjWXd-qsnfHet0yQYcUKSQi5cRLhdOkMBf9i3R83wiESj6JlNLe5cCMxCUkSbU56WSQ9ruu9_5ASMzh8ysIcBVbAM-iRmDFuuvXJFP5fOg-XwhXAuDvJiuRjoj1mLvVX-aLLX-rvaSUE0slLStVCJ8Q1LV80wWCgJ3aeTN-45QZJGL9PA7JF2rmBCzkl4z6ZAT08O4ISIA-yUxHki-neczHgFKNf3SWZpyjAGIn-FimGoYBZ2QUJHFunT_EAJIiNnHlW9Ju1qEzqxrwrUZ_gk7COlW6hoeRgCKhxLWWPQSj5cSpjfK9NtENuJSsZiwOcPAZoQzSsuQ\",
  "token_type\": \"Bearer"}`
export const code_curlUseToken = `curl --request GET \\
  --url http://path_to_your_api/ \\
  --header 'authorization: Bearer YOUR_TOKEN_GOES_HERE'`

export const code_curlPostMessageRequest = `curl --location --request POST '${api}/df_project' \\
--header 'authorization: Bearer YOUR_TOKEN_GOES_HERE' \\
--header 'Content-Type: application/json' \\
--data-raw '{
"query": "hello",
"session_id": "a3d77065-fdfc-4a0e-bcbe-566e02033a8a"
}'`;
export const code_curlPostMessageResponse = "{\"id\":\"61baf945-9df9-4b0a-bf4f-e35e85b80791-5fd6c646\",\"action\":\"\",\"query\":\"can you lookup my account details\n" +
  "it?\",\"params\":{\"fields\":{\"covid-19\":{\"stringValue\":\"\",\"kind\":\"stringValue\"}}},\"diagnosticInfo\":null,\"components\":[{\"name\":\"DEFAULT\",\"content\":\"Yes of course, hold on I will look for you" +
  "\"}],\"intent\":\"projects/covid-19-chatbot-qjbkay/agent/intents/97ef32e4-d411-4842-9ac9-42fd75962bea\",\"intentDetectionConfidence\":1,\"sentimentAnalysisResult\":null}"

