import { gql } from '@apollo/client'

export const GET_INTENTS_ALL = gql`
  query GET_INTENTS_ALL(
    $pagination: PaginationInput
    $filter: FilterIntentInput
    $startDate: String
    $endDate: String
    $agentKey: String
  ) {
    intentsAllStats(
      pagination: $pagination
      filter: $filter
      startDate: $startDate
      endDate: $endDate
      agentKey: $agentKey
    ) {
      intentsTotal
      intents {
        messages{
          createdAt
          message
          count
          sessionId
          intentDetectionConfidence
        }
        intent
      }
    }
  }
`;
export const GET_CURRENT_USER = gql`
  {
    currentAgentKey @client
    currentUserName @client
    currentUserVersion @client
    currentUserEmail @client
    currentUserNickname @client
    currentCompanyName @client
    currentCompanyId @client
    currentCompanyApiKey @client
    currentChatbotLimit @client
  }
`;
