import {
  gql
} from '@apollo/client';


export const GET_CHATBOTS = gql`
  query GET_CHATBOTS {
    chatbotsAll {
      name
      active
      createdAt
      platform
      timezone
      agentKey
      dfProjectId
      _id
    }
  }
`;

export const GET_CURRENT_USER = gql`
  {    
    currentAgentKey @client
    currentUserName @client
    currentUserId @client
    currentUserVersion @client
    currentUserEmail @client
    currentUserNickname @client
    currentCompanyName @client
    currentCompanyId @client
    currentCompanyApiKey @client
    currentChatbotLimit @client

  }
`;



