import React from "react";
import Typography from "@material-ui/core/Typography";
import { withStyles } from "@material-ui/core";
const WhiteTextTypography = withStyles({
  root: {
    color: "#FFFFFF"
  }
})(Typography);





function ChatbotDetails({ currentChatbot: { name, platform } }) {
  return (
    <React.Fragment>
      <WhiteTextTypography variant="subtitle1" gutterbottom>
        Loaded chatbot:
      </WhiteTextTypography>
      <WhiteTextTypography variant="subtitle2" gutterbottom>
        {name}
      </WhiteTextTypography>

      <WhiteTextTypography variant="subtitle1" gutterbottom>
        Platform:
      </WhiteTextTypography>
      <WhiteTextTypography variant="subtitle2" gutterbottom>
        {platform}
      </WhiteTextTypography>
    </React.Fragment>
  );
}

export default ChatbotDetails;
