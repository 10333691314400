import React, { useState } from 'react'
import {
  useQuery,gql
} from '@apollo/client';
import { HorizontalBar } from 'react-chartjs-2'

import Loading from '../../../../Loading'
import Typography from '@material-ui/core/Typography'

import { GET_INTENTS_ALL } from './queries'
import moment from 'moment'
import { GET_CURRENT_USER } from '../IntentsDetails/queries'

const TopIntentsGraph = ()=>{
  const [startDate, setStartDate] = useState(moment().subtract("7", "days"));
  const [endDate, setEndDate] = useState(new Date(Date.now()));
  const {loading:loadingUser, data:dataUser, error:errorUser } = useQuery(GET_CURRENT_USER)

  const { data, loading, error, refetch:refetchALL, networkStatus } = useQuery(
    GET_INTENTS_ALL,
    {
      notifyOnNetworkStatusChange: true,
      variables: {
        startDate: startDate,
        endDate: endDate,
        agentKey: dataUser.currentAgentKey
      }
    }
  );

  if (loading) return <Loading />;
  if (error) return  <Loading />;
  const graphLabels = data.intentsAll ? data.intentsAll.map(r=>r.displayName) : []
  const graphLabelsData = data.intentsAll ? data.intentsAll.map(r=>r.count) : []

  const graphData = {
    labels: graphLabels,
    datasets: [
      {
        label: 'Messages',
        borderWidth: 1,
        backgroundColor: '#02749e',
        borderColor: '#04a7e3',
        hoverBackgroundColor: '#04a7e3',
        hoverBorderColor: '#04a7e3',
        data: graphLabelsData
      }
    ]
  };
  return(<React.Fragment>
      <Typography variant="body1" component="p">
        Top matched Intents
      </Typography>
      <HorizontalBar data={graphData} />
    </React.Fragment>

  )
}

export default TopIntentsGraph
