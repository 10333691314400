import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'

import React, { Fragment } from 'react'

import InputLabel from '@material-ui/core/InputLabel'

const NotificationFrequency = ({active=true, frequency, setFrequency}) =>{
  return (<Fragment>
    <InputLabel id="notification-frequency-label">Report frequency</InputLabel>
    <Select
      disabled={!active}
      labelId="notification-frequency-label"
      id="notification-frequency"
      value={frequency}
      name={"frequency"}
      onChange={event=> setFrequency(event.target.value)}
    >
      <MenuItem value={"DAILY"}>Daily</MenuItem>
      <MenuItem value={"EVERY_OTHER_DAY"}>Every Other Day</MenuItem>
      <MenuItem value={"WEEKLY"}>Weekly</MenuItem>
      <MenuItem value={"MONTHLY"}>Monthly</MenuItem>
    </Select>
    </Fragment>)
}

export default NotificationFrequency
