import DashboardWidget from '../DashboardWidget'
import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import DashboardWidgetCounterHolder from '../../Widgets/DashboardWidgetCounterHolder'
import DashboardWidgetCounter from '../../Widgets/DashboardWidgetCounterNumber'
import DashboardWidgetCounterGraph from '../../Widgets/DashboardWidgetCounterGraph'
import {
  useQuery
} from '@apollo/client';
import { GET_CHATBOT_USERS } from './queries'
import Loading from '../../../Loading'
import moment from 'moment'
import { RELOAD_PERIOD } from '../../../../utils/settings'


const DashboardWidgetUsrActivity = ({startDate,endDate,customTitle,currentUser}) =>{
  const { data, loading, error } = useQuery(GET_CHATBOT_USERS, {
    pollInterval: RELOAD_PERIOD * 10,
    variables: {
      startDate: startDate,
      endDate: endDate,
      agentKey: currentUser ? currentUser.currentAgentKey :  null
    }
  });
  if (loading) return <Loading />;
  if (error) return <Loading />;

  const showSmall = () => {
    return (
      data.chatbotUsersPeriod.new.toString().length > 3 ||
      data.chatbotUsersPeriod.returning.toString().length > 3 ||
      data.chatbotUsersPeriod.userSessions.toString().length > 3
    );
  };

  const chatTotal = (data && data.chatbotUsersPeriod) ? data.chatbotUsersPeriod.new + data.chatbotUsersPeriod.returning : 0;
  return (
    <DashboardWidget
      title={'User Activity'}
      to="/dashboard/reports/"
      small={true}
      label=""
      content
      subtitle={customTitle}>
      {
        ((!loading ) && !error) ? (<Grid
       container direction="row" justify="center" alignItems="center" spacing={2}>
        <Grid item>
          <DashboardWidgetCounterHolder
            title={"New"}
            counter={<DashboardWidgetCounter value={(data && data.chatbotUsersPeriod) ? data.chatbotUsersPeriod.new : 0} showSmall={showSmall()}/>}
            graph={<DashboardWidgetCounterGraph value={(data && data.chatbotUsersPeriod) ? data.chatbotUsersPeriod.new : 0} total={chatTotal}/>}
          />
        </Grid>
        <Grid item>
          <DashboardWidgetCounterHolder
            title={'Returning'}
            counter={<DashboardWidgetCounter value={(data && data.chatbotUsersPeriod) ? data.chatbotUsersPeriod.returning : 0} showSmall={showSmall()}/>}
            graph={<DashboardWidgetCounterGraph value={(data && data.chatbotUsersPeriod) ? data.chatbotUsersPeriod.returning : 0} total={chatTotal}/>}
          />
        </Grid>
        <Grid item>
          <DashboardWidgetCounterHolder
            title={"Sessions"}
            counter={<DashboardWidgetCounter value={(data && data.chatbotUsersPeriod) ? data.chatbotUsersPeriod.userSessions : 0}  showSmall={showSmall()}/>}
            graph={null}
          />
        </Grid>
      </Grid>) : (<Loading />)
      }
    </DashboardWidget>
  )
}

export default DashboardWidgetUsrActivity;
