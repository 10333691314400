import {
  gql
} from '@apollo/client';


export const GET_CHATBOT_USERS = gql`
  query GET_CHATBOT_USERS($startDate: String,$endDate: String,$agentKey: String) {
    chatbotUsersPeriod(startDate: $startDate, endDate: $endDate, agentKey: $agentKey) {
        new
        returning
        lifetime
        userSessions
    }
  }
`;
