import { gql } from '@apollo/client'

export const GET_INTERACTIONS_OVER_TIMESPAN = gql`
    query GET_INTERACTIONS_OVER_TIMESPAN($startDate: String, $agentKey: String){ 
        interactionsAllTimespan(startDate: $startDate, agentKey: $agentKey) {
          start,
          finish,
          segments{
            count
            date
        }
      }
    }
`;



