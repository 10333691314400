import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
});

function createData(code, text, purpose) {
  return { code, text, purpose };
}

const rows = [

  createData('200','OK', 'For successful GET and PUT requests.'),
  createData('201','OK', 'For a successful POST request.'),
  createData('400', 'Bad Request', 'Issued when a malformed request was sent'),
  createData('401', 'Unauthorized', 'This response is sent when your client failed to provide credentials or its credentials were invalid. Try refreshing your key'),
  createData('500', 'Internal Server Error', 'When an error has occurred within the API.'),
];
const ApiStatusCodes = ()=>{
  const classes = useStyles();
  return (
    <TableContainer component={Paper}>
      <Table className={classes.table} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>Code</TableCell>
            <TableCell>Text</TableCell>
            <TableCell>Purpose</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map(({code,text,purpose}) => (
            <TableRow key={code}>
              <TableCell component="th" scope="row">
                {code}
              </TableCell>
              <TableCell>{text}</TableCell>
              <TableCell>{purpose}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

export default ApiStatusCodes;
