import React from 'react';
import IconButton from '@material-ui/core/IconButton';
import EmojiEmotionsIcon from '@material-ui/icons/EmojiEmotions';
import { createMuiTheme, withStyles, makeStyles, ThemeProvider } from '@material-ui/core/styles';
const useStyles = makeStyles(theme => ({
  happy: {
    color: "green"
  }
}));
const Status = ()=>{
  const classes = useStyles();
  return <IconButton size={'small'} target={'_blank'} href={'https://status.chatseer.com'} color="secondary" aria-label="add to shopping cart">
    Status <EmojiEmotionsIcon className={classes.happy} />
  </IconButton>
}

export default Status
