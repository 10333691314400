import React, { useState } from "react";
import MaterialTable  from 'material-table';
import ChatIcon from "@material-ui/icons/ChatBubble";
import Chat from "@material-ui/icons/Chat";
import Assistant from '@material-ui/icons/Assistant'
import Grid from '@material-ui/core/Grid';
import FirstPageIcon from "@material-ui/icons/FirstPage";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import moment from "moment";
import { duration } from "./../../../utils/time";
import { removeHTML } from "./../../../utils/cleaner";
import Badge from "@material-ui/core/Badge";
import ListItemText from "@material-ui/core/ListItemText";
import PersonIcon from "@material-ui/icons/Person";
import ArrowUpIcon from "@material-ui/icons/ArrowUpward";
import LastPageIcon from "@material-ui/icons/LastPage";
import NextPageIcon from "@material-ui/icons/NavigateNext";
import PreviousPageIcon from "@material-ui/icons/NavigateBefore";
import RefreshIcon from "@material-ui/icons/Refresh";
import SearchIcon from "@material-ui/icons/Search";
import ExportIcon from "@material-ui/icons/SaveAlt";
import ClearIcon from "@material-ui/icons/Clear";
import { makeStyles } from "@material-ui/core/styles";
import Sentiment from "../../Sentiment";

import {
  useQuery
} from '@apollo/client';

import Typography from '@material-ui/core/Typography';
import MaterialTableToolbar from './../../MaterialTableToolbar/index'
import { GET_CONVERSATIONS, GET_CURRENT_USER } from './queries'
const useStyles = makeStyles(theme => ({
  interactionAgent: {
    backgroundColor: theme.palette.grey[100],
    padding: theme.spacing(2),
    paddingLeft: theme.spacing(4)
  },
  interactionUser: {
    backgroundColor: theme.palette.grey[300],
    padding: theme.spacing(2),
    paddingLeft: theme.spacing(4)
  },
  transcriptDetailPanel: {
    paddingLeft: theme.spacing(2),
    backgroundColor: theme.palette.primary.light
  },
  root: {
    padding: 0,
    margin: 0
  },
  user: {
    color: theme.palette.success.main
  },
  anchorOriginBottomLeftCircle: {
    bottom: 30
  },
  toolbar: {
    fontSize: theme.typography.h6.fontSize,
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    padding: 20
  },
  messageIcon: {
    marginLeft: 10,
    marginRight: 10
  },
  sentimentWrapper: {
    paddingRight: 13
  },
  userBadge: {
    backgroundColor: theme.palette.userColor.main,
    color: "white",
    fontSize: "0.6rem",
    opacity: 0.9
  },
  chatbotBadge: {
    backgroundColor: theme.palette.chatbotColor.main,
    color: "white",
    fontSize: "0.6rem",
    opacity: 0.9
  },
  date: {
    paddingTop: theme.spacing(1)
  },
  messages: {
    backgroundColor: 'white',
    borderRadius: theme.spacing(2),
    padding: theme.spacing(1)
  }

}));



function messagesCount(data, sender) {
  return data.interactions.filter(d => d.sender === sender).length;
}

function generate(data, classes) {
  return data.map(({ sender, message, sentiment, id, responseTimestamp }) => (
    <ListItem
      key={id}
      className={
        sender === "OUT" ? classes.interactionAgent : classes.interactionUser
      }
    >
      <ListItemText
        disableTypography={true}
        primary={
          <React.Fragment>
            <Grid container spacing={1} justify="space-between">
              <Grid>
                 <Badge
                    classes={{ badge: sender !== "OUT"? classes.userBadge : classes.chatbotBadge }}
                    badgeContent={sender !== "OUT" ? "User" : "Agent"}
                    anchorOrigin={{
                      vertical: "top",
                      horizontal: "left"
                    }}
                  >
                    <Typography variant="caption" className={classes.date} component={'span'} >{new Date(responseTimestamp).toUTCString()}</Typography>
                  </Badge>
              </Grid>
              <Grid>
                {sender === "IN" && (
                  <div className={classes.sentimentWrapper}>
                    <Sentiment sentiment={sentiment ? sentiment.magnitude : 0} />
                  </div>
                )}
              </Grid>
              <Grid item xs={12}>
                <Typography variant="body2" component={'p'} className={classes.messages} >{removeHTML(message)}{" "}</Typography>
              </Grid>
            </Grid>
          </React.Fragment>
        }
      />
    </ListItem>
  ));
}

const TranscriptGrid = ({ columns }) => {
  const defaultPageSize = 5;
  const classes = useStyles();
  const [q, setQ] = React.useState(undefined);
  const [startDate, setStartDate] = useState(moment().subtract("7", "days"));
  const [endDate, setEndDate] = useState(new Date(Date.now()));
  const [currentPage, setCurrentPage] = React.useState(0);
  const [currentPageSize, setCurrentPageSize] = React.useState(defaultPageSize);
  const {loading:loadingUser, data:dataUser, error:errorUser } = useQuery(GET_CURRENT_USER);
  const [currentPagination, setCurrentPagination] = React.useState({
    page: currentPage,
    pageSize: currentPageSize
  });
  const [currentFilter, setCurrentFilter] = React.useState({
    query: q
  });


  React.useEffect(() => {
    if (currentPage !== undefined && currentPageSize !== undefined) {
      setCurrentPagination({
        page: currentPage,
        pageSize: currentPageSize
      });
    } else {
      setCurrentPagination(undefined);
    }
  }, [currentPage, currentPageSize, setCurrentPagination]);
  React.useEffect(() => {
    if (
      q !== undefined &&
      q !== ""
    ) {
      setCurrentFilter({
        query: q
      });
    } else {
      setCurrentFilter(undefined);
    }
  }, [q, setCurrentFilter]);

  const { data, loading, error, refetch, networkStatus } = useQuery(
    GET_CONVERSATIONS,
    {
      notifyOnNetworkStatusChange: true,
      variables: {
        startDate: startDate,
        endDate: endDate,
        pagination: {
          limit: currentPageSize,
          offset: currentPageSize * currentPage
        },
        filter: currentFilter,
        agentKey: dataUser.currentAgentKey
      }
    }
  );
  const parseDataForCSVExport = (data) => {
    let cleaned = []
    data.forEach(con => {
        con.interactions.forEach(msg=> {
          cleaned.push({session:con.sessionId,sender: msg.sender === 'IN' ? 'user' : 'chatbot', dataTime: msg.responseTimestamp, message: msg.message,fallback: msg.isFallback ? "x" : "",sentiment: (msg.sentiment) ? msg.sentiment.score : ""})
        });
      }
    )
    return cleaned;
  }
  return (
    <MaterialTable
      columns={columns}
      data={data ? data.conversationsAll ? data.conversationsAll.conversations.map(o => ({ ...o })) : [] : []}
      totalCount={(data && data.conversationsAll) ? data.conversationsAll.conversationsCount : 0}
      isLoading={loading || networkStatus === 4}
      icons={{
        FirstPage: FirstPageIcon,
        LastPage: LastPageIcon,
        NextPage: NextPageIcon,
        PreviousPage: PreviousPageIcon,
        SortArrow: ArrowUpIcon,
        Search: SearchIcon,
        ResetSearch: ClearIcon,
        Export: ExportIcon
      }}
      components={{
        Toolbar: props => ( <MaterialTableToolbar
            refetch={refetch}
            startDate={startDate}
            setStartDate={setStartDate}
            endDate={endDate}
            csvExportFile={'transcripts.csv'}
            setQ={setQ}
            currentFilter={ currentFilter }
            data={data ? data.conversationsAll ? data.conversationsAll.conversations : [] : []}
            csvParseData={parseDataForCSVExport}/>
        )
      }}
      options={{
        search: false,
        pageSize: defaultPageSize,
        emptyRowsWhenPaging: false,
        //exportButton: true,
        pageSizeOptions: [5, 10, 20]
      }}
      onSearchChanged={(event)=>{
        event.preventDefault();
        setQ(event.target.value)
      }}
      onChangePage={nextPage => {
        setCurrentPage(nextPage);
      }}
      page={currentPage }
      onChangeRowsPerPage={nextPerPage => {
        setCurrentPageSize(nextPerPage);
        setCurrentPage(0);
      }}
      detailPanel={[
        {
          tooltip: "Show Transcript",
          icon: () => <ChatIcon />,
          render: rowData => {
            return (
              <div className={classes.transcriptDetailPanel}>
                <List
                  classes={{
                    root: classes.root
                  }}
                  disablePadding={true}
                  dense={true}
                >
                  {generate(rowData.interactions, classes)}
                </List>
              </div>
            );
          }
        }
      ]}
      columns={[
        {
          title: "Session Start",
          field: "sessionStart",
          render: rowData => new Date(rowData.createdAt).toUTCString()
        },
        {
          title: "Session Latest",
          field: "message",
          render: rowData => new Date(rowData.updatedAt).toUTCString(),
          sorting: false
        },
        {
          title: "Duration",
          field: "duration",
          sorting: false,
          render: rowData => `${duration(rowData)}`
        },
        {
          title: "Messages In",
          field: "messagesIn",
          sorting: false,
          customSort: rowData => {
            //TODO need to figure out how this will work
            messagesCount(rowData, "IN");
          },
          render: rowData => messagesCount(rowData, "IN")
        },
        {
          title: "Messages Out",
          field: "messagesOut",
          sorting: false,
          customSort: rowData => messagesCount(rowData, "OUT"),
          render: rowData => messagesCount(rowData, "OUT")
        }
        // {
        //   field: 'sentiment',
        //   title: 'Sentiment',
        //   render: rowData => <Sentiment sentiment={(rowData.sentiment)? rowData.sentiment.score : ''}/>
        // },
      ]}
    />
  );
};
export default TranscriptGrid;
