import React, { Fragment } from "react";
import TextField from "@material-ui/core/TextField";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Grid from "@material-ui/core/Grid";
import InputLabel from '@material-ui/core/InputLabel';
const NotificationTarget = ({ notificationTarget, active=true, setNotificationTarget }) => {
  const handleChange = (event) =>{
    setNotificationTarget({...notificationTarget,[event.target.name]:event.target.value})
  }
  return (
    <Fragment>
      <Grid container spacing={3} justify="flex-start" alignItems="flex-end">
        <Grid item xs={3}>
          <InputLabel id="alert-target-label">Alert Target</InputLabel>
          <Select
            disabled={!active}
            name={"notificationTarget"}
            value={notificationTarget['notificationTarget']}
            name={"notificationTarget"}
            onChange={handleChange}
          >
            <MenuItem value={"SMS"}>SMS</MenuItem>
            <MenuItem value={"EMAIL"}>Email</MenuItem>
          </Select>
        </Grid>
        <Grid item xs={9}>
          <InputLabel id="demo-simple-select-helper-label">Alert Destination</InputLabel>
          <TextField
            id="standard-basic"
            fullWidth
            label={"For multiple values, please add a comma separated list."}
            size={"medium"}
            value={notificationTarget['notificationTargetValue']}
            name={"notificationTargetValue"}
            //onKeyUp={handleChange}
            onChange={handleChange}
          />
        </Grid>
      </Grid>
    </Fragment>
  );
};
export default NotificationTarget;
