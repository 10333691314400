import React from 'react'
import PageHeader from './PageHeader'
import RouterBreadcrumbs from '../RouterBreadcrumbs'
import { useLocation } from "react-router-dom";
import { makeStyles, useTheme } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery/useMediaQuery'
const useStyles = makeStyles(theme => ({
  header: {
    minHeight: '80px',
    margin: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
      margin: theme.spacing(1),
    },
    marginLeft: 0,
    marginRight: 0,
    marginTop: 0,
    display: 'flex',
    flexDirection: 'column',
    alignContent: 'space-between',
    width: '100%'
  },
  appBarSpacer: theme.mixins.toolbar,
}));
export default function Header(){
  const classes = useStyles();
  let location = useLocation();
  const theme = useTheme()
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'))
  const isMobile = !isDesktop
  let dashboard = location.pathname === '/dashboard' || location.pathname === '/dashboard/'
  if(!dashboard){
    return(
      <React.Fragment>
        <div className={classes.header}>
          <PageHeader/>
          <RouterBreadcrumbs/>
        </div>
      </React.Fragment>
    )
  }

  return null

}
