import React, { Fragment, useState } from 'react'
import Grid from '@material-ui/core/Grid'
import PageHeader from '../../../Header/PageHeader'
import NotificationsGrid from '../NotificationsGrid'
import Typography from '@material-ui/core/Typography'
import Paper from '@material-ui/core/Paper'
import { makeStyles } from '@material-ui/core/styles'
import NotificationsActionPanel from '../NotificationsActionPanel'
const useStyles = makeStyles(theme => ({
  content: {
    padding: theme.spacing(2)
  }
}));
const Alerts = () => {
  const classes = useStyles();
  const [openActionAdd, setOpenActionAdd] = React.useState(false);
  const [addMode, setAddMode] = useState(false);
  const handleClose = (value) => {
    setOpenActionAdd(false);
  };

  return (
    <Fragment>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Paper className={classes.content}>
            <Typography variant="body1" component="p">
                  Manage your alerts stay up to date with real time alerts via SMS or
                  Email including unhandled intents, Bad Sentiment, Intent use, contact requests and more.
            </Typography>
            <NotificationsActionPanel addMode={addMode} setAddMode={setAddMode} setOpenActionAdd={setOpenActionAdd}/>
            <NotificationsGrid addMode={addMode} setAddMode={setAddMode} setOpenActionAdd={setOpenActionAdd} handleClose={handleClose} openActionAdd={openActionAdd} notificationType={"ALERT"} />
          </Paper>
        </Grid>
      </Grid>

    </Fragment>

  )
}

export default Alerts;
