import React, { Fragment } from 'react'
import TextField from '@material-ui/core/TextField'
import FormControl from '@material-ui/core/FormControl'
import Grid from '@material-ui/core/Grid'
import InputLabel from '@material-ui/core/InputLabel/InputLabel'
import { makeStyles } from '@material-ui/core'
import CopyClipboard from '../../CopyCodeClipboard'
import IconButton from '@material-ui/core/IconButton';
import CopyIcon from '@material-ui/icons/FileCopy';
import { CopyToClipboard } from 'react-copy-to-clipboard'
import Tooltip from '@material-ui/core/Tooltip/Tooltip'
import Highlight from 'react-highlight.js'
import { gql } from '@apollo/client'
import { ChatbotName as locale } from '../../../assets/locale/en'
import BootstrapTooltip from '../../BootstrapTooltip'
const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  }
}));
const ChatbotName = ({name,setName,currentAgentKey,client})=>{
  const classes = useStyles();
  const copyCodeRef = React.createRef();
  const handleCopyCode = () => {
    client.writeQuery({
      query : gql`
        query {
           showSnackBar
           snackBarMessage
           severity
         }`,
      data: {
        showSnackBar: true,
        snackBarMessage: locale.CODE_COPIED_TO_CLIPBOARD,
        severity: 'success'
      }
    });
  };

  return(
    <Grid container spacing={2}>
      <Grid item xs={6}>
        <FormControl fullWidth className={classes.formControl}>
        <InputLabel id="name-select-label">Chatbot name</InputLabel>
        <TextField
          fullWidth
          size={"medium"}
          value={name}
          name={"chatbotName"}
          onChange={event => {
            setName(event.target.value);
          }}
        />
      </FormControl>
      </Grid>
      {
        currentAgentKey && <Grid item xs={6}>
          <FormControl fullWidth className={classes.formControl}>
            <InputLabel id="chatbot-key-label">Chatbot key</InputLabel>
            <Grid
              container
              direction="row"
              justify="flex-start"
              alignItems="center"
            >
            <Grid>
            <Highlight language={"JavaScript"} style={{width: '200px'}}>
              {currentAgentKey}
            </Highlight>
            </Grid>
            <Grid>
            <CopyToClipboard text={currentAgentKey}
                             onCopy={handleCopyCode}>
              <BootstrapTooltip title="Copy Key">
                <IconButton aria-label="copy" id={'copyButton'} color={'secondary'} style={{width: '80px'}}>
                  <CopyIcon fontSize="small" />
                </IconButton>
              </BootstrapTooltip>
            </CopyToClipboard>
            </Grid>
            </Grid>
          </FormControl>
        </Grid>
      }

    </Grid>

  )
}
export default ChatbotName;
