import React from 'react'
import PropTypes from "prop-types";
import { CopyToClipboard } from 'react-copy-to-clipboard'
import Button from '@material-ui/core/Button';
import Tooltip from '@material-ui/core/Tooltip';
import { makeStyles } from '@material-ui/core/styles'
import Highlight from 'react-highlight.js'
import IconButton from '@material-ui/core/IconButton';
import CopyIcon from '@material-ui/icons/FileCopy';
import BootstrapTooltip from '../BootstrapTooltip'
const useStyles = makeStyles(theme => ({

  disabled: {
    opacity: "0.65",
    pointerEvents: "none"
  },
  underline: {
    "&:hover:not($disabled):before,&:before": {
      borderColor: "#D2D2D2 !important",
      borderWidth: "1px !important"
    },
    "&:after": {
      borderColor: theme.palette.primary.dark
    }
  },
  codeInputArea: {
    position: 'relative',
    width: '100%',
  },
  code:{
    position: 'relative',
    "@media screen and (max-width: 599px)": {
      width: '350px',
      '& #copyButton':{
        position: 'relative',
        top: '-55px',
        right: '-80px'
      }
    },
    "@media screen and (min-width: 600px)": {
      width: '300px',
      '& #copyButton':{
        position: 'relative',
        top: '-55px',
        right: '-140px'
      }
    },
    "@media screen and (min-width: 900px)": {
      width: '400px',
      '& #copyButton':{
        position: 'relative',
        top: '-55px',
        right: '-180px'
      }
    },
    "@media screen and (min-width: 1200px)": {
      width: '500px',
      '& #copyButton':{
        position: 'relative',
        top: '-65px',
        right: '-240px'
      }
    },
  },
  tooltip: {
    backgroundColor: theme.palette.primary.dark,
  },
}))
const CopyCodeClipboard = (props)=>{
  const classes = useStyles();
  return (
    <div className={classes.code}>

      <Highlight language={"JavaScript"}>
        {props.value}
      </Highlight>
      <CopyToClipboard text={props.value}
                       onCopy={props.onCopyClick}>
        <BootstrapTooltip title="Copy Key">
        <IconButton aria-label="copy" id={'copyButton'} color={'secondary'}>
          <CopyIcon fontSize="small" />
        </IconButton>
        </BootstrapTooltip>
      </CopyToClipboard>
    </div>
  )
}
CopyCodeClipboard.propTypes = {
  onCopyClick: PropTypes.func,
  classes: PropTypes.object,
  value: PropTypes.string
}
export default CopyCodeClipboard;
