import React, { useState } from 'react'

import { makeStyles } from "@material-ui/core/styles";
import CreateIcon from '@material-ui/icons/Create';
import MaterialTable from 'material-table';

import {
  useQuery, gql
} from '@apollo/client';
import Loading from "../../../../Loading";
import FirstPageIcon from '@material-ui/core/SvgIcon/SvgIcon'

import ArrowUpIcon from "@material-ui/icons/ArrowUpward";
import LastPageIcon from "@material-ui/icons/LastPage";
import NextPageIcon from "@material-ui/icons/NavigateNext";
import PreviousPageIcon from "@material-ui/icons/NavigateBefore";

import SearchIcon from "@material-ui/icons/Search";
import ExportIcon from "@material-ui/icons/SaveAlt";
import ClearIcon from "@material-ui/icons/Clear";
import moment from 'moment'
import MaterialTableToolbar from '../../../../MaterialTableToolbar'
import Sentiment from '../../../../Sentiment'
import Grid from '@material-ui/core/Grid'
import PageHeader from '../../../../Header/PageHeader'
import { useAuth0 } from '../../../../../utils/react-auth0-spa'
import { GET_UNHANDLED_INTENTS_ALL } from '../queries'
import { GET_CURRENT_USER } from '../../../Transcripts/queries'


const useStyles = makeStyles(theme => ({
  seeMore: {
    marginTop: theme.spacing(3)
  }
}));
// const GET_UNHANDLED_INTENTS_ALL = gql`
//   query GET_UNHANDLED_INTENTS_ALL($startTime: String) {
//
//     intentsUnhandledAll(startTime: $startTime) {
//       message
//       id
//       responseTimestamp
//       sentiment {
//         score
//       }
//     }
//   }
// `;


export default function UnhandledIntentsMessages() {
  const classes = useStyles();
  const defaultPageSize = 20;
  const [q, setQ] = React.useState(undefined);
  const [startDate, setStartDate] = useState(moment().subtract("7", "days"));
  const [endDate, setEndDate] = useState(new Date(Date.now()));
  const [currentPage, setCurrentPage] = React.useState(0);
  const [currentPageSize, setCurrentPageSize] = React.useState(defaultPageSize);
  const {loading:loadingUser, data:dataUser, error:errorUser } = useQuery(GET_CURRENT_USER);
  const [currentPagination, setCurrentPagination] = React.useState({
    page: currentPage,
    pageSize: currentPageSize
  });
  const [currentFilter, setCurrentFilter] = React.useState({
    query: q
  });
  React.useEffect(() => {
    if (currentPage !== undefined && currentPageSize !== undefined) {
      setCurrentPagination({
        page: currentPage,
        pageSize: currentPageSize
      });
    } else {
      setCurrentPagination(undefined);
    }
  }, [currentPage, currentPageSize, setCurrentPagination]);

  React.useEffect(() => {
    if (
      q !== undefined &&
      q !== ""
    ) {
      setCurrentFilter({
        query: q
      });
    } else {
      setCurrentFilter(undefined);
    }
  }, [q, setCurrentFilter]);

  const { data, loading, error, networkStatus, refetch } = useQuery(
    GET_UNHANDLED_INTENTS_ALL,
    {
      notifyOnNetworkStatusChange: true,
      variables: {
        startDate: startDate,
        endDate: endDate,
        pagination: {
          limit: currentPageSize,
          offset: currentPageSize * currentPage
        },
        filter: currentFilter,
        agentKey: dataUser.currentAgentKey
      }
    });
  if (loading) return <Loading />;
  if (error) return <Loading />;
  if (!data.intentsUnhandledAll) return <p>Not found</p>;
  const exportHandler = (columns, data) => {
    alert('You should develop a code to export ' + data.length + ' rows');
  }
  const parseDataForCSVExport = (data) => {
    return data.map(intent=>{
        return {
          message:intent.message,
          dateTime: intent.responseTimestamp
        }
    })
  }
  return (
    <React.Fragment>

      <Grid container justify={'center'} className={classes.root} spacing={2}>
        <Grid item xs={12}>
          <MaterialTable
            columns={[
              {
                title: "Date",
                field: "sessionStart",
                render: rowData => new Date(rowData.responseTimestamp).toUTCString()
              },
              {
                title: "Message",
                field: "message",
                numeric: false,
                sorting: false
              },{
                title: "Sentiment",
                field: "sentiment",
                sorting: true,
                render: rowData => <Sentiment sentiment={rowData.sentiment ? rowData.sentiment.magnitude : 0} />

              }
            ]}
            data={data ? data.intentsUnhandledAll ? data.intentsUnhandledAll.intentsUnhandled.map(o => ({ ...o })) : [] : []}
            totalCount={data ? data.intentsUnhandledAll.intentsTotalsUnhandled : 0}
            isLoading={loading || networkStatus === 4}
            icons={{
              FirstPage: FirstPageIcon,
              LastPage: LastPageIcon,
              NextPage: NextPageIcon,
              PreviousPage: PreviousPageIcon,
              SortArrow: ArrowUpIcon,
              Search: SearchIcon,
              ResetSearch: ClearIcon,
              Export: ExportIcon
            }}
            components={{
              Toolbar: props => ( <MaterialTableToolbar
                refetch={refetch} startDate={startDate} setStartDate={setStartDate} endDate={endDate}
                setQ={setQ}
                currentFilter={ currentFilter }
                csvExportFile={'unhandled-intent-messages.csv'}
                data={data ? data.intentsUnhandledAll ? data.intentsUnhandledAll.intentsUnhandled : [] : []}
                csvParseData={parseDataForCSVExport}
              />)
            }}
            // actions={[
            //   {
            //     icon: CreateIcon,
            //     tooltip: 'Train',
            //     onClick: (event, rowData) => {
            //       window.open(`https://dialogflow.cloud.google.com/#/agent/${user['https://thebotforge.eu.auth0.com.app_metadata']['agentId']}/training`)
            //     }
            //   }
            // ]}
            options={{
              search: false,
              pageSize: defaultPageSize,
              emptyRowsWhenPaging: false,
              exportButton: true,
              pageSizeOptions: [20, 50, 100],
              exportAllData: true,
              actionsColumnIndex: -1
            }}
            onChangePage={nextPage => {
              setCurrentPage(nextPage);
            }}
            onChangeRowsPerPage={nextPerPage => {
              setCurrentPageSize(nextPerPage);
              setCurrentPage(0);
            }}
            page={currentPage }

          />
        </Grid>
      </Grid>




    </React.Fragment>
  );
}
