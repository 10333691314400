import React from 'react';
import PropTypes from 'prop-types';
import Select from '@material-ui/core/Select/Select'
import Input from '@material-ui/core/Input/Input'
import MenuItem from '@material-ui/core/MenuItem'
import InputLabel from '@material-ui/core/InputLabel';
import { makeStyles } from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField/TextField'
import PLATFORMS from '../../../constants/PLATFORMS'
ChatbotPlatform.propTypes = {

}
const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));
function ChatbotPlatform ({selectedPlatform, setSelectedPlatform}) {
  const classes = useStyles();
  return (
    <FormControl fullWidth className={classes.formControl}>
      <InputLabel id="platform-select-label">Chatbot platform</InputLabel>
      <Select
        size={"medium"}
        id="platform-select"
        name="chatbotPlatform"
        onChange={(e)=>{
          setSelectedPlatform(e.target.value)
        }}
        value={selectedPlatform}
        input={<Input />}
      >
        {PLATFORMS.map(({name,code}) => (
          <MenuItem key={code} value={code}>
            {name}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}

export default ChatbotPlatform
