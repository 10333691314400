import {
  gql
} from '@apollo/client';


export const GENERATE_API_KEY = gql`
  mutation generateApiKey ($id: ID!) {
      generateApiKey(
        id: $id
      ){
        apiKey
      }
  }
`;
