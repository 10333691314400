import {
  gql, useLazyQuery
} from '@apollo/client';


export const DF_INTENTS_ALL = gql`
    query DF_INTENTS_ALL{ 
        intentsDFAll{
          name,
          displayName
      }
    }
`;
