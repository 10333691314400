import React, {useCallback} from 'react'
import {useDropzone} from 'react-dropzone'
import RootRef from '@material-ui/core/RootRef'
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
  container: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: 20,
    borderWidth: 2,
    borderRadius: 2,
    borderColor: '#666',
    borderStyle: 'dashed',
    backgroundColor: '#fafafa',
    color: '#bdbdbd',
    outline: 'none',
    transition: 'border .24s ease-in-out'
  }

}));

const Dropzone = ({setAddedAgentFile, setAddedAgentFileBinary}) => {
  const onDrop = useCallback(acceptedFiles => {
    // Do something with the files

    acceptedFiles.forEach((file) => {
      const reader = new FileReader()

      reader.onabort = () => console.log('file reading was aborted')
      reader.onerror = () => console.log('file reading has failed')
      reader.onload = () => {
        // Do whatever you want with the file contents
        const binaryStr = reader.result
        console.log(binaryStr)
        setAddedAgentFileBinary(binaryStr)
        setAddedAgentFile(file)
      }
      reader.readAsArrayBuffer(file)

    })


     console.log(acceptedFiles)

  }, [setAddedAgentFile])
  const classes = useStyles();
  const maxSize = 1048576;
  const {isDragActive, getRootProps, getInputProps, isDragReject, acceptedFiles, rejectedFiles} = useDropzone({
    onDrop,
    accept: 'application/json',
    minSize: 0,
    multiple: false,
    maxSize,
  })
  const isFileTooLarge = rejectedFiles ? rejectedFiles.length > 0 && rejectedFiles[0].size > maxSize : false;

  const {ref, ...rootProps} = getRootProps()


  return (
    <RootRef rootRef={ref}>
      <Paper {...rootProps} className={classes.container}>
        <input {...getInputProps()} />
        {!isDragActive && 'Click here or drop a file to upload!'}
        {isDragActive && !isDragReject && "Drop file!"}
        {isDragReject && "File type not accepted, sorry!"}
        {isFileTooLarge && (
          <div className="text-danger mt-2">
            File is too large.
          </div>
        )}
      </Paper>
    </RootRef>
  )
}
export default Dropzone;
