import ErrorSwitch from "../../../../ErrorSwitch";

import React from "react";

const NotificationActive = ({active,setActive}) => (
  <ErrorSwitch
    checked={active}
    lable={"Active"}
    onChange={()=> setActive(!active)}
    name={"active"}
    inputProps={{ "aria-label": "secondary checkbox" }}
  />
);

export default NotificationActive;
