import {
  gql
} from '@apollo/client';

export const GET_CONVERSATIONS = gql`
  query GET_CONVERSATIONS(
    $pagination: PaginationInput
    $filter: FilterConversationInput
    $startDate: String
    $endDate: String
    $agentKey: String
  ) {
    conversationsAll(
      pagination: $pagination
      filter: $filter
      startDate: $startDate
      endDate: $endDate
      agentKey: $agentKey
    ) {
      conversationsCount
      conversations {
        sessionId
        startTime
        createdAt
        updatedAt
        interactions {
          intent {
            name
            displayName
          }
          message
          isFallback
          sender
          id
          responseTimestamp
          sentiment {
            score
            magnitude
          }
        }
      }
    }
  }
`;


export const GET_CURRENT_USER = gql`
  {
    currentAgentKey @client
    currentUserName @client
    currentUserVersion @client
    currentUserEmail @client
    currentUserNickname @client
    currentCompanyName @client
    currentCompanyId @client
    currentCompanyApiKey @client
    currentChatbotLimit @client
  }
`;
