import React from 'react'
import SentimentDissatisfiedIcon from '@material-ui/icons/SentimentDissatisfied'
import SentimentSatisfiedIcon from '@material-ui/icons/SentimentSatisfied'
import SentimentSatisfiedAltIcon from '@material-ui/icons/SentimentSatisfiedAlt';
import SentimentVeryDissatisfiedIcon from '@material-ui/icons/SentimentVeryDissatisfied';
import SentimentVerySatisfiedIcon from '@material-ui/icons/SentimentVerySatisfied';
import { makeStyles } from '@material-ui/core/styles'
const useStyles = makeStyles(theme => ({
  vsat: {
    color: theme.palette.success.light
  },
  satalt: {
    color: theme.palette.success.main
  },
  sat: {
    color: theme.palette.success.dark
  },
  ds: {
    color: theme.palette.error.main
  },
  vds: {
    color: theme.palette.error.dark
  }
}));
const SentimentOption = ({sentiment}) =>{
  const classes = useStyles();
  const sents = {
    SentimentVeryDissatisfiedIcon: <SentimentVeryDissatisfiedIcon className={classes.vds}/>,
    SentimentDissatisfiedIcon: <SentimentDissatisfiedIcon className={classes.ds}/>,
    SentimentSatisfiedIcon: <SentimentSatisfiedIcon className={classes.sat}/>,
    SentimentSatisfiedAltIcon: <SentimentSatisfiedAltIcon className={classes.satalt}/>,
    SentimentVerySatisfiedIcon:  <SentimentVerySatisfiedIcon className={classes.vsat}/>
  }
  return (
    sents[sentiment]
  )
}

export default SentimentOption;
