export const ROUTE_NAME_MAP= {
  "/dashboard": "Dashboard",
  "/gatewayservices": "Gateway Services",
  "/dashboard/chatbots": "Chatbots",
  "/dashboard/reports": "Reports",
  "/dashboard/reports/intents": "Top Intents",
  "/dashboard/reports/unhandledintents": "Unhandled Intent Messages",
  "/dashboard/reports/unhandledintentscomparison":
    "Unhandled Intents Comparison",
  "/dashboard/transcripts": "Transcripts",
  "/dashboard/notifications": "Notifications",
  "/dashboard/notifications/reports": "Automated Reports",
  "/dashboard/notifications/alerts": "Notifications Alerts",
  "/dashboard/embed": "Chatbot Code",
  "/dashboard/account": "Manage Account"
};
