import React, { useState, useEffect, useRef } from "react";
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import SearchIcon from "@material-ui/icons/Search";
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import InputAdornment from '@material-ui/core/InputAdornment';
import FormControl from '@material-ui/core/FormControl';
import ClearIcon from "@material-ui/icons/Clear";
import IconButton from '@material-ui/core/IconButton';

import _throttle from 'lodash/throttle'
const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1
  }
}));

const Search = (props)=>{
  const [isEditing, setEditing] = useState(false);
  const classes  = useStyles();
  const inputRef = useRef(null);
  useEffect(() => {
    if(props.q && props.q.length > 1){
      inputRef.current.focus();
    }
  }, [isEditing]);
  const toggleEditing = () => {
    setEditing(!isEditing);
  };

  const send = function (event) {
    setEditing(true)
    props.setQ(event.target.value)
  }
  return (
    <div className={classes.root}>

        <FormControl className={classes.margin}>
          <Input
            id="input-with-icon-adornment"
            startAdornment={
              <InputAdornment position="start">
                <IconButton
                  aria-label="search transcripts"
                  edge="end"
                  onClick={()=> {
                    props.setQ(props.q);
                  }}
                >
                  <SearchIcon/>
                </IconButton>
              </InputAdornment>
            }
            inputRef={inputRef}
            value={props.q}
            onChange={_throttle(send, 1)}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="search transcripts"
                  edge="end"
                  onClick={()=> props.setQ()}
                >
                  <ClearIcon/>
                </IconButton>
              </InputAdornment>
            }
          />
        </FormControl>
    </div>
  );
}

export default Search;
