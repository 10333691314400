export const isEmail = (value)=> {
  // function that returns true if value is email, false otherwise
  const emails = value.split(",");
  const emailRex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  if (emails.every(val=> emailRex.test(val))) {
    return true;
  }
  return false;
}
export const isUrl = (value)=>{
  // verifies if value is a valid URL
  const urlRex = /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/
  if (urlRex.test(value)) {
    return true;
  }
  return false;
}
// function that verifies if two strings are equal
export const compare = (string1, string2)=> {
  if (string1 === string2) {
    return true;
  }
  return false;
}
// function that verifies if a string has a given length or not
export const verifyLength = (value, length)=> {
  if (value.length >= length) {
    return true;
  }
  return false;
}


// function that verifies if a string has a given length or not
export const notEmpty = (value)=> {
  if (value.length > 0) {
    return true;
  }
  return false;
}

// function that verifies if a string is a phone number or not
export const isPhone = (value)=>{
  const phoneRegx = /(\s*\(?0\d{4}\)?\s*\d{6}\s*)|(\s*\(?0\d{3}\)?\s*\d{3}\s*\d{4}\s*)|(\d{12})/
  if (phoneRegx.test(value)) {
    return true;
  }
  return false;
}

export const isMeeting = (entity)=>{
  return entity.displayName === 'ent-meeting-type';
}

export const isValidEntity = (opts)=>{
  const {entity, entities} = opts;
  const checkValid = (element, index, array)=>{
    return element.question === '' || (!element.entityTypeDisplayName || element.entityTypeDisplayName === '')
  }
  if(entity)
    return entity.entityQuestions.some(checkValid)

  if(entities){
    return entities.some(ent => ent.entityQuestions.some(checkValid))
  }
}
