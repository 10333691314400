import React from 'react'
import useMediaQuery from '@material-ui/core/useMediaQuery/useMediaQuery'
import { createMuiTheme } from "@material-ui/core";
import { useTheme } from '@material-ui/core'
import Grid from '@material-ui/core/Grid';
import { DatePicker, DateTimePicker } from '@material-ui/pickers'
import DateRangeIcon from '@material-ui/icons/DateRange';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles'
import CustomDateRange from './CustomDateRange'
const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(1)
  },
  mobileRoot:{
    margin: theme.spacing(1)
  }
}));

const DateSpanPicker = ({startDate,endDate,setCustomRange,setStartDate,setEndDate,setManualDate,manualDate})=>{
  const theme = useTheme()
  const classes = useStyles();
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'))
  const isMobile = !isDesktop

  if(!isMobile){
    return (
      <Grid
      container
      direction="row"
      justify="flex-end"
      alignItems="center"
    ><div className={classes.root}>
      <Paper>
        <div className={classes.root}>
      <Grid container direction="row" justify="center" alignItems="flex-end" spacing={1}>
        <Grid item>
          <DateRangeIcon/>
        </Grid>
        <Grid item>
          <CustomDateRange setCustomRange={setCustomRange}/>
        </Grid>
        <Grid item>
          <DatePicker
            autoOk
            disableFuture
            value={startDate}
            onChange={m=>{
              setStartDate(m)
              setManualDate(!manualDate)
            }}
            label="From"
            variant="inline"
            format="MMM D, YYYY"
            size="small"
          />
        </Grid>
        <Grid item>
          <DatePicker
            autoOk
            disableFuture
            value={endDate}
            onChange={m=>{
              setEndDate(m)
              setManualDate(!manualDate)
            }}
            label="To"
            variant="inline"
            size="small"
            format="MMM D, YYYY"
          />
        </Grid>
      </Grid>
        </div>
      </Paper>
      </div>
    </Grid>
  )
  }else {
    return (
        <Paper>
          <div className={classes.mobileRoot}>
            <DateRangeIcon/>
          </div>
        </Paper>
    )
  }
}


export default DateSpanPicker
