import React, { Fragment, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import {
  useQuery,
  useMutation
} from '@apollo/client';

import Loading from "../../../Loading";

import NotificationsGridRow from "./NotificationsGridRow";
import SimpleDialog from "../../../SimpleDialog";
import NotificationAddEdit from "../NotificationAddEdit";
import { useSnackbar } from 'notistack';
import { GET_NOTIFICATIONS } from "./queries";
import { ADD_NOTIFICATION, UPDATE_NOTIFICATION, REMOVE_NOTIFICATION } from "./mutations";
import useCancelDialog from '../../../CancelDialog/useCancelDialog'
import CancelDialog from '../../../CancelDialog'
import NoResultsText from '../../../NoResultsText'
import {UNHANDLED_INTENT} from './../../../../constants/ALERT_TYPES'
import { GET_CURRENT_USER } from './queries'

const useStyles = makeStyles(theme => ({

  content: {
    padding: theme.spacing(2)
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120
  },
  selectEmpty: {
    marginTop: theme.spacing(2)
  },
  tableCell: {
    display: "flex"
  },
  noResults: {
    color: theme.palette.error.dark
  }
}));

const NotificationsGrid = ({
  notificationType,
  openActionAdd,
  addMode,
  setAddMode,
  handleClose,
  setOpenActionAdd
}) => {
  const classes = useStyles();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [isValid, setIsValid] = useState(false);
  const [active, setActive] = useState(false);
  const [name, setName] = useState("");
  const [id, setId] = useState("");
  const {isShowing, toggle} = useCancelDialog();
  const [frequency, setFrequency] = useState();
  const [notificationTarget, setNotificationTarget] = useState({
    notificationTarget: "",
    notificationTargetValue: ""
  });
  const {loading:loadingUser, data:dataUser, error:errorUser } = useQuery(GET_CURRENT_USER);
  const validNotificationTarget = () =>
    notificationTarget.notificationTarget && notificationTarget.notificationTargetValue;
  const [action, setAction] = useState({
    action: "",
    actionValue: ""
  });
  const validAction = () => {
    if(action.action && action.action === UNHANDLED_INTENT){
      return true
    }
    return action.action && action.actionValue !== null;
  }
  React.useEffect(() => {
    console.log(addMode)
    if(openActionAdd && addMode){
      handleCancel();
    }
  },[openActionAdd]);


  React.useEffect(() => {
    let valid = true;
    if(isReport()){
      valid = name !== "" && validNotificationTarget();
    }else{
      valid = name !== "" && validNotificationTarget() && validAction();
    }
    setIsValid(valid);
  }, [
    name,
    setName,
    action,
    setAction,
    notificationTarget,
    setNotificationTarget
  ]);

  let { loading: queryLoading, error: queryError, data } = useQuery(
    GET_NOTIFICATIONS,
    {
      variables: {
        notificationType: notificationType,
        agentKey: dataUser.currentAgentKey,
        user_id: dataUser.currentUserId
      }
    }
  );

  const [
    updateNotification,
    { loading: updateLoading, error: updateError, called: updateCalled }
  ] = useMutation(UPDATE_NOTIFICATION);

  const [
    removeNotification,
    { loading: removeLoading, error: removeError, called: removeCalled }
  ] = useMutation(REMOVE_NOTIFICATION);

  const [addNotification, {
    loading: addLoading, error: addError, called: addCalled
  }] = useMutation(ADD_NOTIFICATION);

  const handleRemoveNotification = () =>{
    removeNotification({
      variables: {
        id: id
      },
      refetchQueries: [
        {
          query: GET_NOTIFICATIONS,
          variables: { notificationType: notificationType,agentKey: dataUser.currentAgentKey,user_id: dataUser.currentUserId }
        }
      ]
    });
  }
  const handleCancel = () => {
    //clear out all the changes in state
    setName("");
    setActive(false);
    setNotificationTarget({
      notificationTarget: "",
      notificationTargetValue: ""
    });
    setAction({
      action: "",
      actionValue: ""
    });
  };
  const isReport = () => notificationType === "REPORT"
  if (updateLoading && updateCalled) {
    enqueueSnackbar('Notification updated',{
      anchorOrigin:{ vertical: "bottom"},
      variant: "success"
    });
  }

  if (addLoading && addCalled) {
    enqueueSnackbar('Notification added',{
      anchorOrigin:{ vertical: "bottom"},
      variant: "success"
    });
  }

  if (removeLoading && removeCalled) {
    enqueueSnackbar('Notification removed',{
      anchorOrigin:{ vertical: "bottom"},
      variant: "success"
    });
  }

  if (addError) {
    enqueueSnackbar('There was an error',{
      anchorOrigin:{ horizontal: "right", vertical: "bottom"},
      variant: "error"
    });
  }

  if (queryLoading) return <Loading />;
  if (queryError) return <Loading />;

  const handleSave = (variables) =>{
    if (addMode) {
      addNotification({
        variables: {
          name: name,
          action: action.action,
          actionValue: String(action.actionValue),
          active: true,
          notificationTarget: notificationTarget.notificationTarget,
          notificationTargetValue:
          notificationTarget.notificationTargetValue,
          notificationType: "ALERT",
          agentKey: dataUser.currentAgentKey,
          user_id: dataUser.currentUserId
        },
        refetchQueries: [
          {
            query: GET_NOTIFICATIONS,
            variables: { notificationType: notificationType,agentKey: dataUser.currentAgentKey,user_id: dataUser.currentUserId }
          }
        ]
      });
    } else {

      updateNotification({
        variables: variables || {
          id: id,
          name: name,
          action: action.action,
          actionValue: String(action.actionValue),
          active: active,
          notificationTarget: notificationTarget.notificationTarget,
          notificationTargetValue:
          notificationTarget.notificationTargetValue,
          notificationType: "ALERT",
          frequency: frequency
        },
        refetchQueries: [
          {
            query: GET_NOTIFICATIONS,
            variables: { notificationType: notificationType,agentKey: dataUser.currentAgentKey,user_id: dataUser.currentUserId }
          }
        ]
      });
    }
  }
  return (
    <Fragment>
      {!data.notificationsAll || data.notificationsAll.length <= 0
        ? <Fragment>
          {(isReport() ? <NoResultsText message={"No reports found"}/> : <NoResultsText message={"No alerts found"}/>)}
        </Fragment>
        : <Fragment>
          <TableContainer component={Paper}>
            <Table className={classes.table} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>Name</TableCell>
                  <TableCell>Action</TableCell>
                  <TableCell align="left">Active</TableCell>
                  <TableCell align="left">Target</TableCell>
                  {notificationType === "REPORT" && (
                    <TableCell align="left">Frequency</TableCell>
                  )}
                  <TableCell align="left"></TableCell>
                  <TableCell align="left"></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data.notificationsAll.map(
                  ({
                     id,
                     name,
                     action,
                     scheduleDateTime,
                     active,
                     frequency,
                     notificationTargetValue,
                     notificationTarget,
                     notificationType,
                     actionValue
                   }) => (
                    <NotificationsGridRow
                      key={id}
                      setNotificationTarget={setNotificationTarget}
                      setAction={setAction}
                      setActive={setActive}
                      setName={setName}
                      setId={setId}
                      setAddMode={setAddMode}
                      setFrequency={setFrequency}
                      setOpenActionAdd={setOpenActionAdd}
                      toggleCancel={toggle}
                      handleSave={handleSave}
                      {...{
                        id,
                        name,
                        action,
                        scheduleDateTime,
                        active,
                        frequency,
                        notificationTarget,
                        notificationTargetValue,
                        notificationType,
                        actionValue,
                        classes
                      }}
                    />
                  )
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Fragment>
      }


      <SimpleDialog
        title={addMode ? "Create Alert" : "Edit Alert"}
        subtitle={``}
        isValid={isValid}
        fullWidth={true}
        maxWidth={"md"}
        onSave={() => {
          handleSave();
          handleClose();
        }}
        onClose={() => {
          handleCancel();
          handleClose();
        }}
        open={openActionAdd || false}
      >
        <NotificationAddEdit
          notificationTarget={notificationTarget}
          setNotificationTarget={setNotificationTarget}
          action={action}
          setAction={setAction}
          frequency={frequency}
          setFrequency={setFrequency}
          name={name}
          setName={setName}
          active={active}
          setActive={setActive}
          isReport={isReport}
        />
      </SimpleDialog>

      <CancelDialog
        isShowing={isShowing}
        hide={toggle}
        confirmButtonText={'Delete Notification'}
        confirm={()=>{
          handleRemoveNotification();
          toggle();
        }}
        confirmText={"You won't be able to recover the alert notification."}
      />

    </Fragment>
  );
};
export default NotificationsGrid;
