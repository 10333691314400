import React from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import SentimentOption from '../../../../Sentiment/SentimentOption'
import {SentimentList} from '../../../../Sentiment/SentimentSelect'
import { SENTIMENT } from '../../../../../constants/ALERT_TYPES'
const NotificationsActionDisplay = ({ actionValue, action }) => {
  const getSentimentIcon = (sentList) => {
    const s = sentList.find(s=>s.value===actionValue)
    if(s) return <SentimentOption sentiment={s.sentiment}/>
    return null;
  }
  return (
    <React.Fragment>
      <Grid container direction="column">
        <Grid item>
          <Typography variant="caption" display="block" gutterBottom>
            Type: {action}
          </Typography>
        </Grid>
        {
          actionValue && <Grid item>{
            (action === SENTIMENT) ? getSentimentIcon(SentimentList) : <Typography variant="caption" display="block" gutterBottom>
              Trigger: {actionValue}
            </Typography>}
          </Grid>
        }

      </Grid>
    </React.Fragment>
  );
};

export default NotificationsActionDisplay;
