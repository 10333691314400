import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import Grid from '@material-ui/core/Grid'
import ChatbotActive from './../ChatbotActive'
import ChatbotName from './../ChatbotName'
import ChatbotTimezone from '../ChatbotTimezone'
import ChatbotPlatform from '../ChatbotPlatform'
import {
  useApolloClient
} from '@apollo/client';
import ChatbotProjectId from '../ChatbotProjectId'


const ChatbotAddEdit = props => {
  const client = useApolloClient();
  return (
    <Fragment>
      <Grid container spacing={3}
            direction="row"
            justify="center"
            alignItems="center"
      >

        <Grid item xs={12}>
          <Grid container spacing={1} justify="flex-start" alignItems="flex-end">
            {/*<Grid item xs={3}>*/}
            {/*  <ChatbotActive {...props}/>*/}
            {/*</Grid>*/}
            <Grid item xs={12}>
              <ChatbotName {...props} client={client}/>
            </Grid>
            <Grid item xs={12}>
              <ChatbotPlatform {...props}/>
            </Grid>
            <Grid item xs={12}>
              <ChatbotProjectId {...props} client={client}/>
            </Grid>
            <Grid item xs={12}>
              <ChatbotTimezone {...props}/>
            </Grid>
            <Grid item xs={12}>
              <ChatbotActive {...props}/>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>

        </Grid>
      </Grid>
    </Fragment>
  )
}

ChatbotAddEdit.propTypes = {

}

export default ChatbotAddEdit
