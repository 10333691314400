import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';

import {
  useApolloClient, useQuery,useMutation, gql
} from '@apollo/client';
//import { GET_CHATBOT } from "./queries";
import Loading from "../../components/Loading";
//import { EMAIL_EMBED_CODE } from "./mutations";

import { GET_CURRENT_USER } from '../../components/Chatbots/queries'
import AccountDetails from '../../components/dashboard/AccountDetails'
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          {children}
        </Box>
      )}
    </div>
  );
}
const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1
  },
  paper: {
    height: 140,
    width: 100
  },
  control: {
    padding: theme.spacing(2)
  },
  half: {
    height: "50vh"
  }
}));
const Account =()=> {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);
  const client = useApolloClient();
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const { data: userData, userError, loading: userDataLoading } = useQuery(GET_CURRENT_USER);


  return (
    <React.Fragment>
      <Grid container justify={"center"} spacing={0}>
        <Grid item xs={12}>
            <Tabs value={value} onChange={handleChange} aria-label="simple tabs example">
              <Tab label="Account" />

            </Tabs>

          <TabPanel value={value} index={0}>
            <AccountDetails/>
          </TabPanel>

        </Grid>
      </Grid>
    </React.Fragment>
  );
};

export default Account;
