import React, { Fragment } from 'react'
import TextField from '@material-ui/core/TextField'

const NotificationName = ({name,setName})=>{
  return(
    <TextField
      fullWidth
      size={"medium"}
      value={name}
      name={"name"}
      label="Alert Name"
      onChange={event => {
        setName(event.target.value);
      }}
    />
  )
}
export default NotificationName;
