import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import moment from 'moment'

const useStyles = makeStyles({
  root: {
    minWidth: 275,
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
});
export default function Billing({cost,company}) {
  const classes = useStyles();
  const bull = <span className={classes.bullet}>•</span>;

  return (
    <Card className={classes.root}>
      <CardContent>
        <Typography variant="h5" component="h2" align={'left'} color="textSecondary" gutterBottom>
          Current month
        </Typography>
        <Typography variant="h5" component="h2" align={'left'}>
          1-{moment().format("D MMMM YYYY")}
        </Typography>
        <Typography color="textSecondary" align={'left'}>
          Month-to-date total cost
        </Typography>
        <Typography variant="body2" component="p" align={'left'}>
          £{company ? company.cost : 0}
        </Typography>
        <Typography color="textSecondary" align={'left'}>
          Month-to-date call count
        </Typography>
        <Typography variant="body2" component="p" align={'left'}>
          {company ? company.gsCallCount : 0}
        </Typography>
          {
            company && <Typography variant="body2" component="p" align={'left'}>{company.name}</Typography>
          }
      </CardContent>
      {/*<CardActions>*/}
      {/*  <Button size="small">Learn More</Button>*/}
      {/*</CardActions>*/}
    </Card>
  );
}
