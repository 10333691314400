import React  from "react";
import { useAuth0 } from '../../utils/react-auth0-spa';
import Loading from '../Loading';
import { Paper } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';

import { makeStyles } from '@material-ui/core/styles';
import Typography from "@material-ui/core/Typography";
import Avatar from '@material-ui/core/Avatar';
import {
  gql, useQuery
} from '@apollo/client';

const useStyles = makeStyles(theme => ({
  profilePic:  {
    maxWidth: 100
  },
  overflow: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    width: 200
  }
}));

const GET_CURRENT_USER = gql`
  {
    currentUserVersion @client
    currentUserName @client
    currentUserEmail @client
    currentUserNickname @client
    currentCompanyName @client
  }
`;

const Profile = () => {
  const classes = useStyles();
  const {loading, data, error } = useQuery(GET_CURRENT_USER);
  if(loading || !data) {
    return <Loading/>
  }

  return (

      <Grid
        container
        direction="column"
        justify="center"
        alignItems="center"
      >
      <Avatar alt={data.currentUserName} src={data.currentUserPicture} />
      <Typography align={'center'} component="h6" variant="subtitle1" color="inherit" noWrap>
        {data.currentUserName === data.currentUserEmail? data.currentUserNickname : data.currentUserName}
      </Typography>
      <Typography align={'center'} component="div" variant="subtitle2" color="inherit" className={classes.overflow}>
        {data.currentUserEmail}
      </Typography>
        <Typography align={'center'} component="div" variant="subtitle2" color="inherit" className={classes.overflow}>
          {data.currentCompanyName}
        </Typography>
      </Grid>

  );
}

export default Profile;
