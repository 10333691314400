import React,{Fragment} from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import AddIcon from '@material-ui/icons/Add'
import Grid from '@material-ui/core/Grid'
const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },

}));

const ActionPanel = ({setOpenAddAgent,allowEdit}) =>{
  const classes = useStyles();
  return (
    <Fragment>
      <Grid container spacing={3}
            direction="row"
            justify="space-between"
            alignItems="flex-end" className={classes.root}>
        <Grid item xs={9}>

        </Grid>
        <Grid item xs={3}>{
          allowEdit && <Button
            variant="outlined"
            color="secondary"
            onClick={event=>setOpenAddAgent(true)}
            startIcon={<AddIcon />}
          >
            Add Dialogflow Agent
          </Button>
        }

        </Grid>
      </Grid>
    </Fragment>
  )
}
export default ActionPanel;
