import {
  gql
} from '@apollo/client';




export const GET_INTERACTIONS_IN_OUT = gql`
    query GET_INTERACTIONS_IN_OUT($startDate: String,$endDate: String,$agentKey: String){ 
        interactionsInOut(startDate: $startDate,endDate: $endDate,agentKey: $agentKey) {
          in,
          out
      }
    }
`;
