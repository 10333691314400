import React  from 'react'
import {
  Switch,
  Route
} from "react-router-dom";

import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';

import Container from '@material-ui/core/Container';
import TopIntents from '../../components/dashboard/Analytics/TopIntents/index'
import Unhandled from '../../components/dashboard/Analytics/UnhandledIntents/UnhandledIntentsMessages/index'
import UnhandledComparison from '../../components/dashboard/Analytics/UnhandledIntents/UnhandledIntentsComparisonGraph/index'

const useStyles = makeStyles(theme => ({}));

export default function Analytics() {
  const classes = useStyles();

  return (

    <Container maxWidth="lg" className={classes.container}>
      <Grid container spacing={3}>
        <Switch>
          <Route path="/dashboard/reports/intents" component={TopIntents} />
          <Route path="/dashboard/reports/unhandledintents" component={Unhandled} />
          <Route path="/dashboard/reports/unhandledintentscomparison" component={UnhandledComparison} />
        </Switch>
      </Grid>
    </Container>
  )
}

