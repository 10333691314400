import React from 'react'
import { makeStyles, createStyles, } from '@material-ui/core/styles'
import List from '@material-ui/core/List'
import ListSubheader from '@material-ui/core/ListSubheader'

import BarChartIcon from '@material-ui/icons/BarChart';
import ChatIcon from '@material-ui/icons/Chat';
import DashboardIcon from '@material-ui/icons/Dashboard';
import NotificationsIcon from '@material-ui/icons/Notifications';
import AnnouncementIcon from '@material-ui/icons/Announcement';
import NotificationsActiveIcon from '@material-ui/icons/NotificationsActive';
import AssessmentIcon from '@material-ui/icons/Assessment';
import SidebarNavItems from './SidebarNavItems'
import Loading from '../Loading'

import {
  useQuery, gql
} from '@apollo/client';


const GET_CURRENT_USER = gql`
  {
    currentUserVersion @client
  }
`;

export const SidebarNavProps = {
  isCollapsed: false
}
const useStyles = makeStyles((theme) =>
  createStyles({
    navList: {
      width: 240,
      fontSize: '1.1em',
      fontWeight: 400,
      lineHeight: 1.5,
      letterSpacing: '0.00938em',
    },
    navListHeader: {
      textAlign: 'center',
    },
    iconFeatures: {
      color: '#95de3c',
    },
    iconDocs: {
      color: '#f8cda9',
    },
    iconSupporters: {
      color: '#e3b546',
    },
    iconDiscuss: {
      color: '#ccc',
    },
  }),
)

const SidebarNav = (props) => {
  const classes = useStyles();
  const { data, loading } = useQuery(GET_CURRENT_USER);
  if(!data || loading) {
    return <Loading/>
  }
  const { isCollapsed, isMobile, handleDrawerOpen } = props

  const dashboard_links = {
    name: 'Dashboard',
    link: '/dashboard',
    Icon: DashboardIcon,
  }
  const notification_links =  {
    name: 'Notifications',
    link: '/dashboard/notifications/alerts',
    Icon: NotificationsIcon,
    items: [
      {
        name: 'Alerts',
        link: '/dashboard/notifications/alerts',
        Icon: NotificationsActiveIcon
      },
      {
        name: 'Automated Reports',
        link: '/dashboard/notifications/reports',
        Icon: AssessmentIcon
      }]
  }
  const report_links = {
    name: 'Reports',
    link: '/dashboard/reports/intents',
    Icon: BarChartIcon,
    items: [
      {
        name: 'Intents',
        link: '/dashboard/reports/intents',
        Icon: ChatIcon
      },
      {
        name: 'Unhandled Intents',
        link: '/dashboard/reports/unhandledintents',
        Icon: AnnouncementIcon
      },
      {
        name: 'Unhandled Comparison',
        link: '/dashboard/reports/unhandledintentscomparison',
        Icon: AnnouncementIcon
      }
    ]
  }
  const transcript_links = {
    name: 'Transcripts',
    link: '/dashboard/transcripts',
    Icon: ChatIcon,
  };
  // const chatbots = {
  //   name: 'Chatbots',
  //   link: '/dashboard/chatbots',
  //   Icon: SettingsApplicationsIcon,
  // };
  // const embed_code = {
  //   name: 'Embed Code',
  //   link: '/dashboard/embed',
  //   Icon: CodeIcon,
  // };
  const menuItems = [
    dashboard_links
  ]

  if(data.currentUserVersion === "Pro"){
    menuItems.push(notification_links)
  }
  menuItems.push(report_links,transcript_links)

  return (
    <div>
      <List className={classes.navList} disablePadding>
        <SidebarNavItems handleDrawerOpen={handleDrawerOpen} isCollapsed={isCollapsed} items={menuItems} isMobile={isMobile} />
      </List>
    </div>
  )
}


export default SidebarNav
