import AppBar from "@material-ui/core/AppBar/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Grid from "@material-ui/core/Grid";
import HeaderLogo from "../Header/HeaderLogo";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Dialog from "@material-ui/core/Dialog/Dialog";
import React, { Fragment } from "react";
import Grow from "@material-ui/core/Grow/Grow";
import TextField from "@material-ui/core/TextField";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import InputAdornment from "@material-ui/core/InputAdornment";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import Button from "@material-ui/core/Button";
import DialogTitle from "@material-ui/core/DialogTitle";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import bg from "../../assets/img/chatseer-logo@1680x945.png";
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Grow ref={ref} {...props} />;
});
const useStyles = makeStyles(theme => ({
  getStartedBar: {
    position: "relative"
  },
  getStartedDialog: {
    display: "flex",
    flexWrap: "wrap",
    flexDirection: "column",
    alignContent: "center",
    alignItems: "center",
    minHeight: '100%',
    [theme.breakpoints.up('md')]: {
      background: `${theme.palette.primary.main} no-repeat center/100% url("${bg}")`,
    },
    [theme.breakpoints.down('sm')]: {
      background: `${theme.palette.primary.main} no-repeat center/100% url("${bg}")`,
      backgroundSize: 'cover',
      backgroundPosition: 'bottom right'
    }
  },
  getStartedDialogContent: {
    marginBottom: theme.spacing(3),
    marginTop: theme.spacing(3),
    minWidth: "40%"
  },
  getStartedPaper: {
    backgroundColor: theme.palette.primary.main,
    padding: theme.spacing(2)
  },
  header: {
    fontWeight: theme.typography.fontWeightBold,
    color: theme.palette.common.black,
    padding: theme.spacing(1),
    display: "flex",
    backgroundColor: theme.palette.common.white
  },
  form: {
    display: "flex",
    flexDirection: "column"
    //minHeight: '100%'
  },
  margin: {
    margin: theme.spacing(1)
  },
  headerText: {
    fontWeight: 700,
    color: theme.palette.common.white
  }
}));
const CssTextField = withStyles(theme => ({
  root: {
    "& input": {
      color: theme.palette.common.white
    },

    "& label.Mui-focused": {
      color: theme.palette.common.white
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: theme.palette.common.white
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: theme.palette.common.white
      },
      "&:hover fieldset": {
        borderColor: theme.palette.common.white
      },
      "&.Mui-focused fieldset": {
        borderColor: theme.palette.common.white
      }
    }
  }
}))(TextField);
const GetStarted = ({
  open,
  setOpen,
  handleClose,
  setEmail,
  email,
  sm,
  fname,
  setFname
}) => {
  const classes = useStyles();
  return (
    <Dialog
      classes={{
        paper: classes.getStartedDialog
      }}
      fullScreen
      open={open}
      onClose={handleClose}
      TransitionComponent={Transition}
    >
      <AppBar className={classes.header} position="relative">
        <Toolbar>
          <Grid
            container
            direction="row"
            justify="space-between"
            alignItems="center"
          >
            <HeaderLogo />
            <IconButton
              edge="end"
              onClick={() => setOpen(!open)}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
          </Grid>
        </Toolbar>
      </AppBar>

      <DialogContent className={classes.getStartedDialogContent}>
        <Paper className={classes.getStartedPaper}>
          <Typography
            component={sm ? "p" : "h4"}
            variant={sm ? "p" : "h4"}
            align={"center"}
            className={classes.headerText}
          >
            You're Almost Done!
          </Typography>
          <Typography
            component={sm ? "p" : "h4"}
            variant={sm ? "p" : "p"}
            align={"center"}
            className={classes.headerText}
          >
            Please complete your final details so we can set you up on the
            system
          </Typography>
          <form className={classes.form} noValidate>
            <CssTextField
              fullWidth
              size={"small"}
              value={email}
              name={"email"}
              label="Email:"
              onChange={event => {
                setEmail(event.target.value);
              }}
              disabled={false}
              className={classes.margin}
            />

            <CssTextField
              fullWidth
              size={"small"}
              value={fname}
              name={"firstName"}
              label="First Name:"
              onChange={event => {
                setFname(event.target.value);
              }}
              disabled={false}
              className={classes.margin}
            />

            <CssTextField
              fullWidth
              size={"small"}
              value={""}
              name={"lastName"}
              label="Last Name:"
              onChange={event => {}}
              disabled={false}
              className={classes.margin}
            />
            <CssTextField
              fullWidth
              size={"small"}
              value={""}
              name={"companyName"}
              label="Company Name:"
              onChange={event => {}}
              disabled={false}
              className={classes.margin}
            />

            <FormControlLabel
              control={
                <Checkbox
                  checked={false}
                  onChange={() => {}}
                  name="desclaimer"
                />
              }
              label="I agree to The Bot Forge using my contact details for marketing purposes"
            />

            <Grid
              container
              direction="row"
              justify="space-around"
              alignItems="center"
            >
              <Button
                onClick={handleClose}
                variant="contained"
                color="secondary"
              >
                Request Access
              </Button>
              <Button
                onClick={handleClose}
                variant="contained"
                color="secondary"
              >
                No Thanks
              </Button>
            </Grid>
          </form>
        </Paper>
      </DialogContent>
    </Dialog>
  );
};

export default GetStarted;
