import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Link from '@material-ui/core/Link';
import ApiResource from './ApiResource'

import {
  code_curlAuthRequest,
  code_curlAuthResponse,
  code_curlPostMessageRequest, code_curlPostMessageResponse,
  code_curlUseToken
} from './code-tips'

import ApiStatusCodes from './ApiStatusCodes'
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </Typography>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
  tabs: {
    color: theme.palette.common.white
  }
}));

const ApiDocs = ({dfProjectId}) => {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);
  const resourceUrl = `https://cgs.thebotforge.io/api/${dfProjectId}`
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div className={classes.root}>
      <AppBar position="static">
        <Tabs textColor="secondary" value={value} onChange={handleChange} aria-label="simple tabs example">
          <Tab className={classes.tabs} label="Authentication" {...a11yProps(0)} />
          <Tab className={classes.tabs} label="Posting to Gateway" {...a11yProps(1)} />
          <Tab className={classes.tabs} label="API Status Codes" {...a11yProps(2)} />
        </Tabs>
      </AppBar>
      <TabPanel value={value} index={0}>
        <ApiResource
          key={'authentication'}
          header={'Authentication'}
          description={ <Typography variant={'body1'} align={'left'} gutterBottom>
            To post a rest call to the gateway you will need to request an access token to send in your subsequent post requests to the gateway.
            You can inspect how this token is built at <Link href="https://jwt.io" variant="body2">  jwt.io </Link>
            Once you have a key can now extract the access_token property from the response to make authorized requests to your API.
          </Typography>}
          resourceUrl={'https://cgs.thebotforge.io/api/oauth/token'}
          resourceInformation={[{'lable': 'Response formats','value':'JSON'}]}
          resourceParameters={[
              {'name': 'client_id','required':'yes', 'description':'Your client id','example':''},
              {'name': 'client_secret','required':'yes','description':'Your client secret','example':''}
            ]
          }
          requestCode={code_curlAuthRequest}
          responseCode={code_curlAuthResponse}
          extra1code={code_curlUseToken}
          extra1Header={'Sending the token to the API'}
          extra1Content={'You can use this bearer token in an Authorization Header in any further requests to access the gateway API.'}
        />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <ApiResource
          key={'postMessage'}
          header={'Post Message'}
          description={ <Typography variant={'body1'} component={'div'} align={'left'} gutterBottom>
            Sends a text message to the Dialogflow Gateway Service which will relay your message to the connected Dialogflow V2 API.
            There are 2 versions of response:
            <ol>
              <li>Formatted- which is comprised of a slightly simplified version of the Dialogflow agent response.</li>
              <li>Unformatted- original response from the Dialogflow agent.</li>
            </ol>

          </Typography>}
          resourceUrl={'https://cgs.thebotforge.io/api/:agent'}
          resourceInformation={[{'lable': 'Response formats','value':'JSON'},{'lable':'Requires authentication?','value':'Yes, send authorization: Bearer YOUR_TOKEN'},{'lable':'Cost','value':'Free quota of 250 messages per month.\n' +
              'Price above free limit (per message) £0.02'}]}
          resourceParameters={[
            {'name': 'session_id','required':'yes', 'description':'The session id to use in this conversation','example':'a3d77065-fdfc-4a0e-bcbe-566e02033a8a','default_val':''},
            {'name': 'query','required':'yes','description':'String request to send to the gateway to talk to your Dialogflow agent','example':'please can you tell me when you are open','default_val':''},
            {'name': 'format','required':'false','description':'Request a simplified formatted response from the Gateway Service','example':'true','default_val':'false'}
          ]}
          requestCode={code_curlPostMessageRequest.replace('df_project',dfProjectId)}
          responseCode={code_curlPostMessageResponse}
          extra1code={''}
          extra1Header={ 'Usage Notes'}
          extra1Content={'250 calls per month free'}
        />




      </TabPanel>
      <TabPanel value={value} index={2}>
        <ApiStatusCodes/>
      </TabPanel>
    </div>
  );
}

export default ApiDocs
