import React from 'react'
import ListItem from '@material-ui/core/ListItem'
import DeleteIcon from '@material-ui/icons/Delete'
import FileIcon from '@material-ui/icons/Description'
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText'
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction'
import IconButton from '@material-ui/core/IconButton'
import List from '@material-ui/core/List'

const AgentFile = ({addedAgentFile}) => {
  if(!addedAgentFile) return null;
  return (
    <List>
      <ListItem>
        <ListItemIcon>
          <FileIcon />
        </ListItemIcon>
        <ListItemText
          primary={addedAgentFile.name}
          secondary={"Service account file"}
        />
        <ListItemSecondaryAction>
          <IconButton edge="end" aria-label="delete">
            <DeleteIcon />
          </IconButton>
        </ListItemSecondaryAction>
      </ListItem>
    </List>

  )
}

export default AgentFile;
