import {
  gql
} from '@apollo/client';

export const GET_CURRENT_USER = gql`
  {
    currentAgentKey @client
    currentUserName @client
    currentUserVersion @client
    currentUserEmail @client
    currentUserNickname @client
    currentCompanyName @client
    currentCompanyId @client
    currentCompanyApiKey @client
    currentChatbotLimit @client
  }
`;
