import React, { Fragment } from "react";
import { makeStyles } from "@material-ui/core/styles";
import DashboardWidget from "../DashboardWidget";
import DashboardWidgetCounter from "../../Widgets/DashboardWidgetCounterNumber";
import DashboardWidgetCounterHolder from "../../Widgets/DashboardWidgetCounterHolder";
import DashboardWidgetCounterGraph from "../../Widgets/DashboardWidgetCounterGraph";
import Grid from "@material-ui/core/Grid";
import { gql, useQuery } from "@apollo/client";
import Loading from "../../../Loading";
import { RELOAD_PERIOD } from "../../../../utils/settings";
import { GET_INTERACTIONS_IN_OUT } from "../../Widgets/DashboardWidgetInteractionActivityCounters/queries";

const useStyles = makeStyles({});

const DashboardWidgetInteractionActivityCounters = ({
  startDate,
  endDate,
  currentUser
}) => {
  const classes = useStyles();
  const { data, loading, error } = useQuery(GET_INTERACTIONS_IN_OUT, {
    pollInterval: RELOAD_PERIOD,
    variables: {
      startDate: startDate,
      endDate: endDate,
      agentKey: currentUser ? currentUser.currentAgentKey : null
    }
  });

  if (loading) return <Loading />;
  if (error) return <Loading />;
  if (!data.interactionsInOut) return <p>Not found</p>;
  const { interactionsInOut } = data;
  const showSmall = () => {
    return (
      interactionsInOut.in.toString().length > 3 ||
      interactionsInOut.out.toString().length > 3 ||
      (interactionsInOut.in + interactionsInOut.out).toString().length > 3
    );
  };
  return (
    <Fragment>
      <Grid
        container
        direction="row"
        justify="center"
        alignItems="center"
        spacing={2}
      >
        <Grid item>
          <DashboardWidgetCounterHolder
            title={"Incoming"}
            counter={<DashboardWidgetCounter value={interactionsInOut.in} showSmall={showSmall()}/>}
            graph={
              <DashboardWidgetCounterGraph
                value={interactionsInOut.in}
                total={interactionsInOut.in + interactionsInOut.out}
              />
            }
          />
        </Grid>
        <Grid item>
          <DashboardWidgetCounterHolder
            title={"Outgoing"}
            counter={<DashboardWidgetCounter value={interactionsInOut.out} showSmall={showSmall()}/>}
            graph={
              <DashboardWidgetCounterGraph
                value={interactionsInOut.out}
                total={interactionsInOut.in + interactionsInOut.out}
              />
            }
          />
        </Grid>
        <Grid item>
          <DashboardWidgetCounterHolder
            title={"Total"}
            counter={
              <DashboardWidgetCounter
                value={interactionsInOut.in + interactionsInOut.out} showSmall={showSmall()}
              />
            }
            graph={
              <DashboardWidgetCounterGraph
                value={interactionsInOut.in + interactionsInOut.out}
                total={interactionsInOut.in + interactionsInOut.out}
              />
            }
          />
        </Grid>
      </Grid>
    </Fragment>
  );
};

export default DashboardWidgetInteractionActivityCounters;
