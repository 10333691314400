import React, {Fragment} from 'react';
import Copyright from './Copyright'
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
const useStyles = makeStyles(theme => ({
  appBarBottom: {
    top: 'auto',
    bottom: 0,
    zIndex: 99,
    opacity: '0.5',
    backgroundColor: '#fff',
    maxHeight: '46px',
    minHeight: '46px',
    background: 'fff',
    position: 'fixed',
    left: 0,
    width: '100%'
  },

  copyrightContainer: {
    bottom: 0,
    left: theme.spacing(1),
    position: 'fixed',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    zIndex: 100,
    maxHeight: '46px',
    minHeight: '46px',
  }
}));

const FooterHome = ()=>{
  const classes = useStyles();
  return (
    <Fragment>

      <div className={classes.appBarBottom}>
      </div>
      <div className={classes.copyrightContainer}>

      </div>


    </Fragment>
  )
}
export default FooterHome;
