import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import CardBody from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardHeader";
import EmailIcon from "@material-ui/icons/Email";
import Button from "@material-ui/core/Button";
import CopyClipboard from "./../../components/CopyCodeClipboard";
import TextField from "@material-ui/core/TextField";
import { isEmail } from "./../../utils/validation";
import PageHeader from "../../components/Header/PageHeader";
import { useApolloClient, useMutation, useQuery } from '@apollo/client'

import { GET_CHATBOT } from "./queries";
import Loading from "../../components/Loading";

import { EMAIL_EMBED_CODE } from "./mutations";
import {
  gql
} from '@apollo/client';


const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1
  },
  paper: {
    height: 140,
    width: 100
  },
  control: {
    padding: theme.spacing(2)
  },
  half: {
    height: "50vh"
  }
}));

const Embed = () => {
  const client = useApolloClient();
  let { loading: queryLoading, error: queryError, data } = useQuery(
    GET_CHATBOT
  );
  const [
    emailEmbed,
    {
      loading: emailEmbedLoading,
      error: emailEmbedError,
      called: emailUpdateCalled
    }
  ] = useMutation(EMAIL_EMBED_CODE);
  const GET_CURRENT_USER = gql`
    {
      currentUserName @client
    }
  `;
  const classes = useStyles();
  const embedCodeRef = React.createRef();
  const [email, setEmail] = useState("");
  const [emailValid, setEmailValid] = useState(false);
  const { data: userData, userError } = useQuery(GET_CURRENT_USER);
  const handleChange = event => {
    setEmailValid(!isEmail(event.target.value));
    setEmail(event.target.value);
  };

  const copyCode = () => {
    //TODO finish this, add new ccode
    return `<script src="https://cdn.jsdelivr.net/npm/@thebotforge/chatbot-forge-dfagent@0.0.3/dist/chatbot-forge-dfagent.min.js"></script>`;
  };

  const emailCode = () => {
    return `<pre style="color:#000000;background:#ffffff;"><span style="color:#808030; ">&lt;</span>script type<span style="color:#808030; ">=</span><span style="color:#800000; ">"</span><span style="color:#0000e6; ">text/javascript</span><span style="color:#800000; ">"</span> src<span style="color:#808030; ">=</span><span style="color:#800000; ">"</span><span style="color:#0000e6; ">${this.props.currentChatbot.widgetURL}</span><span style="color:#800000; ">"</span> async onload<span style="color:#808030; ">=</span><span style="color:#800000; ">"</span><span style="color:#0000e6; ">StitchAIChat.init({index:\'${this.props.currentChatbot.chatbotURL}?agentguid=${this.props.currentChatbot.agentGuid}'});</span><span style="color:#800000; ">"</span><span style="color:#808030; ">&gt;</span>
           <span style="color:#808030; ">&lt;</span><span style="color:#808030; ">/</span>script<span style="color:#808030; ">&gt;</span></pre>`;
  };

  const handleCopyCode = () => {
    client.writeData({ data: {
        showSnackBar: true,
        snackBarMessage: 'Code copied to clipboard',
        severity: 'success'
    } });
  };

  const submitForm = event => {
    event.preventDefault();
    //TODO this.props.onSendCodeEmail(agent.Chatbots.emailEmbedCode({email:this.state.email,code:this.emailCode()}));
    emailEmbed({
      variables: {
        email: email,
        agentKey: data.chatbot.dfProjectId,
        senderName: userData.currentUserName
      }
    });
    client.writeData({ data: {
        showSnackBar: true,
        snackBarMessage: `Code sent to ${email}`,
        severity: 'success'
      } });
    if(emailEmbedError){
      console.log('Error')
    }
  };

  return (
    <React.Fragment>
      <Grid container justify={"center"} className={classes.root} spacing={2}>
        <Grid item xs={12}>
          <PageHeader title={"Embed your chatbot"} />
        </Grid>
        <Grid item xs={12} className={classes.half}>
          <Grid item>
            <p>
              Copy and paste this code anywhere in the <code>{"<body>"}</code>{" "}
              section of your website:
            </p>
            <p>
              You will need to add this code on every page where you want to
              display your StitchAI Chatbot.
            </p>
            {!data && queryLoading ? (
              <Loading />
            ) : (
              <div className={classes.codeInputArea}>
                <CopyClipboard
                  classes={classes}
                  value={data ? data.chatbot.embedCode : ""}
                  embedCodeRef={embedCodeRef}
                  onCopyClick={handleCopyCode}
                />
                After adding the code snippet, open your website and look for
                the chatbot in the bottom right of your website page. If there
                are any issues please{" "}
                <a href="mailto:support@thebotforge.io">get in touch.</a>
              </div>
            )}
          </Grid>
        </Grid>

        {data && (
          <Grid item xs={12}>
            <Card>
              <CardHeader
                avatar={<EmailIcon />}
                title="You can email these embed instructions to your developer, just fill out the form below."
              />
              <CardBody>
                <form onSubmit={submitForm}>
                  <Grid
                    container
                    direction="row"
                    justify="center"
                    alignItems="center"
                  >
                    <Grid item xs={12} sm={6}>
                      <TextField
                        fullWidth
                        variant="outlined"
                        label="Developer Email *"
                        id="email"
                        value={email}
                        error={emailValid}
                        helperText={
                          emailValid
                            ? "Incorrect email."
                            : "Separate multiple emails with a comma ( , ) "
                        }
                        onChange={handleChange}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Button
                        type="submit"
                        variant="contained"
                        disabled={emailValid === true || email.length < 1}
                        color="primary"
                      >
                        Email these instructions
                      </Button>
                    </Grid>
                  </Grid>
                </form>
              </CardBody>
            </Card>
          </Grid>
        )}
      </Grid>
    </React.Fragment>
  );
};

export default Embed;
