import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';


import TranscriptGrid from '../../components/dashboard/Transcripts/TranscriptGrid'


import PageHeader from '../../components/Header/PageHeader'

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    height: 140,
    width: 100,
  },
  control: {
    padding: theme.spacing(2),
  },
  half: {
    height: '50vh'
  }
}));

const Transcripts = () =>{
  const classes = useStyles();

  return (
    <React.Fragment>
        <Grid container justify={'center'} className={classes.root} spacing={2}>
          <Grid item xs={12} className={classes.half}>
            {
              <TranscriptGrid />
            }
            </Grid>
        </Grid>

    </React.Fragment>

  )
}

export default Transcripts;
