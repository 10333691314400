import React, { Fragment } from 'react'
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
const useStyles = makeStyles((theme) => ({
  formControl: {
    minWidth: 200,
    maxWidth: 400,
  },
  noLabel: {
    marginTop: theme.spacing(3),
  },
}));


const NotificationActionEditSelect = ({values,action,setAction}) =>{
  const classes = useStyles();
  const theme = useTheme();
  const handleChange = (event) =>{
    setAction({...action,[event.target.name]:event.target.value})
  }
  return (
    <FormControl className={classes.formControl}>
      <Select
        fullWidth
        id='actionValue'
        name={'actionValue'}
        value={action['actionValue']}
        onChange={handleChange}
        input={<Input />}
      >
        {values.map((name) => (
          <MenuItem key={name} value={name}>
            {name}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}

export default NotificationActionEditSelect;
