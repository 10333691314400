import React from 'react'
import Grid from "@material-ui/core/Grid";
import {HorizontalBar} from 'react-chartjs-2';
import { useTheme } from "@material-ui/core/styles";
const DashboardWidgetCounterGraph = (props)=>{
  const {value=0,total=0} = props;
  let percentage = value/total * 100;
  if(Number.isNaN(percentage)){
    percentage = 0;
  }
  const max = total;
  const data = {
    labels: [],
    datasets: [{
    data: [value],
    backgroundColor: useTheme().palette.primary.light
    }, {
    data: [max - percentage],
    backgroundColor: "lightgrey",
  }]
  }
  const graphOptions = {
    maintainAspectRatio: false,
    legend: {
      display: false
    },
    tooltips: {
      enabled: false
    },
    scales: {
      xAxes: [{
        display: false,
        stacked: true
      }],
      yAxes: [{
        display: false,
        stacked: true
      }],
    }
  }
  return(
    <Grid container
          direction="row"
          justify="center"
          alignItems="center">

      <Grid item>
        {`${Math.round(percentage)}% `}
      </Grid>
      <Grid item>
        <HorizontalBar
          data={data}
          width={100}
          height={50}
          options={ graphOptions }
        />
      </Grid>
    </Grid>
  )
}
export default DashboardWidgetCounterGraph
